import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { BehaviorSubject, debounceTime, takeUntil, tap } from 'rxjs';
import { MessagesService } from '@services/messages.service';
import { ChartService } from '@services/chart.service';
import { ObjectsService } from '@services/objects.service';
import { formatDefaultDateFromISO } from '@common/utils/format-date';
import { CompareService } from '../../../../components/compare/compare.service';
import { PortalService } from '@services/portal.service';
import { TranslationSensorTypesService } from '@common/services/translation-sensor-types.service';

@Component({
  selector: 'app-messages-graph',
  templateUrl: './messages-graph.component.html',
  styleUrls: ['./messages-graph.component.less'],
  providers: [TuiDestroyService],
})
export class MessagesGraphComponent implements OnInit, OnDestroy, OnChanges {
  @Input() brushEnabled: boolean = false;
  @Input() clearBrush: number = 1;
  @Input() isHighlightDigitalSeries: boolean = false;
  @Input() topValue = 0;

  @Output() isChartInitialized = new EventEmitter<any>();
  @Output() isDigitalSensors = new EventEmitter<any>();

  private topValueChanged$ = new BehaviorSubject<any>(null);

  heightChart = window.innerHeight - this.topValue - 75;

  options: any;

  series: any = [];

  isLoading = true;

  echartsInstance: any;

  params: any = [];

  constructor(
    private messagesService: MessagesService,
    private objectService: ObjectsService,
    private destroy$: TuiDestroyService,
    private chartService: ChartService,
    private compareService: CompareService,
    private portalService: PortalService,
    private translationSensorTypes: TranslationSensorTypesService,
  ) {
    this.topValueChanged$
      .pipe(
        tap(() => {
          if (this.echartsInstance) {
            this.echartsInstance.dispatchAction({
              type: 'hideTip',
            });
          }
        }),
        debounceTime(10),
        takeUntil(this.destroy$),
      )
      .subscribe((value) => {
        this.heightChart = window.innerHeight - value - 75;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.topValueChanged$.next(this.topValue);

    if (this.echartsInstance) {
      if (changes['brushEnabled'] && !changes['brushEnabled'].firstChange) {
        if (changes['brushEnabled'].currentValue) {
          this.echartsInstance.dispatchAction({
            type: 'takeGlobalCursor',
            key: 'brush',
            brushOption: {
              brushType: 'lineX',
            },
          });
        } else {
          this.echartsInstance.dispatchAction({
            type: 'takeGlobalCursor',
            key: 'none',
          });
        }
      }
      if (changes['clearBrush'] && !changes['clearBrush'].firstChange) {
        this.echartsInstance.dispatchAction({
          type: 'brush',
          areas: [],
        });
      }

      if (changes['isHighlightDigitalSeries'] && !changes['isHighlightDigitalSeries'].firstChange) {
        if (changes['isHighlightDigitalSeries'].currentValue) {
          this.echartsInstance.setOption({
            series: this.series,
          });
        } else {
          const allSeries = this.echartsInstance.getOption().series;

          allSeries.forEach((series: any) => {
            series.markArea = {
              data: null,
            };
          });

          this.echartsInstance.setOption({
            series: allSeries,
          });
        }
      }
    }
  }

  ngOnInit() {
    this.topValueChanged$.next(this.topValue);

    this.messagesService.messagesData
      .pipe(takeUntil(this.destroy$))
      .pipe(
        tap((res: any) => {
          if (res.type === 'raw') {
            this.objectService
              .getAvailableParamsById(res.requestParams.objectId)
              .subscribe((params: any) => {
                this.params = params.availableParams;
              });
          }
        }),
      )
      .subscribe((res: any) => {
        this.isLoading = true;
        this.messagesService
          .getMessages(
            res.requestParams.objectId,
            res.requestParams.startDate,
            res.requestParams.endDate,
            0,
            null,
            res.requestParams.messageType,
            res.requestParams.param,
          )
          .subscribe((data: any) => {
            let legends: any = [];
            const messages = [...data].reverse();
            let getData = (l: any) => messages.map((m: any) => m.params[l.id]);

            if (res.type === 'sensors') {
              legends = res.object.sensors.map((s: any, index: number) => {
                const color = this.chartService.getRandomColor(index);

                const isDigitalSensor: any = this.translationSensorTypes.isDigitalSensorType(
                  s.type,
                );

                return {
                  id: 'sensor__' + s.id,
                  title: s.name,
                  selected: true,
                  lineStyle: {
                    color: color,
                  },
                  itemStyle: {
                    color: color,
                  },
                  isDigitalSensor,
                };
              });
              getData = (l: any) => {
                return messages.map((m: any) => m.sensors[`${l.id}`]);
              };
            } else {
              legends = this.params.map((l: any, index: number) => {
                const color = this.chartService.getRandomColor(index);
                return {
                  id: l,
                  title: l,
                  selected: true,
                  lineStyle: {
                    color: color,
                  },
                  itemStyle: {
                    color: color,
                  },
                };
              });
            }
            this.series = legends.map((l: any) => {
              const highlightDigitalSeries: any[] = [];

              if (l.isDigitalSensor) {
                let tempArray: any[] = [];

                messages.forEach((m: any) => {
                  if (m.sensors[`${l.id}`] == 1) {
                    tempArray.push({ xAxis: formatDefaultDateFromISO(m.t) });
                  } else {
                    if (tempArray.length > 0) {
                      highlightDigitalSeries.push([tempArray[0], tempArray[tempArray.length - 1]]);
                      tempArray = [];
                    }
                  }
                });

                if (tempArray.length > 0) {
                  highlightDigitalSeries.push([tempArray[0], tempArray[tempArray.length - 1]]);
                }

                this.isDigitalSensors.emit(true);
              }

              return {
                id: l.id,
                name: l.title,
                type: 'line',
                smooth: false,
                lineStyle: l.lineStyle,
                itemStyle: l.itemStyle,
                connectNulls: true,
                data: getData(l),
                markArea: {
                  data: highlightDigitalSeries,
                },
              };
            });

            const xAxisData = messages.map((m: any) => formatDefaultDateFromISO(m.t));
            const windowHeight =
              window.innerHeight ||
              document.documentElement.clientHeight ||
              document.body.clientHeight;
            this.options = {
              legend: {
                show: false,
                // data: legends.map((l: any) => l.title),
                // type: 'scroll',
                // orient: 'horizontal',
              },
              grid: {
                top: 10,
                left: '10%',
                right: '5%',
                height: 'auto',
              },

              tooltip: {
                trigger: 'axis',
                triggerOn: 'mousemove',
                axisPointer: {
                  type: 'cross',
                },
                showDelay: 10,
                valueFormatter: (value: any) => {
                  if (typeof value === 'number') {
                    return parseFloat(value.toFixed(2));
                  } else {
                    return value;
                  }
                },
                backgroundColor: `var(--tui-base-02)`,
                borderColor: `var(--tui-base-02)`,
                textStyle: {
                  color: `var(--tui-text-02)`,
                },
                extraCssText: 'max-height:' + (windowHeight - 580 - 50) + 'px; overflow: hidden;',
                position: function (point: any, params: any, dom: any, rect: any, size: any) {
                  const memoizedResult: any = {};

                  const getPositionResult = (
                    point: any,
                    params: any,
                    dom: any,
                    rect: any,
                    size: any,
                  ): any => {
                    const tooltipHeight = windowHeight - 580 - 50;
                    if (dom.scrollHeight > tooltipHeight) {
                      const overflowText: any = `
                        <div style="
                          color: #828282;
                          background: #FED1D1;
                          margin: -10px -10px 10px;
                          padding: 4px;
                          line-height: 1;
                          font-size: 11px;
                          font-weight: bold;"
                        >
                          Выбрано слишком много параметров
                          <br> Отключите ненужные
                        </div>`;

                      dom.insertAdjacentHTML('afterbegin', overflowText);
                    }
                  };

                  const memoizedPosition = (
                    point: any,
                    params: any,
                    dom: any,
                    rect: any,
                    size: any,
                  ) => {
                    const args = JSON.stringify(arguments);
                    if (!memoizedResult[args]) {
                      memoizedResult[args] = getPositionResult(point, params, dom, rect, size);
                    }
                    return memoizedResult[args];
                  };

                  return memoizedPosition(point, params, dom, rect, size);
                },
              },
              xAxis: {
                data: xAxisData,
                silent: false,
                splitLine: {
                  show: true,
                },
              },
              yAxis: {
                axisPointer: {
                  snap: true,
                },
              },
              series: this.series,
              dataZoom: [
                {
                  startValue: xAxisData[0],
                },
                {
                  type: 'inside',
                },
              ],
              animation: false,
              toolbox: {
                show: false,
                right: 20,
                top: 0,
                feature: {
                  brush: {
                    type: ['lineX', 'clear'],
                    title: {
                      lineX: 'Выделить область для сравнения данных',
                      clear: 'Очистить выделенную область',
                    },
                  },
                },
              },

              brush: {
                xAxisIndex: 'all',
                brushLink: 'all',
                throttleType: 'debounce',
                throttleDelay: 10,
                outOfBrush: {
                  colorAlpha: 0.1,
                },
                brushType: 'lineX', // Это позволит брашу взаимодействовать с точками вдоль горизонтальной оси
              },
            };
            this.isLoading = false;
            this.messagesService.changeLegends.next(legends);
          });
      });
  }

  onChartInit(ec: any) {
    this.echartsInstance = ec;
    if (!ec) return;

    this.isChartInitialized.emit(true);
    this.chartService.toggleLegendSubject.subscribe(({ name, type }: any) => {
      this.echartsInstance.dispatchAction({
        type,
        name,
      });
    });

    this.echartsInstance.on('brushSelected', (params: any) => {
      const selectedRange = params.batch?.[0]?.areas?.[0];
      const xAxisData = this.options.xAxis?.data || [];
      const selectedSeriesIndexes =
        params.batch?.[0]?.selected?.map((f: any) => f.seriesIndex) || [];
      const optionsSelectedSeries = selectedSeriesIndexes.map(
        (index: any) => this.options.series[index],
      );

      if (selectedRange) {
        // Первый и последний индексы, которые захватывает выделенная область
        const [startIndex, endIndex] = [
          Math.round(selectedRange.coordRange[0]),
          Math.round(selectedRange.coordRange[1]),
        ];

        // Отдельно сохраняем начало и конец выделенной области по горизонтальной оси
        // это то, что улетит в compare.component в качестве названия столбцов начало и конец периода
        const [startDate, endDate] = [xAxisData[startIndex], xAxisData[endIndex]];

        // Здесь собираем массив для каждого параметра
        const resultValues = optionsSelectedSeries.map((m: any) => {
          // Для каждого параметра выбираем все значения между индексами внутри выделенной области
          // и оставляем только те, с которыми возможно производить рассчет
          const valuesBetweenIndexes = m.data
            .slice(startIndex, endIndex + 1)
            .filter((item: any) => !isNaN(item));

          // сохраняем количество отфильтрованных значений
          const lengthBetween = valuesBetweenIndexes.length;

          if (!lengthBetween) {
            return {
              name: m.name,
              startValue: '-',
              endValue: '-',
              minValue: '-',
              maxValue: '-',
              averageValue: '-',
              difference: '-',
            };
          } else {
            // расчитываем сумму всех отфильтрованных значений
            const summaryValuesBetween = valuesBetweenIndexes.reduce(
              (acc: any, val: any) => acc + Number(val),
              0,
            );

            // расчитываем среднее значение
            const averageByBetween = summaryValuesBetween / lengthBetween;

            // сохраняем начальное и конечное значение
            const [startValue, endValue] = [
              valuesBetweenIndexes[0],
              valuesBetweenIndexes[lengthBetween - 1],
            ];

            // расчитываем минимальное значение
            const minValue = Math.min(...valuesBetweenIndexes);

            // расчитываем максимальное значение
            const maxValue = Math.max(...valuesBetweenIndexes);

            return {
              name: m.name,
              startValue,
              endValue,
              minValue,
              maxValue,
              averageValue: parseFloat(averageByBetween.toFixed(2)) ?? '-',
              difference: !(isNaN(startValue) && isNaN(endValue))
                ? parseFloat((endValue - startValue).toFixed(2))
                : '-',
            };
          }
        });

        this.portalService.isVisibleCompareComponent.next(true);
        this.compareService.data.next({
          startDate,
          endDate,
          data: resultValues,
        });
      } else {
        this.portalService.isVisibleCompareComponent.next(false);
      }
    });
  }

  ngOnDestroy() {
    if (this.echartsInstance) {
      this.echartsInstance.dispose();
      this.portalService.isVisibleCompareComponent.next(false);
      this.compareService.data.next(null);
    }
  }
}
