<div class="compare-container" *tuiLet="compareService?.data | async as data">
  <h3>{{ 'Comparison of data' | translate }}</h3>

  <!--  <tui-svg-->
  <!--    [src]="'common/assets/icons/cross.svg'"-->
  <!--    [tuiHint]="'Close' | translate"-->
  <!--    [tuiHintAppearance]="'custom'"-->
  <!--    (click)="close()"-->
  <!--    class="icon close-btn"-->
  <!--  ></tui-svg>-->

  <tui-svg
    [src]="'common/assets/icons/chevronDown.svg'"
    (click)="isOpen = !isOpen"
    [ngStyle]="{ transform: isOpen ? 'rotate(180deg)' : 'none' }"
    class="icon accordion-btn"
  ></tui-svg>

  <div class="hidden" *ngIf="isOpen && data" [@tuiHeightCollapse]="getAnimation(500)">
    <div
      class="dropdowns"
      [style.height.px]="data.data?.length < 7 ? data.data.length * 22 + 42 : ''"
    >
      <!--    <div class="dropdowns" [style.height.px]="data.data.length * 22 + 42">-->
      <!--    <div class="dropdowns">-->
      <tui-scrollbar>
        <table tuiTable class="table" [columns]="columns">
          <thead tuiThead>
            <tr tuiThGroup>
              <th tuiTh>{{ 'PARAMETER' | translate }}</th>
              <th tuiTh>{{ data?.startDate }}</th>
              <th tuiTh>{{ 'Difference' | translate }}</th>
              <th tuiTh>{{ data?.endDate }}</th>
              <th tuiTh>{{ 'minValue' | translate }}</th>
              <th tuiTh>{{ 'averageValue' | translate }}</th>
              <th tuiTh>{{ 'maxValue' | translate }}</th>
            </tr>
          </thead>
          <tbody tuiTbody [data]="data?.data">
            <tr *ngFor="let item of data?.data" tuiTr>
              <td
                *tuiCell="'name'"
                [tuiHint]="item.name"
                [tuiHintAppearance]="'custom'"
                [tuiHintDirection]="'left'"
                tuiTd
                class="ellipsis"
              >
                {{ item.name }}
              </td>
              <td *tuiCell="'startValue'" tuiTd>{{ item.startValue }}</td>
              <td *tuiCell="'difference'" tuiTd [class]="getCellColorByValue(item.difference)">
                {{ item.difference }}
              </td>
              <td *tuiCell="'endValue'" tuiTd>{{ item.endValue }}</td>
              <td *tuiCell="'minValue'" tuiTd>{{ item.minValue }}</td>
              <td *tuiCell="'averageValue'" tuiTd>{{ item.averageValue }}</td>
              <td *tuiCell="'maxValue'" tuiTd>{{ item.maxValue }}</td>
            </tr>
          </tbody>
        </table>
      </tui-scrollbar>
    </div>
  </div>
</div>
