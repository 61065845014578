<div class="view-messages" [style.top.px]="topValue">
  <div class="header">
    <div class="left">
      <tui-tabs class="tab-icon" [(activeItemIndex)]="activeItemIndex" *ngIf="selectedMessageType === 'with_data'">
        <button
          tuiTab
          (click)="onClickTab('table')"
          [tuiHint]="'Table' | translate"
          [tuiHintAppearance]="'custom'"
        >
          <tui-svg [src]="'common/assets/icons/table.svg'"></tui-svg>
        </button>
        <button
          tuiTab
          (click)="onClickTab('graph')"
          [tuiHint]="'Graph' | translate"
          [tuiHintAppearance]="'custom'"
        >
          <tui-svg [src]="'common/assets/icons/chart.svg'"></tui-svg>
        </button>
      </tui-tabs>

      <button
        *ngIf="selectedView === 'table'"
        tuiButton
        size="s"
        type="button"
        appearance="transparent"
        class="icon"
        (click)="onClickIsStroke()"
        [tuiHint]="
          isNoWrapTable
            ? ('Show with line break' | translate)
            : ('Show without line break' | translate)
        "
        [tuiHintAppearance]="'custom'"
        [tuiHintDirection]="'right'"
      >
        <tui-svg [src]="isNoWrapTable ? 'tuiIconMinimize2' : 'tuiIconMaximize2'"></tui-svg>
      </button>
    </div>
    <div class="messages-filter" *ngIf="selectedView === 'table' && selectedType === 'raw' && selectedMessageType === 'with_data'">
      <tui-input
        [formControl]="filterField"
        (keyup.enter)="onClickFilter()"
        [tuiTextfieldCleaner]="true"
        tuiTextfieldSize="s"
      >
        {{ 'Search by parameters' | translate }}
        <input tuiTextfield type="text" />
      </tui-input>
      <button
        tuiButton
        size="s"
        type="button"
        appearance="transparent"
        class="icon"
        [class.active_btn]="isFiltered"
        [disabled]="filterField.value === ''"
        (click)="onClickFilter()"
      >
        <div class="circle"></div>
        <tui-svg [src]="'tuiIconFilter'"></tui-svg>
      </button>
    </div>
    <app-table-paginator
      [paginatorService]="messagesService.paginatorService"
      *ngIf="selectedView === 'table'"
    ></app-table-paginator>

    <div class="graph-actions" *ngIf="selectedView === 'graph'">
      <button
        *ngIf="isChartInitialized && isDigitalSensors"
        tuiButton
        size="s"
        type="button"
        appearance="transparent"
        class="icon"
        [pseudoActive]="isHighlightDigitalSeries"
        (click)="isHighlightDigitalSeries = !isHighlightDigitalSeries"
        [tuiHint]="'Highlight digital sensors' | translate"
        [tuiHintAppearance]="'custom'"
        [tuiHintDirection]="'right'"
      >
        <tui-svg [src]="'tuiIconColumns'"></tui-svg>
      </button>

      <button
        tuiButton
        size="s"
        type="button"
        appearance="transparent"
        class="icon"
        [disabled]="!isChartInitialized"
        [class.active_btn]="brushEnabled"
        (click)="brushEnabled = !brushEnabled"
        [tuiHint]="
          brushEnabled
            ? ('Turn off range creation mode' | translate)
            : ('Enable range creation mode' | translate)
        "
        [tuiHintAppearance]="'custom'"
        [tuiHintDirection]="'right'"
      >
        <div class="circle"></div>
        <tui-svg [src]="'tuiIconBarChart2'"></tui-svg>
      </button>

      <button
        tuiButton
        size="s"
        type="button"
        appearance="transparent"
        class="icon"
        [disabled]="!isChartInitialized"
        (click)="clearBrushTrigger = clearBrushTrigger + 1"
        [tuiHint]="'Clear selected range' | translate"
        [tuiHintAppearance]="'custom'"
        [tuiHintDirection]="'right'"
      >
        <tui-svg [src]="'tuiIconCloseLarge'"></tui-svg>
      </button>
    </div>
  </div>
  <div class="view-messages__content">
    <app-messages-table
      [paginatorService]="messagesService.paginatorService"
      [changeFilterEvent]="changeFilterEvent"
      [topValue]="topValue"
      [isNoWrapTable]="isNoWrapTable"
      *ngIf="selectedView === 'table'"
    ></app-messages-table>
    <app-messages-graph
      *ngIf="selectedView === 'graph'"
      [topValue]="topValue"
      [brushEnabled]="brushEnabled"
      [clearBrush]="clearBrushTrigger"
      [isHighlightDigitalSeries]="isHighlightDigitalSeries"
      (isChartInitialized)="isChartInitialized = true"
      (isDigitalSensors)="isDigitalSensors = $event"
    ></app-messages-graph>
  </div>
</div>
