import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TuiAlertService } from '@taiga-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@services/app.service';

@Injectable({
  providedIn: 'root',
})
export class ObjectsService {
  constructor(
    private httpClient: HttpClient,
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private translate: TranslateService,
    private appService: AppService,
  ) {}

  create(form: any) {
    return this.httpClient.post('objects/', form);
  }

  update(form: any, objectId: any) {
    return this.httpClient.put(`objects/${objectId}/`, form);
  }

  objectsList(params: any = null) {
    let httpParams = new HttpParams();
    if (params?.fields?.length) {
      httpParams = httpParams.set('fields', params.fields.toString());
    }

    return this.httpClient.get('objects/', { params: httpParams });
  }

  shortList() {
    return this.httpClient.get('objects/short/');
  }

  linkingObjectsByObjectId(objectId: number) {
    let httpParams = new HttpParams();

    httpParams = httpParams.set('object_id', objectId.toString());

    return this.httpClient.get(`object_user_access/`, { params: httpParams });
  }

  getObjectById(objectId: number, full: boolean = true) {
    let params = new HttpParams().set('full', full);

    return this.httpClient.get(`objects/${objectId}/`, { params });
  }

  getAvailableParamsById(objectId: number) {
    return this.httpClient.get(`objects/available_params/${objectId}/`);
  }

  getAvailableParamsByIds(objectIds: number[]) {
    return this.httpClient.post('objects/available_params/', { objectIds });
  }

  changeDisplayObjectsByIds(visibleObjectsIds: number[]) {
    return this.httpClient.post('objects/display/', { visibleObjectsIds });
  }

  changeCurrentValueForCalculatedSensors(objectId: number, sensorId: number, newCurrentValue: any) {
    return this.httpClient.post(`objects/${objectId}/sensors/${sensorId}/set_current_value/`, {
      newCurrentValue,
    });
  }

  deleteObject(objectId: number) {
    return this.httpClient.delete(`objects/${objectId}/`);
  }

  alert(name: string, action: string) {
    return this.alerts.open(
      this.translate.instant(action, {
        name,
        context: this.translate.instant('Object'),
      }),
    );
  }

  sendCommand(objectId: number, commandId: number) {
    return this.httpClient.post(`objects/send_command/`, { objectId, commandId });
  }
}
