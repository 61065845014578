import {
  Component,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ReportsService } from '@services/reports.service';
import { TuiDestroyService } from '@taiga-ui/cdk/services';
import { BehaviorSubject, debounceTime, takeUntil, tap } from 'rxjs';
import { formatDefaultDateFromISO } from '@common/utils/format-date';
import { TuiThemeNightService } from '@taiga-ui/addon-doc';

@Component({
  selector: 'app-graph-report',
  templateUrl: './graph-report.component.html',
  styleUrls: ['./graph-report.component.less'],
  providers: [TuiDestroyService],
})
export class GraphReportComponent implements OnInit, OnDestroy, OnChanges {
  @Input() topValue = 0;

  private topValueChanged$ = new BehaviorSubject<any>(null);

  windowHeight = window.innerHeight;

  isLoading = true;

  options: any;

  echartsInstance: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowHeight = window.innerHeight;
  }

  constructor(
    @Inject(TuiThemeNightService) readonly night: TuiThemeNightService,
    public reportService: ReportsService,
    private destroy$: TuiDestroyService,
  ) {
    this.topValueChanged$
      .pipe(
        tap(() => {
          if (this.echartsInstance) {
            this.echartsInstance.dispatchAction({
              type: 'hideTip',
            });
          }
        }),
        debounceTime(10),
        takeUntil(this.destroy$),
      )
      .subscribe((value) => {
        this.windowHeight = window.innerHeight;
      });
  }

  ngOnChanges() {
    this.topValueChanged$.next(this.topValue);
  }

  ngOnInit() {
    this.topValueChanged$.next(this.topValue);

    this.reportService.selectedChart.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      if (!value) return;

      const color = this.night.value ? '#ffffff' : '#222';
      this.options = {
        legend: {
          data: value.y.map((l: any) => l.name),
          align: 'left',
          type: 'scroll',
          textStyle: {
            color,
          },
          pageIconColor: color,
          pageTextStyle: {
            color,
          },
        },

        grid: {
          left: '10%',
          right: '5%',
          height: 'auto',
        },

        tooltip: {
          trigger: 'axis',
          valueFormatter: (value: any) => {
            if (typeof value === 'number') {
              return Number.isInteger(value) ? value : value.toFixed(2);
            } else {
              return value;
            }
          },
          backgroundColor: `var(--tui-base-02)`,
          borderColor: `var(--tui-base-02)`,
          textStyle: {
            color: `var(--tui-text-02)`,
          },
        },
        xAxis: {
          data: value.x.map((v: any) => formatDefaultDateFromISO(v)),
          silent: false,
          splitLine: {
            show: true,
          },
        },
        yAxis: {},
        series: value.y.map((v: any) => ({
          id: v.id,
          name: v.name,
          type: 'line',
          smooth: false,
          connectNulls: true,
          data: v.data,
        })),
        dataZoom: [
          {
            startValue: value.x[0],
          },
          {
            type: 'inside',
          },
        ],
      };
      this.isLoading = false;
      this.reportService.isLoadingReportList.next(false);
    });
  }

  onChartInit(ec: any) {
    this.echartsInstance = ec;

    this.night.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      const color = value ? '#ffffff' : '#222';
      this.echartsInstance.setOption({
        legend: {
          textStyle: {
            color,
          },
          pageIconColor: color,
          pageTextStyle: {
            color,
          },
        },
      });
    });
  }

  ngOnDestroy() {
    if (this.echartsInstance) {
      this.echartsInstance.dispose();
    }
  }
}
