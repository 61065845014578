import { Component, Inject, OnInit } from '@angular/core';
import { monitoringTabs, TabInfo } from '@common/utils/consts/monitoring-tabs-config';
import { NotificationsHelperService } from '@services/notifications-helper.service';
import { TuiThemeNightService } from '@taiga-ui/addon-doc';
import { Store } from '@ngrx/store';
import { selectCurrentUser } from '@store/selectors/current_user.selectors';
import { take } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit {
  isExpanded = !!localStorage.getItem('isExpanded');

  isOpenedSettings = false;

  protected readonly TABS: TabInfo[] = monitoringTabs;

  currentUser$ = this.store.select(selectCurrentUser);
  accessTabs: any = {};

  constructor(
    @Inject(TuiThemeNightService) readonly night: TuiThemeNightService,
    public noticeHelpS: NotificationsHelperService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.currentUser$.subscribe((user) => {
      for (const tab of this.TABS) {
        if (['monitoring', 'objects', 'users'].includes(tab.name)) {
          this.accessTabs[tab.name] = true;
        } else if (tab.name === 'tracks') {
          this.accessTabs[tab.name] = (user?.commonAccesses || []).includes('access_tracks');
        } else if (tab.name === 'reports') {
          this.accessTabs[tab.name] = (user?.commonAccesses || []).includes('access_reports');
        } else if (tab.name === 'messages') {
          this.accessTabs[tab.name] = (user?.commonAccesses || []).includes('access_messages');
        } else if (tab.name === 'notifications') {
          this.accessTabs[tab.name] = (user?.commonAccesses || []).includes('access_notifications');
        } else if (tab.name === 'geofences') {
          this.accessTabs[tab.name] = (user?.commonAccesses || []).includes('access_geozones');
        } else if (tab.name === 'drivers') {
          this.accessTabs[tab.name] = (user?.commonAccesses || []).includes('access_drivers');
        } else if (tab.name === 'buildings') {
          this.accessTabs[tab.name] = (user?.commonAccesses || []).includes('access_buildings');
        } else if (tab.name === 'devices') {
          this.accessTabs[tab.name] = (user?.commonAccesses || []).includes('access_devices');
        }
      }
    });
  }

  setIsExpanded(isExpanded: boolean) {
    this.isExpanded = isExpanded;
    this.isExpanded
      ? localStorage.setItem('isExpanded', 'true')
      : localStorage.removeItem('isExpanded');
  }

  onDblClickLogo() {
    window.open(window.location.href, '_blank');
  }
}
