import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { icons } from '@common/utils/icons';
import * as _ from 'lodash';
import { formatDateToRelativeFromISO } from '@common/utils/format-date';
import { TranslationSensorTypesService } from '@common/services/translation-sensor-types.service';
import { AccessService } from '@common/services/access.service';

@Component({
  selector: 'app-object-tooltip',
  templateUrl: './object-tooltip.component.html',
  styleUrls: ['./object-tooltip.component.less'],
})
export class ObjectTooltipComponent implements OnInit {
  @Input() object: any;

  @Input() address: string = '';

  availableIcons: any = {};

  availableParams: any = [];

  protected readonly formatDateToRelative = formatDateToRelativeFromISO;

  constructor(
    @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
    private translationSensorTypes: TranslationSensorTypesService,
    public accessService: AccessService,
  ) {
    this.availableIcons = Object.fromEntries(
      Object.entries(icons).map(([key, value]: any) => [
        parseInt(key),
        sanitizer.bypassSecurityTrustHtml(value),
      ]) as any,
    );
  }

  ngOnInit(): void {
    this.availableParams = this.object.sensors
      // .filter((f: any) => f?.isVisible)
      .map((s: any) => {
        const isDigitalSensor = this.translationSensorTypes.isDigitalSensorType(s.type);

        const value = _.get(this.object.lastMessage?.sensors, `sensor_${s.id}`, '-');

        return {
          title: s.name,
          value: isDigitalSensor
            ? this.translationSensorTypes.splitUnitFromDigitalSensor(s.unit, value)
            : _.isNumber(value)
              ? value.toFixed(2)
              : value,
          unit: isDigitalSensor ? '' : s.unit || '',
        };
      });
  }
}
