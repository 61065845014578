import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AllPresetsModel } from '@common/components/create-user/models/users.models';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private httpClient: HttpClient) {}

  usersList(params: any = null) {
    let httpParams = new HttpParams();
    if (params?.fields?.length) {
      httpParams = httpParams.set('fields', params.fields.toString());
    }

    return this.httpClient.get<any[]>('users/', { params: httpParams });
  }

  usersAccessList() {
    let httpParams = new HttpParams();
    // if (params?.fields?.length) {
    //   httpParams = httpParams.set('fields', params.fields.toString());
    // }

    return this.httpClient.get<any[]>('access/users/', { params: httpParams });
  }

  userById(userId: number) {
    return this.httpClient.get(`users/${userId}/`);
  }

  linkingObjectsByUserId(userId: number) {
    let httpParams = new HttpParams();

    httpParams = httpParams.set('user_id', userId.toString());

    return this.httpClient.get(`object_user_access/`, { params: httpParams });
  }

  getCurrentUser() {
    return this.httpClient.get('current_user/');
  }

  updateUserProfile(body: any) {
    return this.httpClient.put('update_user_profile/', body);
  }

  changePassword(body: any) {
    return this.httpClient.put('change_password/', body);
  }

  getAccessPresets(): Observable<AllPresetsModel | null> {
    // TODO: uncomment when backend is ready
    // return this.httpClient.get('access_presets/');
    // TODO: update with any mock data
    return of(null);
  }

  updateAccessPresets(body: AllPresetsModel): Observable<AllPresetsModel> {
    // TODO: uncomment when backend is ready
    // return this.httpClient.get('access_presets/');
    // TODO: update with any mock data
    return of(body);
  }

  createUser(body: any): Observable<any> {
    return this.httpClient.post<any[]>('users/', body);
  }

  updateUser(body: any, id: string): Observable<any> {
    return this.httpClient.patch<any[]>(`users/${id}/`, body);
  }

  deleteUser(id: string): Observable<any> {
    return this.httpClient.delete<any[]>(`users/${id}/`);
  }

  // TODO: delete when backend is ready
  logmessages = [
    { time: '2018-01-01 00:00:00', message: 'message' },
    { time: '2018-01-01 00:00:00', message: 'message' },
  ];

  getUserLogs(body: any, id: string): Observable<any[]> {
    // TODO: uncomment when backend is ready
    // return this.httpClient.get('users/logs/' + id, body);
    return of(this.logmessages);
  }

  sessions = {
    access: ['aaaa', 'bbbb', 'cccc'],
    notifications: ['1111', '2222', '3333'],
  };

  getUserSessions(id: string): Observable<any> {
    // TODO: uncomment when backend is ready
    // return this.httpClient.get('users/sessions/' + id);
    return of({ ...this.sessions, id });
  }
}
