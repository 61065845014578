import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentUser } from '@store/selectors/current_user.selectors';
import { skip } from 'rxjs';
import { MapService } from '../map/map.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-visible-layers-settings',
  templateUrl: './visible-layers-settings.component.html',
  styleUrls: ['./visible-layers-settings.component.less'],
})
export class VisibleLayersSettingsComponent implements OnInit {
  currentUserId: number | null = null;

  isOpened = false;

  isVisibleObjectName: FormControl = new FormControl(false);

  constructor(
    private store: Store,
    private mapService: MapService,
  ) {
    store
      .select(selectCurrentUser)
      .pipe(skip(1))
      .subscribe((user) => {
        this.currentUserId = user?.id;
        const keyFromStorage = `user_name_on_map_${this.currentUserId}`;
        const mapLayers: string | null = localStorage.getItem(keyFromStorage);
        if (!mapLayers) {
          this.mapService.isVisibleObjectsName.next(false);
        } else {
          this.isVisibleObjectName.setValue(true);
        }
      });
  }

  ngOnInit() {
    this.isVisibleObjectName.valueChanges.subscribe((value) => {
      this.mapService.isVisibleObjectsName.next(value);
      if (value) {
        localStorage.setItem(`user_name_on_map_${this.currentUserId}`, 'true');
      } else {
        localStorage.removeItem(`user_name_on_map_${this.currentUserId}`);
      }
    });
  }

  onClickAction() {
    this.isOpened = !this.isOpened;
  }

  onObscured(obscured: boolean): void {
    if (obscured) {
      this.isOpened = false;
    }
  }

  onActiveZone(active: boolean): void {
    this.isOpened = active && this.isOpened;
  }
}
