export const arrSortedValue = ['a-z', 'z-a'];

export const getSortIndexValue = (valueSort: string) => {
  return arrSortedValue.findIndex((value: any) => value === valueSort);
};

export const getSortIcon = (valueSort: string) => {
  return valueSort === arrSortedValue[1]
    ? 'common/assets/icons/sort_Z-A.svg'
    : `common/assets/icons/sort_A-Z.svg`;
};

export const getSortedList = (list: any[], valueSort: string | null = arrSortedValue[0]) => {
  return valueSort === arrSortedValue[1]
    ? list.sort((a: any, b: any) => b.name.localeCompare(a.name))
    : list.sort((a: any, b: any) => a.name.localeCompare(b.name));
};

export const getSearchSortValue = (
  list: any,
  searchValue: string | null = '',
  sortedValue: string | null,
  sortedKeys: string[] = ['name'],
) => {
  if (searchValue) {
    const filteredDrivers = list.filter((o: any) => {
      return sortedKeys.some((key) => o[key].toLowerCase().includes(searchValue.toLowerCase()));
    });

    return getSortedList(filteredDrivers, sortedValue);
  } else {
    return getSortedList(list, sortedValue);
  }
};
