import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { BuildingService } from '@services/buildings.service';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { takeUntil } from 'rxjs';
import { formatDateToRelativeFromISO } from '@common/utils/format-date';

@Component({
  selector: 'app-room-scheme-view',
  templateUrl: './room-scheme-view.component.html',
  styleUrls: ['./room-scheme-view.component.less'],
  providers: [TuiDestroyService],
})
export class RoomSchemeViewComponent implements OnDestroy {
  @ViewChild('schema') schema: ElementRef | undefined;

  protected readonly formatDateToRelativeFromISO = formatDateToRelativeFromISO;

  currentBuilding: any = null;

  beaconsObjects: any = {};

  interval: any = null;

  activeDevice = this.buildingService.activeDevice;

  constructor(
    private buildingService: BuildingService,
    @Inject(TuiDestroyService)
    destroy$: TuiDestroyService,
  ) {
    this.buildingService.currentBuilding.pipe(takeUntil(destroy$)).subscribe((building) => {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.subscribeBuilding(building);
      this.interval = setInterval(() => {
        this.subscribeBuilding(building);
      }, 1000 * 10);
    });
  }

  subscribeBuilding(building: any) {
    if (building) {
      this.currentBuilding = building;
      this.buildingService.getRoomInfo(building.id).subscribe((res: any) => {
        this.beaconsObjects = res;
        this.buildingService.roomOpened.next(res);
      });
    }
  }

  getBeaconCount(deviceUUID: any) {
    return (this.beaconsObjects[String(deviceUUID).toLowerCase()] || []).length;
  }

  getBeaconList(deviceUUID: any) {
    return this.beaconsObjects[String(deviceUUID).toLowerCase()] || [];
  }

  getDeviceTransform(device: any) {
    const schemaWidth = this.schema?.nativeElement.offsetWidth;
    const schemaHeight = this.schema?.nativeElement.offsetHeight;
    const devicePosition = this.currentBuilding.devicesPositions.find(
      (devicePosition: any) => devicePosition.deviceId === device.id,
    );
    const x = (devicePosition?.x / 100) * schemaWidth;
    const y = (devicePosition?.y / 100) * schemaHeight;
    if (devicePosition) {
      return `translate3d(${x}px, ${y}px, 0px)`;
    } else {
      return `translate3d(0px, 0px, 0px)`;
    }
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
