import { createReducer, on } from '@ngrx/store';
import {
  addObject,
  deleteObjectById,
  storeObjects,
  updateConnectedStatusForObject,
  updateLastMessageForObject,
  updateObjectById,
} from '@store/actions/objects.actions';
import { getSearchSortValue } from '@common/utils/search-sort';

export const initialState: any = {
  list: [],
  loaded: false,
};

export const objectsReducer = createReducer(
  initialState,
  on(storeObjects, (state, { list }) => ({ ...state, list, loaded: true })),
  on(updateLastMessageForObject, (state, data) => {
    return {
      ...state,
      list: state.list.map((object: any) => {
        return object.id === data.oid
          ? {
              ...object,
              lastMessage: data
                ? {
                    ...object.lastMessage,
                    ...{
                      ...data.m,
                      pos: data.m?.pos ? data.m.pos : object.lastMessage?.pos,
                    },
                  }
                : object.lastMessage,
            }
          : object;
      }),
    };
  }),
  on(updateConnectedStatusForObject, (state, data) => {
    return {
      ...state,

      list: state.list.map((object: any) => {
        return data.oid === object.id
          ? {
              ...object,
              connectedStatus: data ? data.connectedStatus : object.connectedStatus,
            }
          : object;
      }),
    };
  }),
  on(updateObjectById, (state, data) => {
    const { updatedObject, params } = data;
    const list = state.list.map((object: any) => {
      return object.id === updatedObject.id ? { ...object, ...updatedObject } : object;
    });

    console.log(params);

    return {
      ...state,
      list: getSearchSortValue(list, params?.searchValue || null, params?.sortedValue || null, [
        'name',
        'uniqueId',
      ]),
    };
  }),
  on(addObject, (state, data) => {
    const { createdObject, params } = data;
    return {
      ...state,
      list: getSearchSortValue(
        [createdObject, ...state.list],
        params?.searchValue || null,
        params?.sortedValue || null,
        ['name', 'uniqueId'],
      ),
    };
  }),
  on(deleteObjectById, (state, data) => {
    const { deletedObjectId } = data;
    return {
      ...state,
      list: state.list.filter((object: any) => object.id !== deletedObjectId),
    };
  }),
);
