<div class="import-popup-container">
  <label tuiProgressLabel class="label-wrapper">
    <span>{{ 'Importing objects' | translate }}: {{ allTotal }}%</span>
    <span>{{ (expanded ? 'Collapse' : 'Expand') | translate }}</span>
    <progress
      tuiProgressBar
      [max]="max"
      [value]="allTotal"
      style="height: 45px"
      (click)="expanded = !expanded"
    ></progress>
  </label>

  <tui-expand [expanded]="expanded" *tuiLet="value$ | async as value">
    <tui-scrollbar style="max-height: 500px">
      <div class="content-popup" *ngFor="let val of value">
        <div class="header-import">
          <div class="header-txt">
            <span>
              {{ 'Import' | translate }} {{ val?.total }}
              {{ getEnding(val?.total, 'object', true) | translate }}:
            </span>
            <span class="success">
              {{ val?.success }} - {{ ('Successfully' | translate).toLowerCase() }},
            </span>
            <span class="fail">
              {{ val['fail'] }} - {{ ('With an error' | translate).toLowerCase() }}
            </span>
          </div>
        </div>

        <div class="progress">
          <label *ngIf="val" tuiProgressLabel class="label-wrapper">
            {{ val?.progress?.toFixed(2) }}%
            <progress tuiProgressBar [max]="max" [value]="val.progress"></progress>
          </label>

          <button
            tuiButton
            [size]="'xs'"
            [appearance]="'transparent'"
            [disabled]="val.status !== 'DONE'"
            (click)="openDialog(template, val.taskId)"
          >
            <tui-svg [src]="'common/assets/icons/reports.svg'"></tui-svg>
          </button>

          <button
            tuiButton
            [size]="'xs'"
            [appearance]="'transparent'"
            [disabled]="val.status !== 'DONE'"
            (click)="deleteProcess(val.taskId)"
          >
            <tui-svg [src]="'tuiIconCloseLarge'"></tui-svg>
          </button>
        </div>
      </div>
    </tui-scrollbar>
  </tui-expand>
</div>

<ng-template #template let-confirm>
  <div class="template-result">
    <h3>{{ 'Objects import report' | translate }}</h3>
    <ol class="tui-list tui-list_ordered tui-list_nested">
      <ng-container *ngFor="let item of reportList">
        <li class="tui-list__item">{{ item?.filename }}: {{ item?.errorText }}</li>
      </ng-container>
    </ol>

    <div class="btn-block">
      <button tuiButton type="button" appearance="outline" size="s" (click)="confirm.complete()">
        {{ 'Close' | translate }}
      </button>
      <button
        tuiButton
        type="button"
        appearance="primary"
        size="s"
        (click)="downloadTextFile(reportList)"
      >
        {{ 'Download the error report' | translate }}
      </button>
    </div>
  </div>
</ng-template>
