import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationSensorTypesService {
  availableSensorTypes: any = [];

  constructor(private translate: TranslateService) {
    translate
      .get([
        'MILEAGE',
        'DIGITAL',
        'INDICATORS',
        'ENGINE',
        'FUEL',
        'ODOMETER',
        'IGNITION_SENSOR',
        'INSTANT_MOTION_SENSOR',
        'RANDOM_DIGITAL_SENSOR',
        'VOLTAGE_SENSOR',
        'WEIGHT_SENSOR',
        'ACCELEROMETER',
        'TEMPERATURE_SENSOR',
        'ENGINE_SPEED_SENSOR',
        'ENGINE_EFFICIENCY_SENSOR',
        'ABSOLUTE_ENGINE_HOURS',
        'RELATIVE_ENGINE_HOURS',
        'PULSE_FUEL_CONSUMPTION_SENSOR',
        'ABSOLUTE_FUEL_CONSUMPTION_SENSOR',
        'INSTANT_FUEL_CONSUMPTION_SENSOR',
        'FUEL_LEVEL_SENSOR',
        'PULSE_FUEL_LEVEL_SENSOR',
        'OTHER_SENSORS_LABEL',
        'DRIVER_ASSIGN',
        'Custom sensor',
      ])
      .subscribe((res: any) => {
        this.availableSensorTypes = [
          {
            label: res.MILEAGE,
            items: [
              { id: 'odometer', title: res.ODOMETER }, // title: 'Датчик пробега',
            ],
          },
          {
            label: res.DIGITAL, // цифровые
            items: [
              { id: 'ignition_sensor', title: res.IGNITION_SENSOR }, // title: 'Датчик зажигания',
              {
                id: 'instant_motion_detection_sensor',
                title: res.INSTANT_MOTION_SENSOR,
              }, // title: 'Датчик мгновенного определения движения',
              {
                id: 'custom_digital_sensor',
                title: res.RANDOM_DIGITAL_SENSOR,
              }, // title: 'Произвольный цифровой датчик',
            ],
          },
          {
            label: res.INDICATORS, // показатели
            items: [
              { id: 'voltage_sensor', title: res.VOLTAGE_SENSOR }, // title: 'Датчик напряжения',
              { id: 'weight_sensor', title: res.WEIGHT_SENSOR }, // title: 'Датчик веса',
              { id: 'accelerometer', title: res.ACCELEROMETER }, // title: 'Акселерометр',
              { id: 'temperature', title: res.TEMPERATURE_SENSOR }, // title: 'Датчик температуры',
            ],
          },
          {
            label: res.ENGINE, // двигатель
            items: [
              { id: 'engine_speed_sensor', title: res.ENGINE_SPEED_SENSOR }, // title: 'Датчик оборотов двигателя',
              {
                id: 'engine_usable_operation_sensor',
                title: res.ENGINE_EFFICIENCY_SENSOR,
              }, // title: 'Датчик полезной работы двигателя',
              {
                id: 'absolute_running_hours',
                title: res.ABSOLUTE_ENGINE_HOURS,
              }, // title: 'Абсолютные моточасы',
              {
                id: 'relative_running_hours',
                title: res.RELATIVE_ENGINE_HOURS,
              }, // title: 'Относительные моточасы',
            ],
          },
          {
            label: res.FUEL, // топливо
            items: [
              {
                id: 'fuel_impulse_sensor',
                title: res.PULSE_FUEL_CONSUMPTION_SENSOR,
              }, // title: 'Импульсный датчик расхода топлива',
              {
                id: 'absolute_fuel_impulse_sensor',
                title: res.ABSOLUTE_FUEL_CONSUMPTION_SENSOR,
              }, // title: 'Датчик абсолютного расхода топлива',
              {
                id: 'current_fuel_impulse_sensor',
                title: res.INSTANT_FUEL_CONSUMPTION_SENSOR,
              }, // title: 'Датчик мгновенного расхода топлива',
              { id: 'fuel_level_sensor', title: res.FUEL_LEVEL_SENSOR }, // title: 'Датчик уровня топлива',
            ],
          },
          {
            label: res.OTHER_SENSORS_LABEL, // другие
            items: [
              { id: 'driver_assign', title: res.DRIVER_ASSIGN }, // title: 'Назанчение водителя',
              {
                id: 'custom_sensor',
                title: res['Custom sensor'],
              },
            ],
          },
        ];
      });
  }

  isDigitalSensorType(sensorType: string): boolean {
    return ['ignition_sensor', 'instant_motion_detection_sensor', 'custom_digital_sensor'].some(
      (type: string) => type === sensorType,
    );
  }

  splitUnitFromDigitalSensor(str: string, val: number) {
    const arr = str ? str.split('/') : [];
    const defaultValue = this.translate.instant('on/off');

    if (arr.length === 2) {
      return arr.reverse()[val];
    } else {
      return defaultValue.split('/')[val];
    }
  }
}
