import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, tap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TracksService {
  constructor(
    private httpClient: HttpClient,
    private store: Store,
  ) {}

  public selectedTrack = new ReplaySubject(1);

  public deleteTrack = new Subject();

  public closeTrack = new Subject();

  public addTrack = new Subject();

  public addresses: any = {};

  textErrOnNotCoordinates = 'There is no data for the selected period';

  createTrack(
    objectId: number,
    startDate: string,
    endDate: string,
    trackType: string,
    detectorTrips: boolean | null,
    withStops: boolean = false,
    withParkings: boolean = false,
    withRefuels: boolean = false,
    withPlums: boolean = false,
    withOverSpeed: boolean = false,
  ) {
    return this.httpClient
      .post('tracks/create/', {
        object_id: objectId,
        start_date: startDate,
        end_date: endDate,
        track_type: trackType,
        detect_trips: detectorTrips || false,
        withStops,
        withParkings,
        withRefuels,
        withPlums,
        withOverSpeed,
      })
      .pipe(
        tap((track: any) => {
          if (track.track.messagesCoordinates.length === 0) {
            throw new Error(this.textErrOnNotCoordinates);
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        }),
      );
  }

  getTrips(objectId: number, startDate: string, endDate: string) {
    return this.httpClient.post('tracks/trips/', {
      object_id: objectId,
      start_date: startDate,
      end_date: endDate,
    });
  }

  getStops(objectId: number, startDate: string, endDate: string) {
    return this.httpClient.post('tracks/stops/', {
      object_id: objectId,
      start_date: startDate,
      end_date: endDate,
    });
  }

  getParkings(objectId: number, startDate: string, endDate: string) {
    return this.httpClient.post('tracks/parkings/', {
      object_id: objectId,
      start_date: startDate,
      end_date: endDate,
    });
  }

  getRefuels(objectId: number, startDate: string, endDate: string) {
    return this.httpClient.post('tracks/refuels/', {
      object_id: objectId,
      start_date: startDate,
      end_date: endDate,
    });
  }

  getPlums(objectId: number, startDate: string, endDate: string) {
    return this.httpClient.post('tracks/plums/', {
      object_id: objectId,
      start_date: startDate,
      end_date: endDate,
    });
  }
}
