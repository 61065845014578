import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  TuiContextWithImplicit,
  TuiDestroyService,
  tuiPure,
  TuiStringHandler,
} from '@taiga-ui/cdk';
import * as _ from 'lodash';
import { filter, takeUntil } from 'rxjs';
import { MapService } from 'src/app/portal/components/map/map.service';
import { MessagesService } from '@services/messages.service';
import { PortalService } from '@services/portal.service';
import { TracksService } from '@services/tracks.service';
import { TuiDurationOptions, tuiHeightCollapse } from '@taiga-ui/core';
import { formatDefaultDateFromISO } from '@common/utils/format-date';

@Component({
  selector: 'app-track-player',
  templateUrl: './track-player.component.html',
  styleUrls: ['./track-player.component.less'],
  animations: [tuiHeightCollapse],
  providers: [TuiDestroyService],
})
export class TrackPlayerComponent implements OnInit {
  @tuiPure
  getAnimation(duration: number): TuiDurationOptions {
    return { value: '', params: { duration } };
  }

  @tuiPure
  stringifyValue(
    items: { value: string | number; title: string }[],
  ): TuiStringHandler<TuiContextWithImplicit<string>> {
    const map: any = new Map(
      items.map(({ value, title }) => [value, title] as [string | number, string]),
    );

    return ({ $implicit }: TuiContextWithImplicit<string>) => map.get($implicit) || '';
  }

  isOpen = true;

  // currentSliderValue: number = 0;
  currentSliderValue = new FormControl(0);

  playInterval: any = null;

  maxSliderValue: number = 0;

  pageSize: number = 500;

  pageDownloaded: any = {};

  availableSpeeds: any = [
    {
      value: 1000,
      title: 'x1',
    },
    {
      value: 100,
      title: 'x10',
    },
    {
      value: 10,
      title: 'x100',
    },
  ];

  readonly stringify = (item: any): string => `${item.title}`;

  currentSpeedValue: any = new FormControl(this.availableSpeeds[0].value);

  expandedParams: boolean = true;

  currentTrack: any;

  addresses: any = {};

  get currentAddress() {
    const pos = this.currentTrack.messages[this.currentSliderValue.value || 0]?.pos;
    if (pos) {
      let { y, x } = pos;
      return (
        this.tracksService.addresses[parseFloat(x.toFixed(6)) + '_' + parseFloat(y.toFixed(6))]
          ?.address || '-'
      );
    } else {
      return '-';
    }
  }

  get currentSpeed() {
    return this.currentTrack.messages[this.currentSliderValue.value || 0]?.pos?.s || 0;
  }

  get getMessageParams() {
    return this.currentTrack.messages[this.currentSliderValue.value || 0]?.params;
  }

  get currentDatetime() {
    return formatDefaultDateFromISO(
      this.currentTrack.messages[this.currentSliderValue.value || 0]?.t,
    );
  }

  get currentMessage() {
    return this.currentTrack.messages[this.currentSliderValue.value || 0];
  }

  get currentObject() {
    return this.currentTrack.object;
  }

  constructor(
    private tracksService: TracksService,
    private portalService: PortalService,
    private mapService: MapService,
    private messagesService: MessagesService,
    private destroy$: TuiDestroyService,
  ) {}

  ngOnInit() {
    this.tracksService.selectedTrack
      .pipe(
        filter((track: any) => !!track),
        takeUntil(this.destroy$),
      )
      .subscribe((track: any) => {
        this.stopTrack();

        this.currentTrack = { ...track, messages: [] };
        this.maxSliderValue = this.currentTrack.count - 1;
        this.currentSliderValue.setValue(0);
        const pages = Math.ceil(this.maxSliderValue / this.pageSize);
        if (pages === 0) {
          return;
        }

        for (let i = 0; i < pages; i++) {
          if (i === 0) {
            this.pageDownloaded[i] = { loaded: false, progress: true };
          } else {
            this.pageDownloaded[i] = { loaded: false, progress: false };
          }
        }
        this.messagesService
          .getMessages(
            track.object.id,
            track.startDate,
            track.endDate,
            1,
            this.pageSize,
            'with_data',
            'raw',
            'asc',
          )
          .subscribe((messages: any) => {
            this.pageDownloaded[0].loaded = true;
            this.pageDownloaded[0].progress = false;
            this.currentTrack.messages = messages;
            const coordinates = _.sortBy(
              _.uniqWith(
                messages
                  .filter((o: any) => o?.pos?.x && o?.pos?.y)
                  .map((message: any) => {
                    return {
                      lng: parseFloat(message.pos.x.toFixed(6)),
                      lat: parseFloat(message.pos.y.toFixed(6)),
                    };
                  }),
                _.isEqual,
              ),
              ['lat', 'lng'],
            );
            this.onSliderChange(this.currentSliderValue.value);

            this.mapService.getAddress(coordinates).subscribe((data: any) => {
              this.tracksService.addresses = data;
            });
          });
      });
    this.currentSliderValue.valueChanges.subscribe((value) => {
      this.onSliderChange(value);
    });
    this.currentSpeedValue.valueChanges.subscribe(() => {
      this.stopTrack();
      this.playTrack();
    });
  }

  moveFinishTrack() {
    this.currentSliderValue.setValue(this.maxSliderValue);
  }

  moveStartTrack() {
    this.currentSliderValue.setValue(0);
  }

  prevPoint() {
    this.currentSliderValue.setValue((this.currentSliderValue.value || 0) - 1);
  }

  nextPoint() {
    this.currentSliderValue.setValue((this.currentSliderValue.value || 0) + 1);
  }

  playTrack() {
    if (this.playInterval !== null) {
      this.stopTrack();
      return;
    }
    this.playInterval = setInterval(() => {
      this.updateNextPoint();
    }, this.currentSpeedValue.value);
  }

  updateNextPoint() {
    if (this.currentSliderValue.value === this.maxSliderValue) {
      this.stopTrack();
      return;
    }
    this.currentSliderValue.setValue((this.currentSliderValue.value || 0) + 1);
  }

  stopTrack() {
    if (this.playInterval !== null) {
      clearInterval(this.playInterval);
      this.playInterval = null;
    }
  }

  close() {
    this.portalService.isVisibleTrackPlayer.next(false);
    this.tracksService.closeTrack.next(this.currentTrack);
    this.stopTrack();
  }

  downloadMessages(next = false, callback = () => {}) {
    return new Promise((resolve, reject) => {
      const page = next
        ? Math.ceil((this.currentSliderValue.value || 0) / this.pageSize)
        : Math.floor((this.currentSliderValue.value || 0) / this.pageSize);
      if (
        this.pageDownloaded[page] &&
        !this.pageDownloaded[page].loaded &&
        !this.pageDownloaded[page].progress
      ) {
        this.pageDownloaded[page].progress = true;
        this.messagesService
          .getMessages(
            this.currentObject.id,
            this.currentTrack.startDate,
            this.currentTrack.endDate,
            page + 1,
            this.pageSize,
            'with_data',
            'raw',
            'asc',
          )
          .subscribe((messages: any) => {
            this.pageDownloaded[page].loaded = true;
            this.pageDownloaded[page].progress = false;
            for (let i = page * this.pageSize, j = 0; i < (page + 1) * this.pageSize; i++, j++) {
              this.currentTrack.messages[i] = messages[j];
            }
            callback();
            const coordinates = _.sortBy(
              _.uniqWith(
                messages.map((message: any) => {
                  return {
                    lng: parseFloat(message.pos.x.toFixed(6)),
                    lat: parseFloat(message.pos.y.toFixed(6)),
                  };
                }),
                _.isEqual,
              ),
              ['lat', 'lng'],
            );
            const difference = _.difference(
              coordinates.map((c: any) => `${c.lng}_${c.lat}`),
              Object.keys(this.tracksService.addresses),
            );
            resolve(true);
            if (difference.length > 0) {
              const coordinatesReq = difference.map((a: any) => {
                const c = a.split('_');
                return {
                  lat: c[1],
                  lng: c[0],
                };
              });
              this.mapService.getAddress(coordinatesReq).subscribe((data: any) => {
                this.tracksService.addresses = {
                  ...this.tracksService.addresses,
                  ...data,
                };
              });
            }
          });
      } else {
        resolve(true);
      }
    });
  }

  onSliderChange(event: any) {
    const page = Math.floor((event || 0) / this.pageSize);
    const position = event || 0;
    const needDownload = position / ((page + 1) * this.pageSize) > 0.6;
    const download = (event: any, playInterval: any, next = false, callback = () => {}) => {
      if (playInterval && !next) {
        this.stopTrack();
      }
      this.downloadMessages(next, callback).then(() => {
        if (playInterval && !next) {
          this.playTrack();
        }
      });
    };
    if (this.currentMessage) {
      this.mapService.updateTrackObjectPoint.next({
        message: this.currentMessage,
        object: this.currentObject,
      });
      if (needDownload) {
        download(event, this.playInterval !== null, true);
      }
    } else {
      download(event, this.playInterval !== null, false, () => {
        this.mapService.updateTrackObjectPoint.next({
          message: this.currentMessage,
          object: this.currentObject,
        });
      });
    }
  }
}
