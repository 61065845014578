import { Component, ElementRef, Inject } from '@angular/core';
import {
  tuiAsViewport,
  TuiDurationOptions,
  tuiHeightCollapse,
  TuiRectAccessor,
} from '@taiga-ui/core';
import { tuiPure } from '@taiga-ui/cdk';
import { CompareService } from './compare.service';

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.less'],
  animations: [tuiHeightCollapse],
  providers: [tuiAsViewport(CompareComponent)],
})
export class CompareComponent extends TuiRectAccessor {
  @tuiPure
  getAnimation(duration: number): TuiDurationOptions {
    return { value: '', params: { duration } };
  }

  isOpen = true;

  columns = [
    'name',
    'startValue',
    'difference',
    'endValue',
    'minValue',
    'averageValue',
    'maxValue',
  ];

  readonly type = 'viewport';

  constructor(
    @Inject(ElementRef) private readonly el: ElementRef<HTMLElement>,
    public compareService: CompareService,
  ) {
    super();

    this.compareService?.data.subscribe((val: any) => {
      console.log(val);
    });
  }

  getCellColorByValue(value: any) {
    const numericValue = parseFloat(value);

    if (isNaN(numericValue) || numericValue === 0) {
      return '';
    }

    return numericValue > 0 ? 'green' : 'red';
  }

  getClientRect() {
    return this.el.nativeElement.getBoundingClientRect();
  }
}
