<h2>{{ currentBuilding.name }}</h2>
<div style="margin-top: 16px">
  <div class="scheme-preview" *ngIf="currentBuilding">
    <!-- style="background-image: url('{{ currentBuilding.schema }}')" -->
    <div class="scheme-preview__image" #schema>
      <img [src]="currentBuilding.schema" />

      <div
        class="beacon__position"
        *ngFor="let device of currentBuilding.devices"
        [style.transform]="getDeviceTransform(device)"
      >
        <tui-hosted-dropdown
          tuiDropdownHover
          tuiDropdownAlign="left"
          [content]="hintTemplate"
          [tuiDropdownMaxHeight]="500"
          tuiActiveZone="tuiActiveZone"
        >
          <div
            class="beacon__item"
            [class.scale-animation]="(activeDevice | async) === device.uniqueId"
            [class.is-green]="getBeaconCount(device.uniqueId)"
          >
            <tui-svg [src]="'common/assets/icons/union.svg'"></tui-svg>
            <div *ngIf="getBeaconCount(device.uniqueId)" class="objects-count">
              {{ getBeaconCount(device.uniqueId) }}
            </div>
            <ng-template #hintTemplate>
              <div class="beacon-tooltip">
                <div class="name">{{ device.name }}</div>
                <div class="beacont-tooltip__list">
                  <div
                    class="beacon-tooltip__item"
                    *ngFor="let objects of getBeaconList(device.uniqueId)"
                  >
                    <div>{{ objects.name }}</div>
                    <div>{{ formatDateToRelativeFromISO(objects.t) }}</div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </tui-hosted-dropdown>
      </div>
    </div>
  </div>
</div>
