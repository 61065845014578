import { Pipe, PipeTransform } from '@angular/core';
import { formatDefaultDateFromISO } from '@common/utils/format-date';
import { DateTimeService } from '@common/services/date-time.service';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'cellTransform',
})
export class CellTransformPipe implements PipeTransform {
  dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(\+\d{2}:\d{2})?$/;

  // дн чч:мм:сс / d hh:mm:ss
  dhhmmss = [
    'time_total',
    'timeTotal',
    'time_between',
    'timeBetween',
    'time_segment',
    'timeSegment',
    'time_to_next',
    'timeToNext',
    'time_trips',
    'timeTrips',
    'time_on_move',
    'timeOnMove',
    'planned_time_to_point',
    'plannedTimeToPoint',
    'actual_time_to_point',
    'actualTimeToPoint',
    'entry_time_deviation',
    'entryTimeDeviation',
    'exit_time_deviation',
    'exitTimeDeviation',
    'duration',
    'duration_of_stay',
    'durationOfStay',
    'parking',
    'duration_parking',
    'durationParking',
    'duration_presence',
    'durationPresence',
    'trip_duration',
    'tripDuration',
  ];

  // чч:мм:сс / hh:mm:ss
  hhmmss = [
    'useful_work_duration',
    'usefulWorkDuration',
    'in_move',
    'inMove',
    'idling',
    'engine_hours',
    'engineHours',
    'starting_engine_hours',
    'startingEngineHours',
    'final_engine_hours',
    'finalEngineHours',
    'engine_hours_in_motion',
    'engineHoursInMotion',
    'idling_engine_hours',
    'idlingEngineHours',
    'engine_hours_counter',
    'engineHoursCounter',
  ];

  durationVariables = [
    'duration_of_stay',
    'durationOfStay',
    'time_total',
    'timeTotal',
    'duration_parking',
    'durationParking',
    'time_between',
    'timeBetween',
    'duration',
    'time_trips',
    'timeTrips',
    'timeOnMove',
    'parking',
  ];

  engineHoursVariables = [
    'engine_hours',
    'engineHours',
    // 'state_by_engine_hours', литры в часах
    // 'stateByEngineHours', литры в часах
    'starting_engine_hours',
    'startingEngineHours',
    'final_engine_hours',
    'finalEngineHours',
    'engine_hours_in_motion',
    'engineHoursInMotion',
    'idling_engine_hours',
    'idlingEngineHours',
    'engine_hours_counter',
    'engineHoursCounter',
    // 'spent_by_engine_hours', литры в часах
    // 'spentByEngineHours', литры в часах
    // 'average_consumption_by_engine_hours', литры в часах
    // 'averageConsumptionByEngineHours', литры в часах
  ];

  constructor(
    private dateTime: DateTimeService,
    private translate: TranslateService,
  ) {}

  transform(value: any, ...args: string[]): string {
    if (this.isDateInUTCFormat(value)) {
      return formatDefaultDateFromISO(value);
    }
    if (this.durationVariables.includes(args[0]) && !isNaN(parseFloat(value))) {
      if (this.dhhmmss.includes(args[0])) {
        return this.dateTime.formatTimeFromSeconds(Number(value), 'd hh:mm:ss');
      } else if (this.hhmmss.includes(args[0])) {
        return this.dateTime.formatTimeFromSeconds(Number(value), 'hh:mm:ss');
      }
      return this.dateTime.formatTimeFromSeconds(Number(value));
    }
    if (this.engineHoursVariables.includes(args[0])) {
      if (this.dhhmmss.includes(args[0])) {
        return this.dateTime.formatTimeFromHours(Number(value), 'd hh:mm:ss');
      } else if (this.hhmmss.includes(args[0])) {
        return this.dateTime.formatTimeFromHours(Number(value), 'hh:mm:ss');
      }
      return this.dateTime.formatTimeFromHours(Number(value));
    }
    if (args[0] === 'perimeter') {
      return `${this.formatDistanceInKilometers(value)}${value ? this.formatDistance(value) : ''}`;
    }
    if (args[0] === 'area') {
      return `${value ?? '-'} ${value ? this.translate.instant('GA') : ''}`;
    }
    return `${value ?? '-'}`;
  }

  isDateInUTCFormat(value: any): boolean {
    return (
      typeof value === 'string' &&
      this.dateRegex.test(value) &&
      DateTime.fromISO(value, { zone: 'utc' }).isValid
    );
  }

  formatDistance(value: number): string {
    if (value === null || isNaN(value)) {
      return '-';
    }

    const formattedValue = Math.abs(value % 1) > 0 ? value.toFixed(4) : value;
    const unit = this.translate.instant('m');

    return `, (${formattedValue} ${unit})`;
  }

  formatDistanceInKilometers(value: number): string {
    if (value === null || isNaN(value)) {
      return '-';
    }

    const distanceInKm = value / 1000;
    const formattedDistance =
      Math.abs(distanceInKm % 1) > 0 ? distanceInKm.toFixed(4) : distanceInKm;
    const unit = this.translate.instant('km');
    return `${formattedDistance} ${unit}`;
  }
}
