import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { TuiDestroyService } from '@taiga-ui/cdk';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, of, skip, Subject, takeUntil } from 'rxjs';
import { MapService } from 'src/app/portal/components/map/map.service';
import { MessagesService } from '@services/messages.service';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorService } from '@common/services/paginator.service';
import { formatDefaultDateFromISO, formatDefaultDateFromSeconds } from '@common/utils/format-date';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationSensorTypesService } from '@common/services/translation-sensor-types.service';
import { COMMAND_AVAILABLE_TYPES } from '@common/utils/consts/object-commands';

@Component({
  selector: 'app-messages-table',
  templateUrl: './messages-table.component.html',
  styleUrls: ['./messages-table.component.less'],
  providers: [TuiDestroyService],
})
export class MessagesTableComponent implements OnInit {
  @Input() paginatorService: PaginatorService = new PaginatorService();
  @Input() changeFilterEvent: Subject<any> = new Subject();
  @Input() isNoWrapTable: boolean = true;
  @Input() topValue = 0;

  messagesData: any;

  columnTitlesDefault: any = {
    datetime: 'DATE',
    dateRegister: 'Registration date',
    speed: 'Speed',
    coordinates_str: 'Coordinates',
    height: 'HEIGHT',
    address: 'Address',
  };

  columnCommandsTitlesDefault: any = {
    t: 'Time',
    username: 'User',
    command_name: 'Command name',
    command_type: 'Command type',
    params: 'Params',
    sended_at: 'Execution time',
  };

  columnTitles: any = { ...this.columnTitlesDefault };

  columns: any = Object.keys(this.columnTitles);

  datas: any = new BehaviorSubject([]);

  length = 0;

  requestOptions: any = null;

  isLoading = true;

  pageData = {};

  filterValue: string | null = null;
  loadingAddresses: boolean = false;

  windowHeight = window.innerHeight;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowHeight = window.innerHeight;
  }

  constructor(
    private messagesService: MessagesService,
    private mapService: MapService,
    private translationSensorTypes: TranslationSensorTypesService,
    private translate: TranslateService,
    private destroy$: TuiDestroyService,
    @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
  ) {
    this.messagesService.messagesData.pipe(takeUntil(destroy$)).subscribe((res: any) => {
      this.columnTitles = { ...this.columnTitlesDefault };
      this.columns = Object.keys(this.columnTitles);
      this.messagesData = res;
      this.paginatorService.lengthList$.next(res?.count);
    });
  }

  ngOnInit() {
    this.paginatorService.lengthList$.next(this.messagesData.count);
    this.paginatorService.pageSize$.next(50);
    this.goToPage(1, 50);

    this.paginatorService.pageIndex$
      .pipe(takeUntil(this.destroy$))
      .pipe(skip(1))
      .subscribe((val) => {
        this.goToPage(val < 1 ? 1 : val);
      });

    this.paginatorService.pageSize$
      .pipe(takeUntil(this.destroy$))
      .pipe(skip(1))
      .subscribe((val) => {
        this.goToPage(1, val);
      });
    this.changeFilterEvent.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.filterValue = val;
      this.preparePage(this.pageData);
    });
  }

  preparePage(data: any, filteredValue: string | null = this.filterValue) {
    const timezone = sessionStorage.getItem('timezone') || 'utc';
    let regexpValue = [new RegExp('')];
    if (filteredValue) {
      regexpValue = filteredValue.split(',').map((f: any) => new RegExp(`${f}`, 'gi'));
    }
    const page = data
      .filter((m: any) => {
        if (!filteredValue || this.messagesData.type === 'sensors') {
          return true;
        }
        return Object.entries(m.params)
          .map((p: any) => `${p[0]}=${p[1]}`)
          .some((p: any) => regexpValue.some((r) => r.test(p)));
      })
      .map((m: any) => {
        const dateRegister = DateTime.fromSeconds(m.rt);
        return {
          datetime: formatDefaultDateFromISO(m.t),
          dateRegister: formatDefaultDateFromSeconds(m.rt),
          speed: m.pos?.s ? Number(m.pos?.s).toFixed(2) : '-',
          coordinates: [
            m.pos?.y ? Number(m.pos?.y).toFixed(6) : null,
            m.pos?.x ? Number(m.pos?.x).toFixed(6) : null,
          ],
          coordinates_str:
            m?.pos?.x && m?.pos?.y
              ? `${Number(m.pos?.y).toFixed(6) || ''}, ${Number(m.pos?.x).toFixed(6) || ''} (${
                  m.pos?.sc ?? '-'
                })`
              : '-',
          height: m.pos?.z ? Number(m.pos?.z).toFixed(2) : '-',
          x: m.pos?.x ? parseFloat(Number(m.pos?.x).toFixed(6)) : '-',
          y: m.pos?.y ? parseFloat(Number(m.pos?.y).toFixed(6)) : '-',
          address:
            m?.pos?.x && m?.pos?.y
              ? this.getAddress(
                  parseFloat(Number(m.pos?.x).toFixed(6)),
                  parseFloat(Number(m.pos?.y).toFixed(6)),
                )
              : '-',
          styles: this.diffDateTimes(DateTime.fromISO(m.t), dateRegister, timezone),
          ...this.prepareParams(m, filteredValue),
        };
      });
    this.datas.next(page);
    this.isLoading = false;
  }

  diffDateTimes(d1: DateTime, d2: DateTime, timezone: string) {
    const diff = d2.setZone(timezone).diff(d1.setZone(timezone), 'minutes');
    const duration = diff.minutes;

    switch (true) {
      case duration > 2 && duration < 10:
        return { background: '#E5EEFC', color: '#333333' };
      case duration > 10 && duration < 30:
        return { background: '#DBE8FB', color: '#333333' };
      case duration > 30:
        return { background: '#D2E1FA', color: '#333333' };
      default:
        return { background: `var(--tui-base-01)`, color: `var(--tui-text-02)` };
    }
  }

  goToPage(pageIndex: number, pageSize: number = this.paginatorService.pageSize$.value) {
    if (
      _.isEqual(this.requestOptions, {
        objectId: this.messagesData.requestParams.objectId,
        startDate: this.messagesData.requestParams.startDate,
        endDate: this.messagesData.requestParams.endDate,
        pageIndex,
        pageSize,
        messageType: this.messagesData.requestParams.messageType,
        param: this.messagesData.requestParams.param,
      })
    ) {
      return;
    }
    this.isLoading = true;
    if (this.messagesData.requestParams.messageType === 'sended_commands') {
      this.goToPageCommands(pageIndex, pageSize);
      this.columnTitles = { ...this.columnCommandsTitlesDefault };
      this.columns = Object.keys(this.columnCommandsTitlesDefault);
      return;
    }
    this.requestOptions = {
      objectId: this.messagesData.requestParams.objectId,
      startDate: this.messagesData.requestParams.startDate,
      endDate: this.messagesData.requestParams.endDate,
      pageIndex,
      pageSize,
      messageType: this.messagesData.requestParams.messageType,
      param: this.messagesData.requestParams.param,
    };
    this.messagesService
      .getMessages(
        this.messagesData.requestParams.objectId,
        this.messagesData.requestParams.startDate,
        this.messagesData.requestParams.endDate,
        pageIndex,
        pageSize,
        this.messagesData.requestParams.messageType,
        this.messagesData.requestParams.param,
      )
      .subscribe((data: any) => {
        this.pageData = data;
        const coordinates = data
          .filter((m: any) => m.pos?.x && m.pos?.y)
          .map((m: any) => {
            return `${parseFloat(m.pos.x.toFixed(6))}_${parseFloat(m.pos.y.toFixed(6))}`;
          });
        const difference = _.difference(coordinates, Object.keys(this.messagesService.addresses));
        if (difference.length > 0) {
          this.loadingAddresses = true;
          this.mapService
            .getAddress(
              difference.map((c: string) => ({
                lat: c.split('_')[1],
                lng: c.split('_')[0],
              })),
            )
            .subscribe((res: any) => {
              this.messagesService.addresses = {
                ...this.messagesService.addresses,
                ...res,
              };
              this.loadingAddresses = false;
            });
          this.preparePage(data);
        } else {
          this.preparePage(data);
        }
      });
  }

  goToPageCommands(pageIndex: number, pageSize: number = this.paginatorService.pageSize$.value) {
    this.requestOptions = {
      objectId: this.messagesData.requestParams.objectId,
      startDate: this.messagesData.requestParams.startDate,
      endDate: this.messagesData.requestParams.endDate,
      pageIndex,
      pageSize,
      messageType: this.messagesData.requestParams.messageType,
      param: this.messagesData.requestParams.param,
    };
    this.messagesService
      .getSendedCommands(
        this.messagesData.requestParams.objectId,
        this.messagesData.requestParams.startDate,
        this.messagesData.requestParams.endDate,
        pageIndex,
        pageSize,
        this.messagesData.requestParams.messageType,
        this.messagesData.requestParams.param,
      )
      .subscribe((data: any) => {
        this.datas.next(
          data.map((m: any) => ({
            ...m,
            t: formatDefaultDateFromISO(m.t),
            sended_at: formatDefaultDateFromISO(m.sended_at),
            command_type:
              COMMAND_AVAILABLE_TYPES.filter((t: any) => t.id === m.command_type).map((item) => ({
                ...item,
                title: this.translate.instant(item.title),
              }))[0]?.title || '-',
          })),
        );
        this.isLoading = false;
      });
  }

  prepareParams(m: any, filteredValue: any = null): any {
    if (this.messagesData?.type === 'sensors') {
      let result: any = {};
      for (let sensor of this.messagesData.object.sensors) {
        const isDigitalSensor = this.translationSensorTypes.isDigitalSensorType(sensor.type);

        if (!this.columns.includes(`sensor__${sensor.id}`)) {
          this.columns.push(`sensor__${sensor.id}`);
          this.columnTitles[`sensor__${sensor.id}`] = sensor.name;
        }
        result[`sensor__${sensor.id}`] = `${
          m.sensors['sensor__' + sensor.id] != null
            ? isDigitalSensor
              ? this.translationSensorTypes.splitUnitFromDigitalSensor(
                  sensor.unit,
                  m.sensors['sensor__' + sensor.id],
                )
              : m.sensors['sensor__' + sensor.id]
            : '-'
        } ${isDigitalSensor ? '' : sensor.unit || ''}`;
      }

      return result;
    } else {
      if (!this.columns.includes(`params`)) {
        this.columns.push(`params`);
        this.columnTitles.params = 'PARAMETERS';
      }
    }
    if (filteredValue) {
      const regexpValue = filteredValue.split(',').map((r: any) => new RegExp(`${r}`, 'gi'));
      const params = Object.entries(m.params).map((p: any) => `${p[0]}=${p[1]}`);
      return {
        params: params
          .sort((a, b) => {
            return regexpValue.some((r: any) => a.search(r) !== -1) ? -1 : 1;
          })
          .map((p) =>
            regexpValue.some((r: any) => p.search(r) !== -1)
              ? `<span class="highlight">${p}</span>`
              : p,
          )
          .join(', '),
      };
    }
    return {
      params: Object.entries(m.params)
        .map((p: any) => `${p[0]}=${p[1]}`)
        .join(', '),
    };
  }

  onCellClick(column: any, item: any) {
    if (column === 'coordinates_str') {
      const coords = item.coordinates;
      if (coords[0] && coords[1]) {
        this.mapService.panTo.next({ lat: coords[0], lng: coords[1] });
      }
    }
  }

  checkCellPoint(column: string) {
    return ['coordinates_str'].includes(column);
  }

  getAddress(x: number, y: number) {
    if (!x && !y) return '-';
    return this.messagesService.addresses[x + '_' + y]?.address || '-';
  }

  getAddressAsync(x: number, y: number) {
    if (this.loadingAddresses) return of(`${this.translate.instant('Loading')}...`);
    if (!x && !y) return of('-');
    return of(this.messagesService.addresses[x + '_' + y]?.address) || of('-');
  }

  trackByFn(obj: any): string {
    return JSON.stringify(obj);
  }
}
