import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCurrentUser } from '@store/actions/current_user.actions';
import { getDeviceModels } from '@store/actions/device_models.actions';
import { getObjects } from '@store/actions/objects.actions';
import { selectObjectsByIds } from '@store/selectors/objects.selectors';
import { MapService } from './components/map/map.service';
import { PortalService } from '@services/portal.service';
import { getGeozones } from '@store/actions/geozones.actions';
import { getBuildings } from '@store/actions/buildings.actions';
import { appLoaded } from '@store/selectors/app.selectors';
import { getDrivers } from '@store/actions/drivers.actions';
import { TuiThemeNightService } from '@taiga-ui/addon-doc';
import { selectCurrentUser } from '@store/selectors/current_user.selectors';
import { getImportObjects } from '@store/actions/import_objects.actions';
import { selectImportObjects } from '@store/selectors/import_objects.selector';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.less'],
  providers: [TuiDestroyService],
})
export class PortalComponent implements OnInit {
  widthPortal: string = '500px';
  heightMessages = window.innerHeight;
  topValue = this.heightMessages / 2;

  appLoading: boolean = true;

  visibleObjectsIds: number[] = [];

  importObjectsList$ = this.store.select(selectImportObjects);

  isVisibleMessages = false;
  isVisibleReport = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (!this.isVisibleMessages && !this.isVisibleReport) {
      this.heightMessages = window.innerHeight;
    }
  }

  constructor(
    @Inject(TuiThemeNightService) readonly night: TuiThemeNightService,
    private store: Store,
    private mapService: MapService,
    public portalService: PortalService,
    private destroy$: TuiDestroyService,
  ) {
    this.night.next(!!window.localStorage.getItem('theme') || false);
  }

  ngOnInit(): void {
    this.store.dispatch(getObjects());
    this.store.dispatch(getDeviceModels());
    this.store.dispatch(getCurrentUser());
    this.store.dispatch(getGeozones());
    this.store.dispatch(getBuildings());
    this.store.dispatch(getDrivers());
    this.store.dispatch(getImportObjects());
    // TODO: maybe сделать что бы в сторе хранились id выбранных объектов и подписываться на них в карте
    // this.store.select(selectObjects).subscribe((objects: any) => {
    //   this.mapService.selectedObjects.next(objects);
    // });

    this.store.select(selectCurrentUser).subscribe((user) => {
      this.visibleObjectsIds = [...(user?.visibleObjectsIds || [])];
    });

    this.store
      .select((state) => selectObjectsByIds(state, this.visibleObjectsIds))
      .subscribe((objects) => {
        this.mapService.drawMapObjects.next(objects);
      });

    this.portalService.isVisibleMessages.pipe(takeUntil(this.destroy$)).subscribe((isVisible) => {
      this.heightMessages = isVisible ? this.topValue : window.innerHeight;
      this.isVisibleMessages = isVisible;
    });

    this.portalService.isVisibleReportResult
      .pipe(takeUntil(this.destroy$))
      .subscribe((isVisible) => {
        this.heightMessages = isVisible ? this.topValue : window.innerHeight;
        this.isVisibleReport = isVisible;
      });

    const storedWidth = parseInt(localStorage.getItem('widthPortal') || '');
    this.widthPortal = isNaN(storedWidth) ? this.widthPortal : `${storedWidth}px`;
    this.store.select(appLoaded).subscribe((loaded) => {
      this.appLoading = !loaded;
    });
  }

  onChangeSizeContentRoute($event: readonly [x: number, y: number]) {
    localStorage.setItem('widthPortal', `${$event[0]}`);
  }

  onChangeSizeMap($event: readonly [x: number, y: number]) {
    this.topValue = $event[1];
    if (this.topValue < 100) {
      this.topValue = 100;
    } else if (this.topValue > window.innerHeight - 200) {
      this.topValue = window.innerHeight - 200;
    }
  }
}
