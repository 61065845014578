import { Component, Input, OnInit } from '@angular/core';
import { ReportsService } from '@services/reports.service';

@Component({
  selector: 'app-report-result',
  templateUrl: './report-result.component.html',
  styleUrls: ['./report-result.component.less'],
})
export class ReportResultComponent implements OnInit {
  @Input() topValue = 0;

  isNoWrapTable: boolean = true;

  constructor(public reportService: ReportsService) {}

  ngOnInit() {
    this.reportService.paginatorService.pageIndex$.next(1);
  }

  onClickIsStroke() {
    this.isNoWrapTable = !this.isNoWrapTable;
  }
}
