import { getSearchSortValue } from '@common/utils/search-sort';

export const selectObjects = (state: any) => state.objects.list;

export const selectObjectById = (state: any, { objectId }: { objectId: number | string }) =>
  state.objects.list.find((o: any) => String(o.id) === String(objectId));

export const selectObjectsByIds = (state: any, objectsIds: number[]) => {
  return state.objects.list.filter((f: any) => objectsIds.includes(f.id));
};

export const selectObjectsNotGroupByIds = (state: any, objectsIds: number[]) => {
  return state.objects.list.filter((f: any) => !objectsIds.includes(f.id));
};

export const selectAvailableObjectsByCurrentUserAccessKey = (state: any, key: string) => {
  return state.objects.list.filter((f: any) => f.currentUserAccess.includes(key));
};

export const selectSearchedSortedObjects = (
  state: any,
  { searchValue, sortedValue, objectsIds }: any = {
    searchValue: null,
    sortedValue: null,
    objectsIds: null,
  },
) => {
  // console.log(objectsIds);
  // console.log(searchValue, sortedValue);
  const list = objectsIds
    ? state.objects.list.filter((f: any) => objectsIds.includes(f.id))
    : [...state.objects.list];

  return getSearchSortValue(list, searchValue, sortedValue, ['name', 'uniqueId']);
};
