export const markerIcons: any = {
  start: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.83936" cy="8.83936" r="7.33936" fill="#10B981" stroke="#10B981" stroke-width="3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4898 6.00171L10.655 6.09751C11.5409 6.61116 12.2491 7.0218 12.7314 7.40638C13.2232 7.79846 13.5808 8.24148 13.5808 8.83953C13.5808 9.43759 13.2232 9.88061 12.7314 10.2727C12.2491 10.6573 11.5409 11.0679 10.655 11.5816L10.4898 11.6774C9.60404 12.191 8.89582 12.6017 8.32296 12.8289C7.73921 13.0605 7.17675 13.1508 6.65909 12.8506C6.14211 12.5509 5.93925 12.0178 5.84721 11.3952C5.75681 10.7836 5.75682 9.96257 5.75684 8.935V8.74407C5.75682 7.71651 5.75681 6.89544 5.84721 6.28388C5.93925 5.66124 6.14211 5.12821 6.65909 4.82844C7.17675 4.52827 7.73921 4.61853 8.32296 4.85013C8.89582 5.0774 9.60404 5.48808 10.4898 6.00171Z" fill="white"/>
    </svg>
    `,
  end: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.83936" cy="8.83936" r="7.33936" fill="#10B981" stroke="#10B981" stroke-width="3"/>
    <rect x="5.54688" y="5.32275" width="7.0332" height="7.0332" rx="0.5" fill="white"/>
    </svg>
    `,
  stop: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.83936" cy="8.83936" r="7.33936" fill="#FB1717" stroke="#FB1717" stroke-width="3"/>
    <path d="M10.1068 7.33927C10.0841 7.05518 9.97754 6.83359 9.7872 6.67449C9.5997 6.5154 9.31419 6.43586 8.93066 6.43586C8.68635 6.43586 8.48606 6.46569 8.32981 6.52535C8.1764 6.58216 8.06277 6.66029 7.9889 6.75972C7.91504 6.85915 7.87669 6.97279 7.87385 7.10063C7.86816 7.20574 7.88663 7.30091 7.92924 7.38614C7.9747 7.46853 8.04572 7.54381 8.14231 7.61199C8.2389 7.67734 8.36248 7.73699 8.51305 7.79097C8.66362 7.84495 8.8426 7.89324 9.04998 7.93586L9.76589 8.08927C10.2488 8.19154 10.6622 8.32648 11.0059 8.4941C11.3497 8.66171 11.6309 8.85915 11.8497 9.08643C12.0684 9.31086 12.229 9.5637 12.3312 9.84495C12.4363 10.1262 12.4903 10.433 12.4932 10.7654C12.4903 11.3393 12.3469 11.8251 12.0628 12.2228C11.7787 12.6205 11.3724 12.9231 10.844 13.1305C10.3184 13.3378 9.68635 13.4415 8.94771 13.4415C8.18919 13.4415 7.52725 13.3293 6.96191 13.1049C6.39941 12.8805 5.96191 12.5353 5.64941 12.0694C5.33975 11.6006 5.1835 11.0012 5.18066 10.2711H7.43066C7.44487 10.5381 7.51163 10.7626 7.63095 10.9444C7.75027 11.1262 7.91788 11.264 8.13379 11.3577C8.35254 11.4515 8.61248 11.4984 8.91362 11.4984C9.16646 11.4984 9.37811 11.4671 9.54856 11.4046C9.71902 11.3421 9.84828 11.2555 9.93635 11.1447C10.0244 11.0339 10.0699 10.9074 10.0727 10.7654C10.0699 10.6319 10.0258 10.5154 9.94061 10.416C9.85822 10.3137 9.72186 10.2228 9.53152 10.1432C9.34118 10.0609 9.08407 9.98415 8.76021 9.91313L7.89089 9.72563C7.11816 9.55802 6.50879 9.27819 6.06277 8.88614C5.61958 8.49126 5.39941 7.9529 5.40225 7.27109C5.39941 6.71711 5.54714 6.23273 5.84544 5.81796C6.14657 5.40035 6.56277 5.07506 7.09402 4.84211C7.62811 4.60915 8.24032 4.49268 8.93066 4.49268C9.63521 4.49268 10.2446 4.61057 10.7588 4.84637C11.273 5.08216 11.6693 5.41455 11.9477 5.84353C12.229 6.26966 12.371 6.76824 12.3738 7.33927H10.1068Z" fill="white"/>
    </svg>
    `,
  parking: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.83936" cy="8.83936" r="7.33936" fill="#0B4CA3" stroke="#0B4CA3" stroke-width="3"/>
    <path d="M6.06348 13.639V4.70898H9.91805C10.5808 4.70898 11.1608 4.8398 11.6578 5.10142C12.1549 5.36304 12.5415 5.73077 12.8177 6.20459C13.0939 6.67842 13.2319 7.23219 13.2319 7.86589C13.2319 8.50542 13.0895 9.05918 12.8046 9.5272C12.5226 9.99521 12.1258 10.3557 11.6142 10.6086C11.1055 10.8615 10.5111 10.9879 9.83084 10.9879H7.52856V9.10424H9.34248C9.62735 9.10424 9.87008 9.05482 10.0707 8.95599C10.2741 8.85425 10.4297 8.71035 10.5372 8.52431C10.6477 8.33827 10.7029 8.1188 10.7029 7.86589C10.7029 7.61009 10.6477 7.39207 10.5372 7.21184C10.4297 7.0287 10.2741 6.88917 10.0707 6.79324C9.87008 6.69441 9.62735 6.64499 9.34248 6.64499H8.48784V13.639H6.06348Z" fill="#FEFEFE"/>
    </svg>
    `,
  overSpeed: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
     <circle cx="9" cy="9" r="7.33936" fill="white"/>
     <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8398 9C17.8398 13.8821 13.8821 17.8398 9 17.8398C4.11789 17.8398 0.160156 13.8821 0.160156 9C0.160156 4.11789 4.11789 0.160156 9 0.160156C13.8821 0.160156 17.8398 4.11789 17.8398 9ZM3.81378 11.7751C5.04166 10.9759 6.46553 10.4525 7.99754 10.2928L10.4361 5.91952C10.7223 5.40616 11.3705 5.22206 11.8839 5.50831C12.3973 5.79456 12.5814 6.44277 12.2951 6.95613L10.4056 10.3448C11.7828 10.5495 13.0638 11.0495 14.1819 11.7782C14.6263 10.9509 14.8784 10.0049 14.8784 9.0001C14.8784 5.75285 12.246 3.12043 8.9987 3.12043C5.75145 3.12043 3.11904 5.75285 3.11904 9.0001C3.11904 10.0037 3.37047 10.9485 3.81378 11.7751Z" fill="#FB1717"/>
    </svg>
    `,
  refuel: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.84" cy="8.84" r="8.84" fill="white"/>
    <path d="M13.041 9.47852H11.8685V12.6568C12.5671 12.275 13.041 11.5334 13.041 10.6812V9.47852Z" fill="#0B4CA3"/>
    <path d="M6.90186 4.7338C6.59279 4.7338 6.34224 4.98435 6.34224 5.29342V7.11015C6.34224 7.41922 6.59279 7.66977 6.90186 7.66977H10.2037C10.5128 7.66977 10.7633 7.41922 10.7633 7.11015V5.29342C10.7633 4.98435 10.5128 4.7338 10.2037 4.7338H6.90186Z" fill="#0B4CA3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.83936 17.6787C13.7212 17.6787 17.6787 13.7212 17.6787 8.83936C17.6787 3.95751 13.7212 0 8.83936 0C3.95751 0 0 3.95751 0 8.83936C0 13.7212 3.95751 17.6787 8.83936 17.6787ZM5.19348 4.58398C5.19348 4.0317 5.6412 3.58399 6.19348 3.58399H10.8685C11.4208 3.58399 11.8685 4.0317 11.8685 4.58399V8.62402H13.041V5.44873C13.041 5.03452 13.3768 4.69873 13.791 4.69873C14.2052 4.69873 14.541 5.03452 14.541 5.44873V10.6812C14.541 12.6671 12.9972 14.2925 11.0442 14.4227C10.9872 14.4328 10.9285 14.4381 10.8685 14.4381H6.19348C5.6412 14.4381 5.19348 13.9904 5.19348 13.4381V4.58398Z" fill="#0B4CA3"/>
    </svg>
    `,
  plum: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.84" cy="8.84" r="8.84" fill="white"/>
    <path d="M9.27544 4.7153C9.10393 4.7153 8.939 4.78125 8.81477 4.8995L7.73085 5.93133V6.98393L12.2407 8.57186V7.6806C12.2407 6.04291 10.9131 4.7153 9.27544 4.7153Z" fill="#FB1717"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.83936 17.6787C13.7212 17.6787 17.6787 13.7212 17.6787 8.83936C17.6787 3.95751 13.7212 0 8.83936 0C3.95751 0 0 3.95751 0 8.83936C0 13.7212 3.95751 17.6787 8.83936 17.6787ZM9.60204 3.07349C11.8012 3.07349 13.5839 4.85622 13.5839 7.05534V12.4042C13.5839 13.023 13.2526 13.5944 12.7156 13.9019C10.4139 15.2197 7.57401 15.2127 5.27225 13.8948C4.74309 13.5919 4.4093 13.0314 4.39794 12.4218L4.32185 8.3368V6.92219L3.44468 5.36838L4.88123 4.25124L5.99876 5.14495C6.19704 4.9781 6.45194 4.71218 6.70846 4.42574C7.45093 3.59666 8.4891 3.07349 9.60204 3.07349Z" fill="#FB1717"/>
    </svg>`,
};
