import { Component, Inject, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectImportObjects } from '@store/selectors/import_objects.selector';
import { switchMap, tap } from 'rxjs';
import { ImportObjectService } from '@common/services/import-object.service';
import { deleteImportObjectById } from '@store/actions/import_objects.actions';
import { TuiAlertService, TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { dateNowForFilename } from '@common/utils/format-date';
import { getEnding } from '@common/utils/ending-string-handler';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-progress-import-popup',
  templateUrl: './progress-import-popup.component.html',
  styleUrls: ['./progress-import-popup.component.less'],
})
export class ProgressImportPopupComponent {
  protected readonly getEnding = getEnding;

  max = 100;
  allTotal: any = 0;

  expanded = true;

  reportList: any = [];

  constructor(
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private store: Store,
    private importService: ImportObjectService,
    private translate: TranslateService,
  ) {}

  value$ = this.store.select(selectImportObjects).pipe(
    tap((value) => {
      const totalItems = value.length;

      if (totalItems) {
        // const doneItems = value.filter((p: any) => p?.status === 'DONE').length;
        const totalProgress = value.reduce(
          (acc: any, currentValue: any) => acc + currentValue.progress,
          0,
        );

        this.allTotal = (totalProgress / totalItems).toFixed(2) || 0;
      }
    }),
  );

  deleteProcess(taskId: string) {
    this.importService
      .deleteProcessImport(taskId)
      .pipe(
        tap(() => {
          this.store.dispatch(deleteImportObjectById({ taskId }));
        }),
      )
      .subscribe();
  }

  openDialog(content: TemplateRef<TuiDialogContext>, taskId: string) {
    this.importService
      .getResultImport(taskId)
      .pipe(
        switchMap((value: any) => {
          const resList = value.files.filter((f: any) => f.errorText);
          if (resList?.length) {
            this.reportList = resList;

            return this.dialogs.open(content, {
              dismissible: true,
              size: 'auto',
            });
          } else {
            return this.alerts.open(this.translate.instant('Import completed successfully'), {
              status: 'success',
            });
          }
        }),
      )
      .subscribe();
  }

  downloadTextFile(array: any) {
    const fileName = `import_log_${dateNowForFilename()}.txt`;

    const content = array
      .map((item: any) => `${item?.filename}: ${item?.errorText || ''}`)
      .join('\n');

    setTimeout(() => {
      const element = document.createElement('a');
      const file = new Blob([content], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }, 0);
  }
}
