<div class="view-messages" [style.top.px]="topValue">
  <tui-loader [overlay]="true" [showLoader]="reportService.isLoadingContentOnMap | async">
    <div class="header">
      <div>
        <button
          *ngIf="reportService.selectedTable | async"
          tuiButton
          size="s"
          type="button"
          appearance="transparent"
          class="icon"
          (click)="onClickIsStroke()"
          [tuiHint]="
            isNoWrapTable
              ? ('Show with line break' | translate)
              : ('Show without line break' | translate)
          "
          [tuiHintAppearance]="'custom'"
          [tuiHintDirection]="'right'"
        >
          <tui-svg [src]="isNoWrapTable ? 'tuiIconMinimize2' : 'tuiIconMaximize2'"></tui-svg>
        </button>
      </div>
      <app-table-paginator
        [paginatorService]="reportService.paginatorService"
        [style.display]="(reportService.selectedTable | async) ? 'block' : 'none'"
      ></app-table-paginator>
    </div>
    <div class="view-messages__content">
      <ng-container *ngIf="reportService.selectedTable | async">
        <app-table-report
          [topValue]="topValue"
          [paginatorService]="reportService.paginatorService"
          [isNoWrapTable]="isNoWrapTable"
        ></app-table-report>
      </ng-container>

      <ng-container *ngIf="reportService.selectedChart | async">
        <app-graph-report [topValue]="topValue"></app-graph-report>
      </ng-container>
    </div>
  </tui-loader>
</div>
