<tui-loader [overlay]="isLoading" [showLoader]="isLoading">
  <tui-scrollbar>
    <cdk-virtual-scroll-viewport
      #viewport
      tuiScrollable
      class="tui-zero-scrollbar"
      [itemSize]="45"
      [style.height.px]="windowHeight - topValue - 76"
    >
      <table
        [style.white-space]="isNoWrapTable ? 'nowrap' : ''"
        tuiTable
        class="table"
        [columns]="columns"
      >
        <thead>
          <tr tuiThGroup>
            <th tuiTh [resizable]="true" *ngFor="let column of columns" [sticky]="true">
              {{ columnTitles[column] | translate }}
            </th>
          </tr>
        </thead>
        <tbody tuiTbody>
          <tr *ngFor="let item of datas | async; trackBy: trackByFn" tuiTr>
            <ng-container *ngFor="let column of columns">
              <td
                *tuiCell="column"
                tuiTd
                (click)="onCellClick(column, item)"
                [style.background]="item?.styles?.background || 'var(--tui-base-01)'"
                [style.color]="item?.styles?.color || 'var(--tui-text-02)'"
                [class.pointer]="checkCellPoint(column)"
              >
                <ng-container *ngIf="column !== 'params' && column !== 'address'">{{
                  item[column]
                }}</ng-container>
                <ng-container *ngIf="column === 'params'">
                  <div [innerHTML]="item[column]"></div>
                </ng-container>
                <ng-container *ngIf="column === 'address'">{{
                  getAddressAsync(item.x, item.y) | async
                }}</ng-container>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </cdk-virtual-scroll-viewport>
  </tui-scrollbar>
</tui-loader>
