import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ReportsService } from '@services/reports.service';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { skip, takeUntil } from 'rxjs';
import { PaginatorService } from '@common/services/paginator.service';
import { v4 as uuidV4 } from 'uuid';

@Component({
  selector: 'app-table-report',
  templateUrl: './table-report.component.html',
  styleUrls: ['./table-report.component.less'],
  providers: [TuiDestroyService],
})
export class TableReportComponent implements OnInit {
  @Input() paginatorService: PaginatorService = new PaginatorService();
  @Input() isNoWrapTable: boolean = true;
  @Input() topValue = 0;

  columns: any = [];
  summary: any = null;

  columnsObj: any = [];

  data: any = [];

  tableId: any = null;

  isGrouping = false;
  isVertical = false;

  groupKey: any = {};

  windowHeight = window.innerHeight;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowHeight = window.innerHeight;
  }

  formatColumnName = (columnName: string) =>
    columnName.replace(/_(\w)/g, (_, char) => char.toUpperCase());

  constructor(
    public reportService: ReportsService,
    private destroy$: TuiDestroyService,
  ) {}

  ngOnInit() {
    this.paginatorService.pageIndex$
      .pipe(skip(1))
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.getList(val < 1 ? 1 : val);
      });

    this.paginatorService.pageSize$
      .pipe(skip(1))
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.getList(1);
      });

    this.reportService.selectedTable.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      if (!value) return;
      this.isVertical = value.type === 'statistic' || value.type === 'summary';

      this.columns = value.columns.map((m: any) => this.formatColumnName(m.name));
      this.columnsObj = value.columns;
      this.summary = value.summary;
      this.isGrouping = value.isGrouping;
      this.tableId = value.tableId;
      this.data = value.isGrouping
        ? value.data.map((m: any) => ({ ...m, uid: uuidV4() }))
        : value.data;
      this.reportService.isLoadingReportList.next(false);
    });
  }

  getList(
    page = this.paginatorService.pageIndex$.value,
    perPage = this.paginatorService.pageSize$.value,
  ) {
    const tableId = this.tableId;
    this.reportService.isLoadingContentOnMap.next(true);

    this.reportService.isLoadingReportList.next(false);

    this.reportService.getTableById(tableId, page, perPage).subscribe((res: any) => {
      const results = res?.results || [];
      this.data = this.isGrouping ? results.map((m: any) => ({ ...m, uid: uuidV4() })) : results;
      this.reportService.isLoadingContentOnMap.next(false);
    });
  }

  openGrouping(key: any) {
    this.groupKey[key] = !this.groupKey[key];
  }
}
