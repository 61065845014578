import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { getAreaOfPolygon, getDistance } from 'geolib'; // import 'leaflet.markercluster';
import * as L from 'leaflet';
import { latLng, MapOptions, tileLayer } from 'leaflet';
import 'leaflet-draw';
import leafletPolycolor from 'leaflet-polycolor';
import { EMPTY, filter, switchMap, take, takeUntil, tap } from 'rxjs';
import { selectGeozones } from '@store/selectors/geozones.selectors';
import { GeozonesService } from '@services/geozones.service';
import { PortalService } from '@services/portal.service';
import { TracksService } from '@services/tracks.service';
import { gpsData } from './data';
import { MapService } from './map.service';
import { ObjectsService } from '@services/objects.service';
import { selectObjectById } from '@store/selectors/objects.selectors';
import { TranslateService } from '@ngx-translate/core';
import { selectBuildings } from '@store/selectors/buildings.selectors';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { icons } from '@common/utils/icons';
import { TuiAlertService } from '@taiga-ui/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ObjectTooltipComponent } from '../object-tooltip/object-tooltip.component';
import { DateTimeService } from '@common/services/date-time.service';
import { formatDefaultDateFromISO } from '@common/utils/format-date';
import { markerIcons } from './marker-icons';

leafletPolycolor(L);

L.Edit.Circle.include({
  _resize(latlng: any) {
    var moveLatLng = this._moveMarker.getLatLng();

    var radius = this._map.distance(moveLatLng, latlng);

    this._shape.setRadius(radius);

    if (this._map._editTooltip) {
      this._map._editTooltip.updateContent({
        text:
          L.drawLocal.edit.handlers.edit.tooltip.subtext +
          '<br />' +
          L.drawLocal.edit.handlers.edit.tooltip.text,
        subtext:
          L.drawLocal.draw.handlers.circle.radius +
          ': ' +
          L.GeometryUtil.readableDistance(radius, true, this.options.feet, this.options.nautic),
      });
    }

    this._shape.setRadius(radius);

    this._map.fire(L.Draw.Event.EDITRESIZE, { layer: this._shape });
  },
});

const mapVectorIcon = `<svg width="18" height="13" viewBox="0 0 18 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M0.25 12.75L9.51471 0.25L17.75 12.75H0.25Z" fill="currentColor"/>
</svg>
`;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.less'],
  providers: [TuiDestroyService],
})
export class MapComponent implements OnInit, AfterViewInit {
  @ViewChild('map') mapAnchor!: ElementRef;

  ngAfterViewInit() {
    const mapDiv: HTMLElement = this.mapAnchor?.nativeElement;
    mapDiv.addEventListener('mouseleave', () => {
      this.map.closePopup();
    });
  }

  objectsOnTrack: number[] = [];

  tracks: any = {};

  map: any;

  drawnItems: any;

  drawControl: any;

  isHeight60 = false;

  isHeight50 = false;

  currentGeozoneType: any = null;

  currentGeozoneColor = '#dd4c1e';

  currentDrawGeozoneElement: any = null;

  currentGeozoneWidthLine = 50;

  clickedObject = null;

  clickedObjectAddress = null;

  max_zoom_20_type: string = 'max_zoom_20_type';
  max_zoom_18_type = 'max_zoom_18_type';

  googleStreets = L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
    minZoom: 2,
    maxZoom: 20,
    type: this.max_zoom_20_type,
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
  } as any);

  googleHybrid = L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
    minZoom: 2,
    type: this.max_zoom_20_type,

    maxZoom: 20,
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
  } as any);

  googleSat = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
    minZoom: 2,
    type: this.max_zoom_20_type,
    maxZoom: 20,
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
  } as any);

  googleTerrain = L.tileLayer('https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
    minZoom: 2,
    type: this.max_zoom_20_type,
    maxZoom: 20,
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
  } as any);

  openstreetMap = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    minZoom: 2,
    type: this.max_zoom_18_type,
    maxZoom: 20, //18
    attribution: 'Map data © OpenStreetMap contributors',
  } as any);

  doubleGisMap = tileLayer('https://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}', {
    minZoom: 2,
    type: this.max_zoom_18_type,
    maxZoom: 20, // 18
  } as any);

  yandexMap = L.tileLayer(
    `https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.06.04-0-b210520094930&x={x}&y={y}&z={z}&scale=2&lang=ru`,
    {
      minZoom: 2,
      maxZoom: 20, //18
      type: 'yandex',
    } as any,
  );
  yandexMapTraffic = L.tileLayer(
    `https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.06.04-0-b210520094930&x={x}&y={y}&z={z}&scale=2&lang=ru`,
    {
      minZoom: 2,
      maxZoom: 20, //18
      type: 'yandex',
    } as any,
  );
  yandexMapSatellite = L.tileLayer(
    `https://core-sat.maps.yandex.net/tiles?l=sat&x={x}&y={y}&z={z}&scale=2&lang=ru`,
    {
      minZoom: 2,
      maxZoom: 20, //18
      type: 'yandex',
    } as any,
  );

  yandexMapHybrid = L.tileLayer(
    `https://core-sat.maps.yandex.net/tiles?l=sat&v=3.787.0&x={x}&y={y}&z={z}&scale=2&lang=ru`,
    {
      minZoom: 2,
      maxZoom: 20, //18
      type: 'yandex',
    } as any,
  );

  yandexMapHybridOverlay = L.tileLayer(
    `https://core-renderer-tiles.maps.yandex.net/tiles?l=skl&v=21.06.04-0-b210520094930&x={x}&y={y}&z={z}&scale=2&lang=ru`,
    {
      minZoom: 2,
      maxZoom: 20, //18
      type: 'yandex',
      is_yandex_hybrid: true,
      updateWhenIdle: true,
    } as any,
  );
  yandexMapTrafficOverlay = L.tileLayer(
    `https://core-jams-rdr-cache.maps.yandex.net/1.1/tiles?trf&l=trf&lang=ru&v=21.06.04-0-b210520094930&x={x}&y={y}&z={z}&scale=2`,
    {
      minZoom: 2,
      maxZoom: 20, //18
      type: 'yandex',
      is_yandex_traffic: true,
      updateWhenIdle: true,
    } as any,
  );

  objectsLayer = L.markerClusterGroup({
    iconCreateFunction: (cluster) => {
      return L.divIcon({
        html: `<div class="marker">
                    ${icons['2']}
                    <span class="count">${cluster.getChildCount()}</span>
               </div>`,
        className: 'cluster-marker',
      });
    },
  });

  tracksMarkers = L.layerGroup([]);

  tracksLayer = L.layerGroup([]);

  // stopsLayer = L.layerGroup([]);
  stopsLayer = L.markerClusterGroup({
    iconCreateFunction: (cluster) => {
      return L.divIcon({
        html: `<div class="marker red">
                    ${markerIcons['stop']}
                    <span class="count red">${cluster.getChildCount()}</span>
               </div>`,
        className: 'cluster-marker',
      });
    },
  });

  // startEndsLayer = L.layerGroup([]);
  startEndsLayer = L.markerClusterGroup({
    iconCreateFunction: (cluster) => {
      return L.divIcon({
        html: `<div class="marker">
                    ${markerIcons['start']}
                    <span class="count">${cluster.getChildCount()}</span>
               </div>`,
        className: 'cluster-marker',
      });
    },
  });

  // parkingsLayer = L.layerGroup([]);
  parkingsLayer = L.markerClusterGroup({
    iconCreateFunction: (cluster) => {
      return L.divIcon({
        html: `<div class="marker blue">
                    ${markerIcons['parking']}
                    <span class="count blue">${cluster.getChildCount()}</span>
               </div>`,
        className: 'cluster-marker',
      });
    },
  });

  // refuelsLayer = L.layerGroup([]);
  refuelsLayer = L.markerClusterGroup({
    iconCreateFunction: (cluster) => {
      return L.divIcon({
        html: `<div class="marker blue">
                    ${markerIcons['refuel']}
                    <span class="count blue">${cluster.getChildCount()}</span>
               </div>`,
        className: 'cluster-marker',
      });
    },
  });

  // overSpeedsLayer = L.layerGroup([]);
  overSpeedsLayer = L.markerClusterGroup({
    iconCreateFunction: (cluster) => {
      return L.divIcon({
        html: `<div class="marker red">
                    ${markerIcons['overSpeed']}
                    <span class="count red">${cluster.getChildCount()}</span>
               </div>`,
        className: 'cluster-marker',
      });
    },
  });

  // plumsLayer = L.layerGroup([]);
  plumsLayer = L.markerClusterGroup({
    iconCreateFunction: (cluster) => {
      return L.divIcon({
        html: `<div class="marker red">
                    ${markerIcons['plum']}
                    <span class="count red">${cluster.getChildCount()}</span>
               </div>`,
        className: 'cluster-marker',
      });
    },
  });

  geozonesLayer = L.layerGroup([]);

  buildingsLayer = L.layerGroup([]);
  overlaysLayer = L.layerGroup([]);

  layersControl: any = {};

  mapOptions: MapOptions = {
    center: latLng(gpsData[0].pos?.y, gpsData[0].pos?.x),
    inertia: true,
    bounceAtZoomLimits: false,
    worldCopyJump: true,
    easeLinearity: 0.1,
    zoom: 12,
    minZoom: 2,
    maxZoom: 18,
    keyboard: true,
    // crs: L.CRS.EPSG3857,
    crs: L.CRS.EPSG3395,
    layers: [
      this.overlaysLayer,
      // this.openstreetMap,
      this.yandexMap,
      this.objectsLayer,
      this.tracksLayer,
      this.geozonesLayer,
      this.buildingsLayer,
      this.stopsLayer,
      this.parkingsLayer,
      this.refuelsLayer,
      this.plumsLayer,
      this.overSpeedsLayer,
      this.startEndsLayer,
    ],
    preferCanvas: true,
  };

  data: any = [];

  objectsMarkers: any = {};

  geozonesList: any = [];

  firstLoad = false;

  // translations
  mapLabels: any = {};

  memoizedIcons = {};

  isVisibleObjName = false;

  isShowMapFullScreen = this.portalService.isShowMapFullScreen;

  get zoomValue() {
    return this.map.getZoom() || 1;
  }

  constructor(
    private mapService: MapService,
    private tracksService: TracksService,
    private portalService: PortalService,
    private geoZonesService: GeozonesService,
    private translate: TranslateService,
    private store: Store,
    private objectsService: ObjectsService,
    private destroy$: TuiDestroyService,
    private alertService: TuiAlertService,
    private dateTime: DateTimeService,
    @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
    private viewRef: ViewContainerRef,
  ) {
    this.data = gpsData;

    translate
      .get([
        'STOP',
        'DATE',
        'ADDRESS',
        'DURATION',
        'SEC',
        'PARKING',
        'OBJECT',
        'SPEED',
        'SPEEDING',
        'LATITUDE',
        'LONGITUDE',
        'OPEN_STREET_MAP',
        'GOOGLE_MAPS_STREETS',
        'GOOGLE_MAPS_HYBRID',
        'GOOGLE_MAPS_SATELLITE',
        'GOOGLE_MAPS_TERRAIN',
        '2GIS',
        'VOLUME',
        'REFUEL',
        'PLUM',
        'KM_H',
        'Yandex Map',
        'Yandex Satellite',
        'Yandex Hybrid',
        'Yandex Traffic',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.layersControl = {
          baseLayers: {
            [res.OPEN_STREET_MAP]: this.openstreetMap,
            [res.GOOGLE_MAPS_STREETS]: this.googleStreets,
            [res.GOOGLE_MAPS_HYBRID]: this.googleHybrid,
            [res.GOOGLE_MAPS_SATELLITE]: this.googleSat,
            [res.GOOGLE_MAPS_TERRAIN]: this.googleTerrain,
            [res['2GIS']]: this.doubleGisMap,
            [res['Yandex Map']]: this.yandexMap,
            [res['Yandex Satellite']]: this.yandexMapSatellite,
            [res['Yandex Hybrid']]: this.yandexMapHybridOverlay,
            [res['Yandex Traffic']]: this.yandexMapTrafficOverlay,
          },
        };

        this.mapLabels = res;
      });
  }

  ngOnInit() {
    this.showStartTrackHandler();
    this.showEndTrackHandler();
    this.trackChangeMessageHandler();
    this.geozonesListHandler();

    this.mapService.setDrawMode.pipe(takeUntil(this.destroy$)).subscribe((val: any) => {
      if (val) {
        this.drawStart();
      } else {
        this.drawEnd();
      }
    });

    this.isShowMapFullScreen.pipe(takeUntil(this.destroy$)).subscribe((val: any) => {
      this.resizeObservable();
    });

    this.mapService.isVisibleObjectsName.pipe(takeUntil(this.destroy$)).subscribe((isVisible) => {
      this.isVisibleObjName = isVisible;
    });
    this.mapService.editGeozone.subscribe((geozone: any) => {
      if (geozone) {
        const mapGeozone: any = this.geozonesLayer
          .getLayers()
          .find((layer: any) => layer.options.geozoneId === geozone.id);
        if (mapGeozone) {
          this.map.fitBounds(mapGeozone.getBounds());
          this.drawnItems = new L.FeatureGroup();
          this.map.addLayer(this.drawnItems);
          this.geozonesLayer.removeLayer(mapGeozone);
          this.drawnItems.addLayer(mapGeozone);
          this.drawnItems.eachLayer((layer: any) => {
            layer.editing.enable();
            this.drawItemEnd({ layer: layer });
          });
          this.currentDrawGeozoneElement.disable();
        } else {
          this.currentGeozoneType = 'line';
          const { geozoneColor, widthLine, latLngs } = geozone;
          const geo = L.polyline(latLngs, {
            color: geozoneColor,
            weight: this.metersToPixel(widthLine),
          });
          this.drawnItems.addLayer(geo);
          this.drawnItems.eachLayer((layer: any) => {
            layer.editing.enable();
            this.drawItemEnd({ layer: layer });
          });
          this.currentDrawGeozoneElement.disable();
        }
      }
    });
    this.resizeObservable();
  }

  resizeObservable() {
    const mapDiv: any = document.getElementById('map');
    if (!mapDiv) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      this.map.invalidateSize();
    });

    resizeObserver.observe(mapDiv);
  }

  onMapReady($event: L.Map) {
    if (!$event) {
      return;
    }
    this.map = $event;
    this.map.on('baselayerchange', (e: any) => {
      const type = e.layer?.options?.type;
      const center = this.map.getCenter();
      let zoom = this.map.getZoom();

      if (type === this.max_zoom_20_type) this.map.setMaxZoom(20);

      if ((type === this.max_zoom_18_type || type === 'yandex') && zoom > 18) {
        this.map.setMaxZoom(18);
        zoom = 18;
      }

      if (type === 'yandex') {
        this.map.options.crs = L.CRS.EPSG3395;
      } else {
        this.map.options.crs = L.CRS.EPSG3857;
      }
      if (e.layer.options.is_yandex_hybrid) {
        this.overlaysLayer.addLayer(this.yandexMapHybrid);
      } else if (e.layer.options.is_yandex_traffic) {
        this.overlaysLayer.addLayer(this.yandexMapTraffic);
      } else {
        this.overlaysLayer.removeLayer(this.yandexMapTraffic);
        this.overlaysLayer.removeLayer(this.yandexMapHybrid);
      }

      this.map.setView(center, zoom);
      this.map._resetView(center, zoom);
      setTimeout(() => {
        // this.map.setView(center, zoom);
        // this.map._resetView(center, zoom);
        const objectsLayer: any[] = [];
        const tracksMarkers: any[] = [];
        const tracksLayer: any[] = [];
        const stopsLayer: any[] = [];
        const startEndsLayer: any[] = [];
        const parkingsLayer: any[] = [];
        const refuelsLayer: any[] = [];
        const overSpeedsLayer: any[] = [];
        const plumsLayer: any[] = [];
        const geozonesLayer: any[] = [];
        const buildingsLayer: any[] = [];
        /***************************/
        this.objectsLayer.eachLayer((layer: any) => {
          objectsLayer.push(layer);
        });
        this.objectsLayer.clearLayers();
        this.objectsLayer.addLayers(objectsLayer);
        /***************************/

        this.tracksMarkers.eachLayer((layer: any) => {
          tracksMarkers.push(layer);
        });
        this.tracksMarkers.clearLayers();
        for (let t of tracksMarkers) {
          this.tracksMarkers.addLayer(t);
        }
        /***************************/
        this.tracksLayer.eachLayer((layer: any) => {
          tracksLayer.push(layer);
        });
        this.tracksLayer.clearLayers();
        for (let t of tracksLayer) {
          this.tracksLayer.addLayer(t);
        }
        /***************************/
        this.stopsLayer.eachLayer((layer: any) => {
          stopsLayer.push(layer);
        });
        this.stopsLayer.clearLayers();
        this.stopsLayer.addLayers(stopsLayer);
        /***************************/
        this.startEndsLayer.eachLayer((layer: any) => {
          startEndsLayer.push(layer);
        });
        this.startEndsLayer.clearLayers();
        this.startEndsLayer.addLayers(startEndsLayer);
        /***************************/
        this.parkingsLayer.eachLayer((layer: any) => {
          parkingsLayer.push(layer);
        });
        this.parkingsLayer.clearLayers();
        this.parkingsLayer.addLayers(parkingsLayer);
        /***************************/
        this.refuelsLayer.eachLayer((layer: any) => {
          refuelsLayer.push(layer);
        });
        this.refuelsLayer.clearLayers();
        this.refuelsLayer.addLayers(refuelsLayer);
        /***************************/
        this.overSpeedsLayer.eachLayer((layer: any) => {
          overSpeedsLayer.push(layer);
        });
        this.overSpeedsLayer.clearLayers();
        this.overSpeedsLayer.addLayers(overSpeedsLayer);
        /***************************/
        this.plumsLayer.eachLayer((layer: any) => {
          plumsLayer.push(layer);
        });
        this.plumsLayer.clearLayers();
        this.plumsLayer.addLayers(plumsLayer);
        /***************************/
        this.geozonesLayer.eachLayer((layer: any) => {
          geozonesLayer.push(layer);
        });
        this.geozonesLayer.clearLayers();
        for (let t of geozonesLayer) {
          this.geozonesLayer.addLayer(t);
        }
        /***************************/
        this.buildingsLayer.eachLayer((layer: any) => {
          buildingsLayer.push(layer);
        });
        this.buildingsLayer.clearLayers();
        for (let t of buildingsLayer) {
          this.buildingsLayer.addLayer(t);
        }
      }, 10);
    });

    this.mapService.deleteObjectMarker.pipe(takeUntil(this.destroy$)).subscribe((objectId: any) => {
      if (this.objectsMarkers[objectId]) {
        this.objectsLayer.removeLayer(this.objectsMarkers[objectId]);
        delete this.objectsMarkers[objectId];
      }
    });

    this.mapService.changeObjectMarkerPos
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.firstLoad),
        switchMap((data: any) => {
          const objectId = data.oid;
          const lastMessage = data.m;
          const includesObjectOnMap = String(objectId) in this.objectsMarkers;

          if (!Object.keys(this.tracks).length && !includesObjectOnMap) {
            return this.store.select(selectObjectById, { objectId }).pipe(
              take(1),
              tap((o: any) => {
                this.objectsMarkers[o.id] = this.getObjectMarker(o);
                this.objectsLayer.addLayer(this.objectsMarkers[o.id]);
              }),
            );
          } else if (
            includesObjectOnMap &&
            !Object.values(this.tracks).some((t: any) => String(t.object.id) == String(objectId))
          ) {
            return this.store.select(selectObjectById, { objectId }).pipe(
              take(1),
              tap((o: any) => {
                if (o) {
                  this.updateObjectMarker(o, lastMessage);
                }
              }),
            );
          }
          return EMPTY;
        }),
      )
      .subscribe();

    // this.mapService.changeObjectMarkerPos.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
    //   if (this.firstLoad) {
    //     const objectId = data.oid;
    //     const lastMessage = data.m;
    //     if (
    //       Object.keys(this.objectsMarkers).includes(String(objectId)) &&
    //       !Object.values(this.tracks).some((t: any) => String(t.object.id) == String(objectId))
    //     ) {
    //       this.store
    //         .select(selectObjectById, { objectId })
    //         .pipe(take(1))
    //         .pipe(takeUntil(this.destroy$))
    //         .subscribe((o: any) => {
    //           if (o) {
    //             this.updateObjectMarker(o, lastMessage);
    //           }
    //         });
    //     }
    //   }
    // });
    this.mapService.selectedObjects.pipe(takeUntil(this.destroy$)).subscribe((objects: any) => {
      this.objectsLayer.clearLayers();
      this.objectsMarkers = [];
      let bounds = L.latLngBounds([]);
      for (let o of objects) {
        if (this.checkIsValidLatLng(o?.lastMessage)) {
          this.objectsMarkers[o.id] = this.getObjectMarker(o);
          this.objectsLayer.addLayer(this.objectsMarkers[o.id]);
          bounds.extend(L.latLng(o.lastMessage.pos.y, o.lastMessage.pos.x));
        }

        if (objects.length > 0) {
          if (bounds.isValid()) {
            this.map.fitBounds(bounds);
          }
        }
      }
    });

    this.mapService.drawMapObjects.pipe(takeUntil(this.destroy$)).subscribe((objects: any) => {
      if (!this.firstLoad) {
        let bounds = L.latLngBounds([]);
        for (let o of objects) {
          if (Object.keys(this.objectsMarkers).includes(String(o.id))) {
            this.updateObjectMarker(o, o.lastMessage);
          } else {
            if (this.checkIsValidLatLng(o?.lastMessage)) {
              this.objectsMarkers[o.id] = this.getObjectMarker(o);
              this.objectsLayer.addLayer(this.objectsMarkers[o.id]);
              bounds.extend(L.latLng(o.lastMessage.pos.y, o.lastMessage.pos.x));
            }
          }
        }

        if (objects.length > 0) {
          if (bounds.isValid()) {
            this.map.fitBounds(bounds);
          }
          this.firstLoad = true;
        }
      }
    });
    this.mapService.panTo.pipe(takeUntil(this.destroy$)).subscribe((latLng: any) => {
      console.log(latLng);
      if (!latLng.lat || !latLng.lng) {
        return;
      }
      this.map.panTo(new L.LatLng(latLng.lat, latLng.lng));
      const marker = new L.CircleMarker([latLng.lat, latLng.lng], {}).addTo(this.map);
      setTimeout(() => {
        marker.remove();
      }, 2000);
    });
    this.tracksService.addTrack.pipe(takeUntil(this.destroy$)).subscribe((track: any) => {
      this.createTrack(track);
    });
    this.tracksService.deleteTrack.pipe(takeUntil(this.destroy$)).subscribe((track: any) => {
      this.deleteTrack(track);
    });
    this.tracksService.closeTrack.pipe(takeUntil(this.destroy$)).subscribe((track: any) => {
      this.closeTrack(track);
    });
    this.map.on('zoomend', () => {
      if (this.drawnItems && Object.keys(this.drawnItems).length > 0) {
        this.drawnItems.eachLayer((layer: any) => {
          if (layer.options?.type === 'line') {
            layer.setStyle({
              weight: this.metersToPixel(this.currentGeozoneWidthLine),
            });
          }
        });
      }
      if (this.geozonesLayer && Object.keys(this.geozonesLayer).length > 0) {
        this.geozonesLayer.eachLayer((layer: any) => {
          if (layer.options?.type === 'line') {
            layer.setStyle({
              weight: this.metersToPixel(layer.options.widthLine),
            });
          }
        });
      }
      if (this.map.getZoom() < 16) {
        this.map.removeLayer(this.tracksMarkers);
      } else {
        this.map.addLayer(this.tracksMarkers);
      }
      this.handleGeozoneZoom();
    });
    this.handleDeleteGeozone();
    this.handleGoToGeozoneBounds();
    this.handleGeozoneZoom();
    this.handleDrawStopsForTrack();
    this.handleDrawParkingsForTrack();
    this.handleDrawStartEndsForTrack();
    this.handleDrawRefuelsForTrack();
    this.handleDrawPlumsForTrack();
    this.handleDrawOverSpeedsForTrack();
    this.mapService.zoomOnMap.pipe(takeUntil(this.destroy$)).subscribe((val: any) => {
      if (this.checkIsValidLatLng(val?.lastMessage)) {
        this.map.setView(new L.LatLng(val.lastMessage.pos.y, val.lastMessage.pos.x), 16);
      } else {
        this.alertService
          .open(
            this.translate.instant('There is no information about the location of the object'),
            {
              status: 'error',
              autoClose: 5000,
            },
          )
          .subscribe();
      }
    });
    this.mapService.zoomBuildingOnMap.pipe(takeUntil(this.destroy$)).subscribe((val: any) => {
      if (val?.lat && val?.lng) {
        this.map.setView(new L.LatLng(val.lat, val.lng), 16);
      }
    });
    this.handleBuildings();
  }

  handleBuildings() {
    this.store
      .select(selectBuildings)
      .pipe(takeUntil(this.destroy$))
      .subscribe((buildings: any) => {
        this.buildingsLayer.clearLayers();
        for (let b of buildings) {
          const marker = new L.Marker([b.lat, b.lng], {
            icon: new L.DivIcon({
              html: `<div class="building-icon"><svg width="18" height="19" viewBox="0 0 18 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.3402 8.6232C17.3398 8.62279 17.3394 8.62238 17.3391 8.62196L10.4037 1.27966C10.1081 0.966553 9.71505 0.794067 9.29698 0.794067C8.87892 0.794067 8.48589 0.966415 8.19014 1.27953L1.25841 8.61812C1.25608 8.62059 1.25374 8.6232 1.25141 8.62567C0.644353 9.27208 0.645391 10.3209 1.25439 10.9656C1.53263 11.2603 1.9001 11.431 2.293 11.4489C2.30896 11.4505 2.32504 11.4514 2.34126 11.4514H2.61768L2.61768 16.8548C2.61768 17.9241 3.43941 18.7941 4.44961 18.7941H7.16295C7.43794 18.7941 7.66105 18.558 7.66105 18.2667L7.66105 14.0304C7.66105 13.5425 8.03592 13.1456 8.49679 13.1456H10.0972C10.5581 13.1456 10.9329 13.5425 10.9329 14.0304L10.9329 18.2667C10.9329 18.558 11.1559 18.7941 11.431 18.7941H14.1444C15.1546 18.7941 15.9763 17.9241 15.9763 16.8548L15.9763 11.4514H16.2326C16.6505 11.4514 17.0436 11.279 17.3394 10.9659C17.9491 10.32 17.9494 9.26947 17.3402 8.6232Z" fill="currentColor"/>
              </svg>
              </div>`,
              iconSize: new L.Point(32, 32),
            }),
          });
          marker.on('click', (e: any) => {
            L.popup()
              .setLatLng(e.latlng)
              .setContent(
                `<div class="object-map__popup">
          <h4 style="margin-bottom:8px">${b.name}</h4>
          ${b.address}
          </div>`,
              )
              .openOn(this.map);
          });
          this.buildingsLayer.addLayer(marker);
        }
      });
  }

  handleDrawStartEndsForTrack() {
    this.mapService.drawStartEndsForTrack.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      const { trips, trackId } = data;
      const startEndsLayer: any = new L.FeatureGroup();
      for (let trip of trips) {
        startEndsLayer.addLayer(
          new L.Marker([trip[0][0], trip[0][1]], {
            icon: L.icon({
              iconUrl: '/assets/map/start.svg',
              iconSize: [24, 24],
              iconAnchor: [22, 38],
              popupAnchor: [-3, -76],
              tooltipAnchor: [16, -28],
            }),
          }).on('click', (e: any) => {
            this.mapService
              .getAddressByCoordinates(trip[0][0], trip[0][1])
              .pipe(takeUntil(this.destroy$))
              .subscribe((result: any) => {
                L.popup()
                  .setLatLng(e.latlng)
                  .setContent(
                    `<div class="object-map__popup">
            <h4 style="margin-bottom:8px">Стартовое положение</h4>
            ${this.mapLabels.DATE}: ${formatDefaultDateFromISO(trip[0][3])}<br>
            ${this.mapLabels.ADDRESS}: ${result || '-'}<br>
            </div>`,
                  )
                  .openOn(this.map);
              });
          }),
        );
        startEndsLayer.addLayer(
          new L.Marker([trip[1][0], trip[1][1]], {
            icon: L.icon({
              iconUrl: '/assets/map/end.svg',
              iconSize: [24, 24],
              iconAnchor: [22, 38],
              popupAnchor: [-3, -76],
              tooltipAnchor: [16, -28],
            }),
          }).on('click', (e: any) => {
            this.mapService
              .getAddressByCoordinates(trip[1][0], trip[1][1])
              .pipe(takeUntil(this.destroy$))
              .subscribe((result: any) => {
                L.popup()
                  .setLatLng(e.latlng)
                  .setContent(
                    `<div class="object-map__popup">
            <h4 style="margin-bottom:8px">Конечное положение</h4>
            ${this.mapLabels.DATE}: ${formatDefaultDateFromISO(trip[1][3])}<br>
            ${this.mapLabels.ADDRESS}: ${result || '-'}<br>
            </div>`,
                  )
                  .openOn(this.map);
              });
          }),
        );
      }
      startEndsLayer.options.trackId = trackId;
      this.tracks[trackId].startEndsLayer = startEndsLayer;
      this.startEndsLayer.addLayer(startEndsLayer);
    });
  }

  handleDrawStopsForTrack() {
    this.mapService.drawStopsForTrack.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      const { stops, trackId } = data;
      this.tracks[trackId].stops = stops;
      const stopsLayer: any = new L.FeatureGroup();
      for (const stop of stops) {
        stopsLayer.addLayer(
          new L.Marker([stop.lat, stop.lng], {
            icon: L.icon({
              iconUrl: '/assets/map/stop.svg',
              iconSize: [24, 24],
              iconAnchor: [22, 38],
              popupAnchor: [-3, -76],
              tooltipAnchor: [16, -28],
            }),
          }).on('click', (e: any) => {
            this.mapService
              .getAddressByCoordinates(stop.lat, stop.lng)
              .pipe(takeUntil(this.destroy$))
              .subscribe((result: any) => {
                L.popup()
                  .setLatLng(e.latlng)
                  .setContent(
                    `<div class="object-map__popup">
            <h4 style="margin-bottom:8px">${this.mapLabels.STOP}</h4>
            ${this.mapLabels.DATE}: ${formatDefaultDateFromISO(stop.startDate)}<br>
            ${this.mapLabels.ADDRESS}: ${result || '-'}<br>
            ${this.mapLabels.DURATION}: ${this.dateTime.formatTimeFromSeconds(stop.duration)}
            </div>`,
                  )
                  .openOn(this.map);
              });
          }),
        );
      }

      stopsLayer.options.trackId = trackId;
      this.tracks[trackId].stopsLayer = stopsLayer;
      this.stopsLayer.addLayer(stopsLayer);
    });
  }

  handleDrawParkingsForTrack() {
    this.mapService.drawParkingsForTrack.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      const { parkings, trackId } = data;
      this.tracks[trackId].parkings = parkings;
      const parkingsLayer: any = new L.FeatureGroup();
      for (const stop of parkings) {
        parkingsLayer.addLayer(
          new L.Marker([stop.lat, stop.lng], {
            icon: L.icon({
              iconUrl: '/assets/map/parking.svg',
              iconSize: [24, 24],
              iconAnchor: [22, 38],
              popupAnchor: [-3, -76],
              tooltipAnchor: [16, -28],
            }),
          }).on('click', (e: any) => {
            this.mapService
              .getAddressByCoordinates(stop.lat, stop.lng)
              .pipe(takeUntil(this.destroy$))
              .subscribe((result: any) => {
                L.popup()
                  .setLatLng(e.latlng)
                  .setContent(
                    `<div class="object-map__popup">

            <h4 style="margin-bottom:8px">${this.mapLabels.PARKING}</h4>
            ${this.mapLabels.DATE}: ${formatDefaultDateFromISO(stop.startDate)}<br>
            ${this.mapLabels.ADDRESS}: ${result || '-'}<br>
            ${this.mapLabels.DURATION}: ${this.dateTime.formatTimeFromSeconds(stop.duration)}
            </div>`,
                  )
                  .openOn(this.map);
              });
          }),
        );
      }

      parkingsLayer.options.trackId = trackId;
      this.tracks[trackId].parkingsLayer = parkingsLayer;
      this.parkingsLayer.addLayer(parkingsLayer);
    });
  }

  handleDrawRefuelsForTrack() {
    this.mapService.drawRefuelsForTrack.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      const { refuels, trackId } = data;
      this.tracks[trackId].refuels = refuels;
      const refuelsLayer: any = new L.FeatureGroup();
      for (const refuel of refuels) {
        refuelsLayer.addLayer(
          new L.Marker([refuel.lat, refuel.lng], {
            icon: L.icon({
              iconUrl: '/assets/map/refuel.svg',
              iconSize: [24, 24],
              iconAnchor: [22, 38],
              popupAnchor: [-3, -76],
              tooltipAnchor: [16, -28],
            }),
            zIndexOffset: 100,
          }).on('click', (e: any) => {
            L.popup()
              .setLatLng(e.latlng)
              .setContent(
                `<div class="object-map__popup">
                <h4 style="margin-bottom:8px">${this.mapLabels.REFUEL}</h4>
                ${this.mapLabels.DATE}: ${formatDefaultDateFromISO(refuel.startDate)}<br>
                ${this.mapLabels.ADDRESS}: ${refuel?.address || '-'}<br>
                ${this.mapLabels.VOLUME}: ${refuel.refuelVolume || '-'}
                </div>`,
              )
              .openOn(this.map);
          }),
        );
      }

      refuelsLayer.options.trackId = trackId;
      this.tracks[trackId].refuelsLayer = refuelsLayer;
      this.refuelsLayer.addLayer(refuelsLayer);
    });
  }

  handleDrawPlumsForTrack() {
    this.mapService.drawPlumsForTrack.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      const { plums, trackId } = data;
      this.tracks[trackId].plums = plums;
      const plumsLayer: any = new L.FeatureGroup();
      const preparedPlums: any = {};
      for (const plum of plums) {
        const key = `${plum.lat}_${plum.lng}`;
        if (preparedPlums[key]) {
          preparedPlums[key].push(plum);
        } else {
          preparedPlums[key] = [plum];
        }
      }
      for (const plum of Object.entries(preparedPlums)) {
        const key = plum[0];
        const plums: any = plum[1];
        const [lat, lng]: any = key.split('_');
        let content = '';
        plums.forEach((plum: any) => {
          content += `<div class="object-map__popup">
                <h4 style="margin-bottom:8px">${this.mapLabels.PLUM}</h4>
                ${this.mapLabels.DATE}: ${formatDefaultDateFromISO(plum.startDate)}<br>
                ${this.mapLabels.ADDRESS}: ${plum?.address || '-'}<br>
                ${this.mapLabels.VOLUME}: ${plum.plumVolume || '-'}
                </div>`;
        });
        plumsLayer.addLayer(
          new L.Marker([lat, lng], {
            icon: L.icon({
              iconUrl: '/assets/map/plum.svg',
              iconSize: [24, 24],
              iconAnchor: [22, 38],
              popupAnchor: [-3, -76],
              tooltipAnchor: [16, -28],
            }),
            zIndexOffset: 100,
          }).on('click', (e: any) => {
            L.popup().setLatLng(e.latlng).setContent(content).openOn(this.map);
          }),
        );
      }

      plumsLayer.options.trackId = trackId;
      this.tracks[trackId].plumsLayer = plumsLayer;
      this.plumsLayer.addLayer(plumsLayer);
    });
  }

  handleDrawOverSpeedsForTrack() {
    this.mapService.drawOverSpeedForTrack.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      const { overSpeeds, trackId } = data;
      this.tracks[trackId].overSpeeds = overSpeeds;
      const overSpeedsLayer: any = new L.FeatureGroup();
      for (const speed of overSpeeds) {
        overSpeedsLayer.addLayer(
          new L.Marker([speed.lat, speed.lng], {
            icon: L.icon({
              iconUrl: '/assets/map/overSpeeds.svg',
              iconSize: [24, 24],
              iconAnchor: [22, 38],
              popupAnchor: [-3, -76],
              tooltipAnchor: [16, -28],
            }),
            zIndexOffset: 100,
          }).on('click', (e: any) => {
            this.mapService
              .getAddressByCoordinates(speed.lat, speed.lng)
              .pipe(takeUntil(this.destroy$))
              .subscribe((result: any) => {
                L.popup()
                  .setLatLng(e.latlng)
                  .setContent(
                    `<div class="object-map__popup">

            <h4 style="margin-bottom:8px">${this.mapLabels.SPEEDING}</h4>
            <b>${this.mapLabels.SPEED}:</b> ${speed.maxSpeed} ${this.mapLabels.KM_H}<br>
            <b>${this.mapLabels.DATE}:</b> ${formatDefaultDateFromISO(speed.startDate)}<br>
            <b>${this.mapLabels.ADDRESS}:</b> ${result || '-'}<br>
            <b>${this.mapLabels.DURATION}:</b> ${this.dateTime.formatTimeFromSeconds(
              speed.duration,
            )}
            </div>`,
                  )
                  .openOn(this.map);
              });
          }),
        );
      }

      overSpeedsLayer.options.trackId = trackId;
      this.tracks[trackId].overSpeeds = overSpeedsLayer;
      this.overSpeedsLayer.addLayer(overSpeedsLayer);
    });
  }

  handleGeozoneZoom() {
    let geozones = [];
    if (this.drawnItems) {
      geozones = this.geozonesList.filter((geozone: any) => {
        return !this.drawnItems
          .getLayers()
          .find((layer: any) => layer.options.geozoneId === geozone.id);
      });
    } else {
      geozones = this.geozonesList;
    }
    for (const geozone of geozones) {
      const geozoneOnMap = this.geozonesLayer
        .getLayers()
        .find((layer: any) => layer.options.geozoneId === geozone.id);
      if (
        geozone &&
        geozone.zoomVisibleFrom <= this.map.getZoom() &&
        geozone.zoomVisibleTo >= this.map.getZoom()
      ) {
        if (!geozoneOnMap) {
          this.handleCreateGeozone(geozone);
        }
      } else {
        if (geozoneOnMap) {
          this.geozonesLayer.removeLayer(geozoneOnMap);
        }
      }
    }
  }

  updateObjectMarker(object: any, message: any, centerMap = false) {
    if (
      this.checkIsValidLatLng(message) &&
      Object.keys(this.objectsMarkers).includes(String(object.id))
    ) {
      this.objectsMarkers[object.id].setLatLng([message.pos.y, message.pos.x], this.map.getZoom());
      this.objectsMarkers[object.id].setIcon(this.getObjectIcon(object, message));
      this.objectsMarkers[object.id].off('click');
      this.objectsMarkers[object.id].on('click', (e: any) => {
        this.getObjectPopupContent(object, e, message);
      });
    }
    // if (Object.keys(this.objectsMarkers).includes(String(object.id))) {
    //   this.objectsMarkers[object.id].off('click');
    //   this.objectsMarkers[object.id].on('click', (e: any) => {
    //     this.getObjectPopupContent(object, e, message);
    //   });
    // }
    if (centerMap && message?.pos) {
      this.map.panTo(new L.LatLng(message.pos.y, message.pos.x));
    }
  }

  getObjectMarker(object: any) {
    return new L.Marker([object.lastMessage.pos.y, object.lastMessage.pos.x], {
      icon: this.getObjectIcon(object, object.lastMessage),
    }).on('click', (e: any) => {
      this.getObjectPopupContent(object, e, object.lastMessage);
    });
    // .on("mouseout", (e: any) => {
    // console.log(e)
    // this.map.closePopup();
    // });
  }

  getIconSvg(iconId: number) {
    return icons[iconId] || icons[1];
  }

  getObjectIcon(object: any, message: any) {
    return new L.DivIcon({
      html: `
        <div class="map-object-container">
            <div
                class="object-icon"
                style="color: ${object.objectIconColor || '#828282'};"
             >
                ${this.getIconSvg(object.iconId)}
                <div class="object-market-arrow rotate-arrow-${message?.pos?.c || 0}">
                    ${mapVectorIcon}
                </div>
             </div>
            <span class="obj-name-visible" style="color: ${
              object.objectCaptionColor || '#333333'
            };">${object.name}</span>
        </div>
`,
      iconSize: new L.Point(32, 32),
    });
  }

  getSpeedColor(speed: number, trackColor: any[]) {
    return (
      trackColor.find((tc: any) => speed >= tc.minValue && speed <= tc.maxValue)?.color ||
      trackColor[trackColor.length - 1]?.color ||
      '#000'
    );
  }

  createTrack(trackData: {
    id: number;
    object: any;
    data: any[];
    tracks: { tracks: any } | undefined;
    colorType: string;
    color: string;
  }) {
    this.objectsOnTrack.push(trackData.object.id);
    switch (trackData.colorType) {
      case 'speed':
        this.createTrackBySpeeds(trackData);
        break;
      case 'trip':
        this.createTrackByTrips(trackData);
        break;
      case 'single':
        this.createTrackBySolidColor(trackData);
        break;
      default:
        this.createTrackBySpeeds(trackData);
    }
    this.resizeObservable();
  }

  createTrackByTrips(trackData: any) {
    const trackLayers = new L.FeatureGroup();
    for (const trip of trackData.data) {
      const latLngs: any[] = trip.messagesCoordinates.map((m: any) => [m[0], m[1]]);
      if (latLngs.length) {
        const track = L.polyline(latLngs, {
          color: trip.color || '#3949AB',
          weight: trackData.weight,
        });
        trackLayers.addLayer(track);
      }
    }
    this.tracksLayer.addLayer(trackLayers);
    this.tracks[trackData.id] = {
      track: trackLayers,
      markers: L.layerGroup([]),
      object: trackData.object,
    };

    const bounds = trackLayers.getBounds();
    if (bounds.isValid()) {
      this.map.fitBounds(bounds);
    }

    setTimeout(() => this.renderTrackMarkers(trackData), 100);
  }

  createTrackBySolidColor(trackData: any) {
    let track;
    if (trackData.detectorTrips) {
      track = new L.FeatureGroup();
      for (const trip of trackData.data) {
        const latLngs: any[] = trip.messagesCoordinates.map((m: any) => [m[0], m[1]]);
        if (latLngs.length) {
          const trackTrip = L.polyline(latLngs, {
            color: trackData.solidColor || '#3949AB',
            weight: trackData.weight,
          });
          track.addLayer(trackTrip);
        }
      }
    } else {
      const latLngs: any[] = [];
      trackData.data.forEach((m: any) => {
        latLngs.push([m[0], m[1]]);
      });
      if (latLngs.length) {
        track = L.polyline(latLngs, {
          color: trackData.solidColor || '#3949AB',
          weight: trackData.weight,
        });
      }
    }
    if (track) {
      this.tracksLayer.addLayer(track);
      this.tracks[trackData.id] = {
        track: track,
        markers: L.layerGroup([]),
        object: trackData.object,
      };
      console.error(track);
      // this.map.fitBounds(track.getBounds());

      const bounds = track.getBounds();
      if (bounds.isValid()) {
        this.map.fitBounds(bounds);
      }

      setTimeout(() => this.renderTrackMarkers(trackData), 100);
    }
  }

  createTrackBySpeeds(trackData: any) {
    const speeds: any[] = [];
    let track: any;
    if (trackData.detectorTrips) {
      track = new L.FeatureGroup();
      for (const trip of trackData.data) {
        const latLngs: any[] = trip.messagesCoordinates.map((m: any) => [m[0], m[1]]);
        const speeds = trip.messagesCoordinates.map((m: any) =>
          this.getSpeedColor(m[2], trackData.speedColors),
        );
        if (latLngs.length) {
          //@ts-ignore
          const trackTrip = L.polycolor(latLngs, {
            colors: speeds,
            useGradient: true,
            weight: trackData.weight,
          });
          track.addLayer(trackTrip);
        }
      }
    } else {
      const latLngs: any[] = [];
      trackData.data.forEach((m: any) => {
        latLngs.push([m[0], m[1]]);
        speeds.push(this.getSpeedColor(m[2], trackData.speedColors));
      });
      if (latLngs.length) {
        //@ts-ignore
        track = L.polycolor(latLngs, {
          colors: speeds,
          useGradient: true,
          weight: trackData.weight,
        });
      }
    }
    if (track) {
      this.tracksLayer.addLayer(track);
      this.tracks[trackData.id] = {
        track: track,
        markers: L.layerGroup([]),
        object: trackData.object,
      };
      // this.map.fitBounds(track.getBounds());

      const bounds = track.getBounds();

      if (bounds.isValid()) {
        this.map.fitBounds(bounds);
      }
      setTimeout(() => this.renderTrackMarkers(trackData), 100);
    }
  }

  renderTrackMarkers(trackData: any) {
    const worker = new Worker('/assets/progress-track-markers.js', {
      type: 'module',
    });
    // const trackMarkersCluster = L.Ma({
    //   chunkedLoading: true,
    // });
    worker.onmessage = (event) => {
      const data = event.data;
      for (const chunk of data) {
        // this.tracks[trackData.id].markers.addLayers(
        chunk.map((m: any) => {
          const marker = new L.CircleMarker([m[0], m[1]], {
            radius: 3,
            fill: true,
            fillColor: this.getSpeedColor(m[2], trackData.speedColors),
            fillOpacity: 1,
            color: this.getSpeedColor(m[2], trackData.speedColors),
          }).on('click', (e: any) => {
            this.mapService
              .getAddressByCoordinates(m[0], m[1])
              .pipe(takeUntil(this.destroy$))
              .subscribe((result: any) => {
                L.popup()
                  .setLatLng(e.latlng)
                  .setContent(
                    `<div class="object-map__popup">

            ${this.mapLabels.OBJECT}: ${trackData.object.name}<br>
            ${this.mapLabels.DATE}: ${formatDefaultDateFromISO(m[3])}<br>
            ${this.mapLabels.LATITUDE}: ${m[0]}<br>
            ${this.mapLabels.LONGITUDE}: ${m[1]}<br>
            ${this.mapLabels.SPEED}: ${m[2]}<br>
            ${this.mapLabels.ADDRESS}: ${result || '-'}
            </div>`,
                  )
                  .openOn(this.map);
              });
          });
          this.tracks[trackData.id].markers.addLayer(marker);
        });
      }
    };
    worker.postMessage(trackData);
    this.tracksMarkers.addLayer(this.tracks[trackData.id].markers);
  }

  deleteTrack(track: any) {
    this.objectsOnTrack.splice(this.objectsOnTrack.indexOf(track.id), 1);
    if (Object.keys(this.tracks).includes(track.id)) {
      this.tracksMarkers.removeLayer(this.tracks[track.id].markers);
      this.tracksLayer.removeLayer(this.tracks[track.id].track);
      if (this.tracks[track.id].stopsLayer) {
        this.stopsLayer.removeLayer(this.tracks[track.id].stopsLayer);
      }
      if (this.tracks[track.id].parkingsLayer) {
        this.parkingsLayer.removeLayer(this.tracks[track.id].parkingsLayer);
      }
      if (this.tracks[track.id].refuelsLayer) {
        this.refuelsLayer.removeLayer(this.tracks[track.id].refuelsLayer);
      }
      if (this.tracks[track.id].plumsLayer) {
        this.plumsLayer.removeLayer(this.tracks[track.id].plumsLayer);
      }
      if (this.tracks[track.id].startEndsLayer) {
        this.startEndsLayer.removeLayer(this.tracks[track.id].startEndsLayer);
      }

      if (this.tracks[track.id].overSpeeds) {
        this.overSpeedsLayer.removeLayer(this.tracks[track.id].overSpeeds);
      }

      delete this.tracks[track.id];
    }
    const object = track.object;
    this.updateObjectMarker(object, object.lastMessage);
  }

  closeTrack(track: any) {
    this.objectsOnTrack.splice(this.objectsOnTrack.indexOf(track.id), 1);
    const object = track.object;
    this.updateObjectMarker(object, object.lastMessage);
  }

  getObjectPopupContent(object: any, e: any, message: any) {
    var popLocation = e.latlng;
    let address = '';
    this.mapService
      .getAddressByCoordinates(message.pos.y, message.pos.x)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        const component = this.viewRef.createComponent(ObjectTooltipComponent);
        component.instance.object = object;
        component.instance.address = result || '';
        L.popup({ keepInView: true, className: 'object-map__popup' })
          .setLatLng(popLocation)
          .setContent(component.location.nativeElement)
          .openOn(this.map);
        component.changeDetectorRef.detectChanges();
      });
  }

  showStartTrackHandler() {
    this.mapService.showStartTrack
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ trackId, startMessage }: any) => {
        const startMarker = L.marker([startMessage[0], startMessage[1]], {
          icon: new L.Icon({
            iconUrl: '/assets/map/start-flag.svg',
            iconSize: new L.Point(32, 32),
          }),
        }).addTo(this.map);
        this.map.panTo(new L.LatLng(startMessage[0], startMessage[1]));

        setTimeout(() => {
          startMarker.remove();
        }, 2000);
      });
  }

  showEndTrackHandler() {
    this.mapService.showFinishTrack
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ trackId, endMessage }: any) => {
        const startMarker = L.marker([endMessage[0], endMessage[1]], {
          icon: new L.Icon({
            iconUrl: '/assets/map/end-flag.svg',
            iconSize: new L.Point(32, 32),
          }),
        }).addTo(this.map);
        this.map.panTo(new L.LatLng(endMessage[0], endMessage[1]));
        setTimeout(() => {
          startMarker.remove();
        }, 2000);
      });
  }

  trackChangeMessageHandler() {
    this.mapService.updateTrackObjectPoint
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ message, object }: any) => {
        this.updateObjectMarker(object, message, true);
      });
  }

  drawStart() {
    this.currentGeozoneType = 'polygon';
    this.currentGeozoneColor = '#dd4c1e';
    this.currentDrawGeozoneElement = null;
    this.currentGeozoneWidthLine = 50;
    this.drawnItems = new L.FeatureGroup();
    this.map.addLayer(this.drawnItems);
    this.drawControl = new L.Control.Draw({
      draw: {
        circlemarker: false,
        marker: false,
        rectangle: false,
      },
      edit: {
        featureGroup: this.drawnItems,
        remove: false,
      },
    });
    let line = new L.Draw.Polyline(this.map, {
      shapeOptions: { opacity: 0.6 },
    });
    let polygon = new L.Draw.Polygon(this.map);
    let circle = new L.Draw.Circle(this.map, this.drawControl.options.circle);
    this.currentDrawGeozoneElement = polygon;
    const changeColor = (color: string) => {
      this.currentGeozoneColor = color;
      this.currentDrawGeozoneElement.setOptions({
        shapeOptions: {
          color: color,
        },
      });
      if (this.drawnItems) {
        this.drawnItems.eachLayer((layer: any) => {
          layer.setStyle({ color: color });
        });
      }
    };
    this.mapService.changeDrawOptions
      .pipe(takeUntil(this.geoZonesService.createGeozoneDestroyer))
      .pipe(takeUntil(this.destroy$))
      .subscribe((options: any) => {
        if (options?.widthLine && this.currentDrawGeozoneElement) {
          this.currentGeozoneWidthLine = options.widthLine;
          this.currentDrawGeozoneElement.setOptions({
            shapeOptions: {
              weight: this.metersToPixel(options.widthLine),
              color: this.currentGeozoneColor,
              opacity: 0.6,
            },
          });
          if (
            this.currentGeozoneType === 'line' &&
            this.drawnItems &&
            Object.keys(this.drawnItems._layers).length > 0
          ) {
            this.drawnItems.eachLayer((layer: any) => {
              layer.setStyle({
                weight: this.metersToPixel(options.widthLine),
                color: this.currentGeozoneColor,
                opacity: 0.6,
              });
            });
          }
        }
        if (options?.radiusCircle) {
          if (
            this.currentGeozoneType === 'circle' &&
            this.drawnItems &&
            Object.keys(this.drawnItems._layers).length > 0
          ) {
            this.drawnItems.eachLayer((layer: any) => {
              if (options?.radiusCircle != layer.getRadius()) {
                layer.setRadius(options?.radiusCircle);
                this.drawItemEnd({ layer: layer });
              }
            });
          }
        }
        if (options?.type) {
          this.currentDrawGeozoneElement.disable();
          this.currentGeozoneType = options.type;
          if (this.drawnItems) {
            this.drawnItems.eachLayer((layer: any) => {
              this.drawnItems.removeLayer(layer);
            });
          }
          if (options?.type === 'line') {
            changeColor(this.currentGeozoneColor);
            this.currentDrawGeozoneElement = line;
          }
          if (options?.type === 'polygon') {
            changeColor(this.currentGeozoneColor);
            this.currentDrawGeozoneElement = polygon;
          }
          if (options?.type === 'circle') {
            changeColor(this.currentGeozoneColor);
            this.currentDrawGeozoneElement = circle;
          }
          this.currentDrawGeozoneElement.enable();
        }
        if (options?.colorGeozone) {
          if (
            this.drawnItems &&
            Object.keys(this.drawnItems._layers).length === 0 &&
            options?.pickerClose
          ) {
            this.currentDrawGeozoneElement.disable();
          }
          changeColor(options?.colorGeozone);
          if (
            this.drawnItems &&
            Object.keys(this.drawnItems._layers).length === 0 &&
            options?.pickerClose
          ) {
            this.currentDrawGeozoneElement.enable();
          }
        }
      });
    changeColor(this.currentGeozoneColor);
    this.currentDrawGeozoneElement.enable();
    this.map.on('draw:created', (e: any) => {
      this.drawItemEnd(e);
    });
    this.map.on('draw:editvertex', (e: any) => {
      this.drawItemEnd({ layer: e.poly });
    });
    this.map.on('draw:editresize', (e: any) => {
      this.drawItemEnd({ layer: e.layer }, false);
    });
    this.map.on('draw:editmove', (e: any) => {
      this.drawItemEnd({ layer: e.layer }, false);
    });
    this.map.on('draw:drawvertex', (e: any) => {
      let square = 0;
      let perimeter = 0;
      const points: any[] = [];
      e.layers.eachLayer((layer: any) => {
        points.push([layer._latlng.lat, layer._latlng.lng]);
      });
      if (this.currentGeozoneType === 'polygon') {
        square = this.calculateSquarePolygon(points);
        perimeter = this.calculatePerimeterPolygon(points);
      } else if (this.currentGeozoneType === 'line') {
        square = this.calculateSquareLine(points);
        perimeter = this.calculatePerimeterLine(points);
      }

      this.geoZonesService.createGeozonePerimeter.next(perimeter / 1000);
      this.geoZonesService.createGeozonePerimeterMeters.next(perimeter);
      this.geoZonesService.createGeozoneSquare.next(square / 10000);
    });
    this.mapService.clearMapGeozone
      .pipe(takeUntil(this.geoZonesService.createGeozoneDestroyer))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.drawnItems) {
          this.drawnItems.eachLayer((layer: any) => {
            this.drawnItems.removeLayer(layer);
          });
        }
        line.disable();
        polygon.disable();
        circle.disable();
      });
  }

  calculatePerimeterPolygon(points: any) {
    let perimeter = 0;
    for (let i = 0; i < points.length; i++) {
      if (i === points.length - 1) {
        perimeter += getDistance(
          { latitude: points[i][0], longitude: points[i][1] },
          { latitude: points[0][0], longitude: points[0][1] },
        );
      } else {
        perimeter += getDistance(
          { latitude: points[i][0], longitude: points[i][1] },
          { latitude: points[i + 1][0], longitude: points[i + 1][1] },
        );
      }
    }
    return perimeter;
  }

  calculateSquarePolygon(points: any[]) {
    let result = 0;
    result = getAreaOfPolygon(points);
    return result;
  }

  calculatePerimeterLine(points: any) {
    let perimeter = 0;
    for (let i = 1; i < points.length; i++) {
      perimeter += getDistance(
        { latitude: points[i - 1][0], longitude: points[i - 1][1] },
        { latitude: points[i][0], longitude: points[i][1] },
      );
    }
    return perimeter;
  }

  calculateSquareLine(points: any[]) {
    let result = 0;
    for (let i = 0; i < points.length; i++) {
      if (i === points.length - 1) {
        result +=
          getDistance(
            { latitude: points[i][0], longitude: points[i][1] },
            { latitude: points[0][0], longitude: points[0][1] },
          ) * this.currentGeozoneWidthLine;
      } else {
        result +=
          getDistance(
            { latitude: points[i][0], longitude: points[i][1] },
            { latitude: points[i + 1][0], longitude: points[i + 1][1] },
          ) * this.currentGeozoneWidthLine;
      }
    }
    return result;
  }

  drawEnd() {
    if (this.drawnItems) {
      this.map.removeLayer(this.drawnItems);
    }
    this.drawnItems = null;
    if (this.drawControl) {
      this.map.removeControl(this.drawControl);
    }
    this.drawControl = null;
    this.currentDrawGeozoneElement.disable();
    this.currentGeozoneType = null;
    setTimeout(() => {
      this.currentDrawGeozoneElement = null;
    }, 0);
  }

  drawItemEnd(e: any, fit = true) {
    let square = 0;
    let perimeter = 0;
    let radius = 0;
    if (this.currentGeozoneType === 'polygon') {
      const points = e.layer.getLatLngs()[0].map((latlng: any) => {
        return [latlng.lat, latlng.lng];
      });
      square = this.calculateSquarePolygon(points);
      perimeter = this.calculatePerimeterPolygon(points);
    } else if (this.currentGeozoneType === 'line') {
      const points: any[] = e.layer.getLatLngs().map((latlng: any) => {
        return [latlng.lat, latlng.lng];
      });
      square = this.calculateSquareLine(points);
      perimeter = this.calculatePerimeterLine(points);
    } else if (this.currentGeozoneType === 'circle') {
      radius = Math.floor(e.layer.getRadius());
      square = Math.PI * Math.pow(radius, 2);
      perimeter = 2 * Math.PI * radius;
    }
    this.geoZonesService.createGeozoneSquare.next(square / 10000);
    this.geoZonesService.createGeozonePerimeter.next(perimeter / 1000);
    this.geoZonesService.createGeozonePerimeterMeters.next(perimeter);
    this.geoZonesService.createGeozoneRadius.next(radius);
    e.layer.options.type = this.currentGeozoneType;
    e.layer.editing.enable();
    this.drawnItems.addLayer(e.layer);
    this.geoZonesService.currentDrawLayer = e.layer;
    if (fit && this.currentGeozoneType !== 'circle') {
      this.map.fitBounds(e.layer.getBounds());
    }
  }

  geozonesListHandler() {
    this.store
      .select(selectGeozones)
      .pipe(takeUntil(this.destroy$))
      .subscribe((geozones: any) => {
        this.geozonesLayer.clearLayers();
        this.geozonesList = geozones;
        for (let geozone of geozones) {
          this.handleCreateGeozone(geozone);
        }
        if (this.map) {
          this.handleGeozoneZoom();
        }
      });
  }

  handleCreateGeozone(geozone: any) {
    const currentGeozone = this.geozonesLayer
      .getLayers()
      .find((layer: any) => layer.options.geozoneId === geozone.id);
    if (currentGeozone) {
    } else {
      if (geozone.type === 'line') {
        const line: any = L.polyline(geozone.geo, {
          color: geozone.geozoneColor,
          weight: this.metersToPixel(geozone.widthLine),
          opacity: 0.6,
        });
        line.options.type = geozone.type;
        line.options.geozoneId = geozone.id;
        line.options.widthLine = geozone.widthLine;
        this.geozonesLayer.addLayer(line);
        return line;
      } else if (geozone.type === 'polygon') {
        const polygon: any = L.polygon(geozone.geo, {
          color: geozone.geozoneColor,
        });
        polygon.options.type = geozone.type;
        polygon.options.geozoneId = geozone.id;
        this.geozonesLayer.addLayer(polygon);
        return polygon;
      } else if (geozone.type === 'circle') {
        const circle: any = L.circle(geozone.geo, {
          color: geozone.geozoneColor,
          radius: geozone.radiusCircle,
        });
        circle.options.type = geozone.type;
        circle.options.geozoneId = geozone.id;
        this.geozonesLayer.addLayer(circle);
        return circle;
      }
    }
  }

  handleDeleteGeozone() {
    this.geoZonesService.deleteGeozone
      .pipe(takeUntil(this.destroy$))
      .subscribe((geozoneId: number) => {
        const geozone = this.geozonesLayer
          .getLayers()
          .find((layer: any) => layer.options.geozoneId === geozoneId);
        if (geozone) {
          this.geozonesLayer.removeLayer(geozone);
        }
      });
  }

  getMetersPerPixel() {
    if (this.map === undefined) {
      return 0;
    }
    return (
      (40075016.686 * Math.abs(Math.cos((this.map.getCenter().lat * Math.PI) / 180))) /
      Math.pow(2, this.map.getZoom() + 8)
    );
  }

  metersToPixel(meters: number) {
    const pixels = Math.round(meters / this.getMetersPerPixel());
    return pixels < 5 ? 5 : pixels;
  }

  handleGoToGeozoneBounds() {
    this.geoZonesService.goToGeozoneBounds
      .pipe(takeUntil(this.destroy$))
      .subscribe((geozone: any) => {
        const findedGeozone: any = this.geozonesLayer
          .getLayers()
          .find((layer: any) => layer.options.geozoneId === geozone.id);
        if (findedGeozone) {
          this.map.fitBounds(findedGeozone.getBounds());
        } else {
          const geozoneFromList = this.geozonesList.find((g: any) => g.id === geozone.id);
          if (geozoneFromList) {
            const showedGeozone = this.handleCreateGeozone(geozoneFromList);
            this.map.fitBounds(showedGeozone.getBounds());
          }
        }
      });
  }

  getHelpDescription() {
    if (this.currentGeozoneType === 'circle') {
      return this.sanitizer.bypassSecurityTrustHtml(
        this.translate.instant('DRAW_HELP_DESCRIPTION_CIRCLE'),
      );
    } else if (this.currentGeozoneType === 'line') {
      return this.sanitizer.bypassSecurityTrustHtml(
        this.translate.instant('DRAW_HELP_DESCRIPTION_LINE'),
      );
    } else if (this.currentGeozoneType === 'polygon') {
      return this.sanitizer.bypassSecurityTrustHtml(
        this.translate.instant('DRAW_HELP_DESCRIPTION_POLYGON'),
      );
    }
    return null;
  }

  checkIsValidLatLng(lastMessage: any) {
    return !(
      isNaN(lastMessage?.pos?.x) ||
      isNaN(lastMessage?.pos?.y) ||
      Math.abs(lastMessage?.pos?.x) > 180 ||
      Math.abs(lastMessage?.pos?.y) > 90
    );
  }
}
