<div>
  <button
    tuiButton
    size="s"
    type="button"
    appearance="transparent"
    class="icon custom-style"
    [tuiHint]="'Layer visibility' | translate"
    [tuiHintDirection]="'right'"
    [tuiHintAppearance]="'custom'"
    [tuiDropdown]="content"
    [tuiDropdownManual]="isOpened"
    (click)="onClickAction()"
    (tuiActiveZoneChange)="onActiveZone($event)"
    (tuiObscured)="onObscured($event)"
  >
    <tui-svg [src]="'common/assets/icons/eye.svg'"></tui-svg>

    <ng-template #content>
      <div>
        <tui-checkbox-labeled [formControl]="isVisibleObjectName" size="m">
          {{ 'Object name' | translate }}
        </tui-checkbox-labeled>
      </div>
    </ng-template>
  </button>
</div>
