<div class="draw-description" cdkDragBoundary=".portal" cdkDrag *ngIf="getHelpDescription()">
  <h4 cdkDragHandle>{{ 'Reference' | translate }}</h4>
  <div [innerHTML]="getHelpDescription()"></div>
</div>
<div
  id="map" *ngIf="mapOptions"
  #map
  class="map-container"
  leaflet
  [class.map-name-visible]="isVisibleObjName"
  [leafletOptions]="mapOptions"
  [leafletLayersControl]="layersControl"
  (leafletMapReady)="onMapReady($event)"
>
  <app-object-tooltip
    *ngIf="clickedObject && clickedObjectAddress"
    [object]="clickedObject"
    [address]="clickedObjectAddress"
  ></app-object-tooltip>

  <app-visible-layers-settings></app-visible-layers-settings>

  <div>
    <ng-container *tuiLet="isShowMapFullScreen | async as state">
      <button
        tuiButton
        size="s"
        type="button"
        appearance="transparent"
        class="icon control-block-btn"
        [tuiHint]="(state ? 'Collapse map' : 'Expand map') | translate"
        [tuiHintDirection]="'right'"
        [tuiHintAppearance]="'custom'"
        (click)="isShowMapFullScreen.next(!state)"
      >
        <tui-svg
          [src]="state ? 'common/assets/icons/minimize.svg' : 'common/assets/icons/maximize.svg'"
        ></tui-svg>
      </button>
    </ng-container>
  </div>

  <div class="zoom-value">zoom: {{ zoomValue }}</div>
</div>
