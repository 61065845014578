import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { selectCurrentUser } from '@store/selectors/current_user.selectors';
import { take } from 'rxjs';

export const checkObjectAccess = (accessList: string[], accessName: string) => {
  if (!accessList) {
    return false;
  }
  return accessList.includes(accessName);
};

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  constructor(
    private httpClient: HttpClient,
    private store: Store,
  ) {}
  getAvailableAccessList() {
    return this.httpClient.get('access/available/list');
  }

  checkUserCommonAccess(accessList: string[]) {
    let commonAccesses: any;
    this.store
      .select(selectCurrentUser)
      .pipe(take(1))
      .subscribe((user: any) => {
        commonAccesses = user?.commonAccesses || [];
      });
    return accessList.every((access) => checkObjectAccess(commonAccesses, access));
  }
}
