import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getEnding } from '@common/utils/ending-string-handler';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  constructor(private translate: TranslateService) {}

  formatTimeFromSeconds(
    seconds: number | null,
    format: 'd hh:mm:ss' | 'hh:mm:ss' | null = null,
  ): string {
    if (!seconds) {
      return '-';
    }

    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const remainingSeconds = seconds % 60;

    let result = '';
    if (!format) {
      const translatedDays =
        days > 0 ? `${days} ${this.translate.instant(getEnding(days, 'day')).toLowerCase()}, ` : '';
      const translatedHours =
        hours > 0
          ? `${hours} ${this.translate.instant(getEnding(hours, 'hour')).toLowerCase()}, `
          : '';
      const translatedMinutes =
        minutes > 0
          ? `${minutes} ${this.translate.instant(getEnding(minutes, 'minute')).toLowerCase()}, `
          : '';
      const translatedSeconds = `${remainingSeconds} ${this.translate.instant(getEnding(remainingSeconds, 'second')).toLowerCase()}`;

      result = translatedDays + translatedHours + translatedMinutes + translatedSeconds;
    } else {
      const formattedDays =
        format === 'd hh:mm:ss' && days > 0
          ? `${days} ${this.translate.instant(getEnding(days, 'day')).toLowerCase()} `
          : '';
      const formattedHours =
        format === 'd hh:mm:ss'
          ? `${this.formatNumberWithLeadingZeroes(hours)}:`
          : `${this.formatNumberWithLeadingZeroes(days * 24 + hours)}:`;
      const formattedMinutes = `${this.formatNumberWithLeadingZeroes(minutes)}:`;
      const formattedSeconds = `${this.formatNumberWithLeadingZeroes(remainingSeconds)}`;

      result = `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`;
    }

    return result;
  }

  formatNumberWithLeadingZeroes(val: any) {
    return val.toString().padStart(2, '0');
  }

  formatTimeFromHours = (
    hours: number | null,
    format: 'd hh:mm:ss' | 'hh:mm:ss' | null = null,
  ): string => {
    if (hours === null || hours === undefined) {
      return '-';
    }

    const totalSeconds = Math.round(hours * 60 * 60);
    return this.formatTimeFromSeconds(totalSeconds, format);
  };
}
