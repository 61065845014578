<div class="track-player">
  <h3>{{ 'Track player' | translate }}</h3>

  <tui-svg
    [src]="'common/assets/icons/cross.svg'"
    [tuiHint]="'Close' | translate"
    [tuiHintAppearance]="'custom'"
    (click)="close()"
    class="icon close-btn"
  ></tui-svg>

  <tui-svg
    [src]="'common/assets/icons/chevronDown.svg'"
    [tuiHint]="(isOpen ? 'Collapse' : 'Expand') | translate"
    [tuiHintAppearance]="'custom'"
    (click)="isOpen = !isOpen"
    [ngStyle]="{ transform: isOpen ? 'rotate(180deg)' : 'none' }"
    class="icon accordion-btn"
  ></tui-svg>

  <div class="hidden" *ngIf="isOpen" [@tuiHeightCollapse]="getAnimation(500)">
    <div class="address-speed">
      <div class="address">{{ currentAddress }}</div>
      <div class="speed">{{ currentSpeed | number: '0.0-0' }} {{ 'KM_H' | translate }}</div>
    </div>
    <div class="track-datetime">{{ currentDatetime }}</div>
    <div class="track-slider">
      <input
        tuiSlider
        type="range"
        [max]="maxSliderValue"
        step="1"
        size="s"
        [formControl]="currentSliderValue"
      />
    </div>
    <div class="track-control">
      <div class="track-control__play">
        <button
          [tuiHint]="'PLAY_PAUSE' | translate"
          (click)="playTrack()"
          [tuiHintAppearance]="'custom'"
        >
          <tui-svg *ngIf="!playInterval" [src]="'tuiIconPlay'" class="icon icon-green"></tui-svg>
          <tui-svg *ngIf="playInterval" [src]="'tuiIconPause'" class="icon icon-orange"></tui-svg>
        </button>
        <button
          [tuiHint]="'START_POSITION' | translate"
          [tuiHintAppearance]="'custom'"
          (click)="moveStartTrack()"
        >
          <tui-svg [src]="'tuiIconRewind'" class="icon"></tui-svg>
        </button>
        <button
          [tuiHint]="'PREVIOUS_POINT' | translate"
          [tuiHintAppearance]="'custom'"
          (click)="prevPoint()"
        >
          <tui-svg [src]="'tuiIconSkipBack'" class="icon"></tui-svg>
        </button>
        <button
          [tuiHint]="'NEXT_POINT' | translate"
          [tuiHintAppearance]="'custom'"
          (click)="nextPoint()"
        >
          <tui-svg [src]="'tuiIconSkipForward'" class="icon"></tui-svg>
        </button>
        <button
          [tuiHint]="'FINAL_POSITION' | translate"
          [tuiHintAppearance]="'custom'"
          (click)="moveFinishTrack()"
        >
          <tui-svg [src]="'tuiIconFastForward'" class="icon"></tui-svg>
        </button>
      </div>
      <div class="track-control__speed">
        <!--        <tui-combo-box-->
        <!--          class="b-form"-->
        <!--          [stringify]="stringify"-->
        <!--          [formControl]="currentSpeedValue"-->
        <!--          [tuiTextfieldSize]="'s'"-->
        <!--        >-->
        <!--          {{ 'PLAYBACK_SPEED' | translate }}-->
        <!--          <input tuiTextfield placeholder="Скорость воспроизведения" />-->
        <!--          <tui-data-list-wrapper-->
        <!--            *tuiDataList-->
        <!--            [items]="availableSpeeds | tuiFilterByInputWith: stringify"-->
        <!--            [itemContent]="stringify | tuiStringifyContent"-->
        <!--          ></tui-data-list-wrapper>-->
        <!--        </tui-combo-box>-->

        <tui-select
          class="b-form"
          [formControl]="currentSpeedValue"
          tuiTextfieldSize="s"
          [valueContent]="stringifyValue(availableSpeeds)"
        >
          <ng-template tuiDataList>
            <tui-data-list>
              <button *ngFor="let item of availableSpeeds" tuiOption [value]="item.value">
                {{ item.title | translate }}
              </button>
            </tui-data-list>
          </ng-template>
        </tui-select>
      </div>
    </div>

    <div class="track-params">
      <div class="expanded-header" (click)="expandedParams = !expandedParams">
        {{ 'PARAMETERS' | translate }}
        <tui-svg [src]="'tuiIconChevronDown'" *ngIf="!expandedParams" class="icon"></tui-svg>
        <tui-svg [src]="'tuiIconChevronUp'" *ngIf="expandedParams" class="icon"></tui-svg>
      </div>
      <tui-expand [expanded]="expandedParams">
        <ng-template tuiExpandContent>
          <tui-scrollbar style="max-height: 600px; margin-bottom: 16px">
            <div class="track-params__list">
              <div
                *ngFor="let param of getMessageParams | keyvalue"
                class="track-params__list-item"
              >
                <strong>{{ param.key }}: </strong> {{ param.value }}
              </div>
            </div>
          </tui-scrollbar>
        </ng-template>
      </tui-expand>
    </div>
  </div>
</div>
