import { getDistanceFromLine, isPointInPolygon, isPointWithinRadius } from 'geolib';
import { getSearchSortValue } from '@common/utils/search-sort';

export const selectGeozones = (state: any) => state.geozones.list;

export const selectGeozonesById = (state: any, geozonesIds: number[]) => {
  return state.geozones.list.filter((f: any) => geozonesIds.includes(f.id));
};

export const selectGeozonesNotGroupById = (state: any, geozonesIds: number[]) => {
  return state.geozones.list.filter((f: any) => !geozonesIds.includes(f.id));
};

const checkPointInGeozone = (x: any, y: number, geozone: any) => {
  if (!x || !y) return false;
  if (geozone.type === 'polygon') {
    return isPointInPolygon(
      { latitude: y, longitude: x },
      geozone.geo.map((g: any) => ({ latitude: g.lat, longitude: g.lng })),
    );
  } else if (geozone.type === 'line') {
    return geozone.geo.some((g: any, index: number) => {
      if (index !== geozone.geo.length - 1) {
        return (
          getDistanceFromLine(
            { latitude: y, longitude: x },
            {
              latitude: geozone.geo[index].lat,
              longitude: geozone.geo[index].lng,
            },
            {
              latitude: geozone.geo[index + 1].lat,
              longitude: geozone.geo[index + 1].lng,
            },
          ) <=
          geozone.widthLine / 2
        );
      } else {
        return false;
      }
    });
  } else if (geozone.type === 'circle') {
    return isPointWithinRadius(
      { latitude: y, longitude: x },
      {
        latitude: geozone.geo.lat,
        longitude: geozone.geo.lng,
      },
      geozone.radiusCircle,
    );
  }
  return false;
};

export const selectGeozonesWithObjectsCount = (state: any) => {
  return state.geozones.list.map((geozone: any) => {
    return {
      ...geozone,
      objectsCount: state.objects.list.filter((object: any) =>
        checkPointInGeozone(object.lastMessage?.pos?.x, object.lastMessage?.pos?.y, geozone),
      ).length,
    };
  });
};

export const selectSearchedSortedGeozones = (
  state: any,
  { searchValue, sortedValue }: any = { searchValue: null, sortedValue: null },
) => {
  const list = selectGeozonesWithObjectsCount(state);

  return getSearchSortValue(list, searchValue, sortedValue);
};
