<div class="object-tooltip" *ngIf="object">
  <div class="header">
    <div>
      <div class="icon">
        <div
          [style.color]="object.objectIconColor || '#828282'"
          [innerHTML]="availableIcons[object.iconId]"
        ></div>
      </div>
      <div class="name">{{ object.name }}</div>
    </div>
    <div class="speed">{{ object.lastMessage?.pos?.s || 0 }} {{ 'KM_H' | translate }}</div>
  </div>
  <div class="address">{{ address }}</div>
  <tui-scrollbar>
    <div class="coordinates">
      <span>{{ 'Coordinates' | translate }}</span>
      <div class="date">
        <div>{{ formatDateToRelative(object.lastMessage?.t) }}</div>
        <div>{{ object.lastMessage?.t | customDate }}</div>
      </div>

      <div>
        <div>{{ object.lastMessage?.pos?.y ? object.lastMessage?.pos?.y.toFixed(6) : '' }}</div>
        <div>{{ object.lastMessage?.pos?.x ? object.lastMessage?.pos?.x.toFixed(6) : '' }}</div>
        <div>({{ object.lastMessage?.pos?.sc || 0 }})</div>
      </div>
    </div>

    <div class="assigned-drivers" *ngIf="accessService.checkUserCommonAccess(['access_drivers'])">
      <ng-container *ngIf="object?.drivers?.length">
        {{ 'Designated drivers' | translate }}
        <ng-container *ngFor="let driver of object?.drivers; let last = last">
          <span *ngIf="!last">{{ driver?.name }}, </span>
          <span *ngIf="last">{{ driver?.name }}</span>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!object?.drivers?.length">
        <span>{{ 'Drivers are not assigned' | translate }}</span>
      </ng-container>
    </div>

    <div class="object-sensors">
      <div class="object-sensors__list">
        <div *ngFor="let param of availableParams; let i = index" class="object-sensors__list-item">
          <div class="title">{{ param.title }}</div>
          <div class="value">{{ param.value }} {{ param.unit }}</div>
        </div>
      </div>
    </div>
  </tui-scrollbar>
</div>
