export const gpsData: any = [
  {
    t: 1677458497,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677458499,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.782,
      io_66: 12782,
      pwr_int: 3.933,
      io_67: 3933,
      io_68: 0,
    },
  },
  {
    t: 1677458797,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677458798,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.785,
      io_66: 12785,
      pwr_int: 3.933,
      io_67: 3933,
      io_68: 0,
    },
  },
  {
    t: 1677459097,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677459099,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.782,
      io_66: 12782,
      pwr_int: 3.934,
      io_67: 3934,
      io_68: 0,
    },
  },
  {
    t: 1677459397,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677459398,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.785,
      io_66: 12785,
      pwr_int: 3.933,
      io_67: 3933,
      io_68: 0,
    },
  },
  {
    t: 1677459697,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677459699,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.782,
      io_66: 12782,
      pwr_int: 3.933,
      io_67: 3933,
      io_68: 0,
    },
  },
  {
    t: 1677459997,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677459998,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.781,
      io_66: 12781,
      pwr_int: 3.932,
      io_67: 3932,
      io_68: 0,
    },
  },
  {
    t: 1677460297,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677460299,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.782,
      io_66: 12782,
      pwr_int: 3.932,
      io_67: 3932,
      io_68: 0,
    },
  },
  {
    t: 1677460597,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677460598,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.782,
      io_66: 12782,
      pwr_int: 3.932,
      io_67: 3932,
      io_68: 0,
    },
  },
  {
    t: 1677460897,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677460899,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.781,
      io_66: 12781,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677461197,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677461198,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.781,
      io_66: 12781,
      pwr_int: 3.932,
      io_67: 3932,
      io_68: 0,
    },
  },
  {
    t: 1677461497,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677461499,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.778,
      io_66: 12778,
      pwr_int: 3.932,
      io_67: 3932,
      io_68: 0,
    },
  },
  {
    t: 1677461797,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677461798,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.782,
      io_66: 12782,
      pwr_int: 3.933,
      io_67: 3933,
      io_68: 0,
    },
  },
  {
    t: 1677462097,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677462098,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.777,
      io_66: 12777,
      pwr_int: 3.931,
      io_67: 3931,
      io_68: 0,
    },
  },
  {
    t: 1677462397,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677462398,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.778,
      io_66: 12778,
      pwr_int: 3.931,
      io_67: 3931,
      io_68: 0,
    },
  },
  {
    t: 1677462697,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677462698,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.776,
      io_66: 12776,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677462997,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677462998,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.768,
      io_66: 12768,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677463297,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677463298,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.779,
      io_66: 12779,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677463597,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677463598,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.771,
      io_66: 12771,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677463897,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677463898,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.776,
      io_66: 12776,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677464197,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677464198,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.762,
      io_66: 12762,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677464497,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677464498,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.757,
      io_66: 12757,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677464797,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677464798,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.756,
      io_66: 12756,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677465097,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677465098,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.746,
      io_66: 12746,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677465397,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677465399,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.749,
      io_66: 12749,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677465697,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677465698,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.745,
      io_66: 12745,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677465997,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677465998,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.741,
      io_66: 12741,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677466297,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677466298,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.743,
      io_66: 12743,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677466597,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677466598,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.742,
      io_66: 12742,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677466897,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677466898,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677467197,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677467198,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.74,
      io_66: 12740,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677467497,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677467498,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.743,
      io_66: 12743,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677467797,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677467799,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677468097,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677468098,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677468397,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677468399,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677468697,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677468698,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.739,
      io_66: 12739,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677468997,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677468999,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677469297,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677469298,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677469597,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677469599,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.74,
      io_66: 12740,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677469897,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677469898,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.735,
      io_66: 12735,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677470197,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677470199,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.74,
      io_66: 12740,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677470497,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677470498,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677470797,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677470798,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677471097,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677471098,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677471397,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677471399,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677471697,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677471698,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.74,
      io_66: 12740,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677471997,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677471999,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677472297,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677472298,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677472597,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677472599,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.74,
      io_66: 12740,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677472897,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677472898,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677473197,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677473198,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677473497,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677473498,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677473797,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677473798,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677474097,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677474099,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.736,
      io_66: 12736,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677474397,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677474398,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677474697,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677474699,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677474997,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677474998,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677475297,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677475299,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677475597,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677475598,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677475897,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677475899,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677476197,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677476198,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.737,
      io_66: 12737,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677476497,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677476498,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.737,
      io_66: 12737,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677476797,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677476798,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677477097,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677477099,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677477397,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677477398,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.736,
      io_66: 12736,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677477697,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677477698,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.733,
      io_66: 12733,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677477997,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677477998,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677478297,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677478299,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.736,
      io_66: 12736,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677478597,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677478598,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.738,
      io_66: 12738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677478897,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27408,
      x: 30.4587266,
      c: 0,
      z: 163,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677478899,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.737,
      io_66: 12737,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479160,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2739316,
      x: 30.4581,
      c: 221,
      z: 87,
      s: 10,
      sc: 18,
    },
    lc: 0,
    rt: 1677479161,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.61,
      io_66: 14610,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479164,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.273845,
      x: 30.4579349,
      c: 237,
      z: 88,
      s: 12,
      sc: 19,
    },
    lc: 0,
    rt: 1677479165,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.673,
      io_66: 14673,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479166,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2738083,
      x: 30.4578383,
      c: 219,
      z: 89,
      s: 13,
      sc: 19,
    },
    lc: 0,
    rt: 1677479167,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.639,
      io_66: 14639,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677479167,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2737883,
      x: 30.4577883,
      c: 207,
      z: 89,
      s: 14,
      sc: 19,
    },
    lc: 0,
    rt: 1677479169,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.653,
      io_66: 14653,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677479171,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.273695,
      x: 30.4576083,
      c: 241,
      z: 90,
      s: 12,
      sc: 19,
    },
    lc: 0,
    rt: 1677479172,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.653,
      io_66: 14653,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479172,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2736833,
      x: 30.4575566,
      c: 254,
      z: 89,
      s: 15,
      sc: 19,
    },
    lc: 0,
    rt: 1677479173,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.668,
      io_66: 14668,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479173,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27367,
      x: 30.457515,
      c: 234,
      z: 89,
      s: 10,
      sc: 19,
    },
    lc: 0,
    rt: 1677479174,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.62,
      io_66: 14620,
      pwr_int: 3.918,
      io_67: 3918,
      io_68: 0,
    },
  },
  {
    t: 1677479174,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2736683,
      x: 30.4574833,
      c: 282,
      z: 88,
      s: 8,
      sc: 19,
    },
    lc: 0,
    rt: 1677479175,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.648,
      io_66: 14648,
      pwr_int: 3.931,
      io_67: 3931,
      io_68: 0,
    },
  },
  {
    t: 1677479174,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2736683,
      x: 30.4574833,
      c: 282,
      z: 88,
      s: 8,
      sc: 19,
    },
    lc: 0,
    rt: 1677479175,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 1,
      io_254: 32,
      pwr_ext: 14.649,
      io_66: 14649,
      pwr_int: 3.931,
      io_67: 3931,
      io_68: 0,
    },
  },
  {
    t: 1677479182,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.273725,
      x: 30.457125,
      c: 292,
      z: 89,
      s: 19,
      sc: 18,
    },
    lc: 0,
    rt: 1677479183,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.672,
      io_66: 14672,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479190,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2738083,
      x: 30.456565,
      c: 290,
      z: 89,
      s: 8,
      sc: 19,
    },
    lc: 0,
    rt: 1677479191,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.668,
      io_66: 14668,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479192,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2738083,
      x: 30.4564966,
      c: 251,
      z: 88,
      s: 6,
      sc: 19,
    },
    lc: 0,
    rt: 1677479193,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.613,
      io_66: 14613,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677479193,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2738,
      x: 30.45647,
      c: 221,
      z: 87,
      s: 6,
      sc: 19,
    },
    lc: 0,
    rt: 1677479194,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.545,
      io_66: 14545,
      pwr_int: 3.918,
      io_67: 3918,
      io_68: 0,
    },
  },
  {
    t: 1677479194,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2737833,
      x: 30.4564433,
      c: 207,
      z: 87,
      s: 10,
      sc: 19,
    },
    lc: 0,
    rt: 1677479202,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.596,
      io_66: 14596,
      pwr_int: 3.922,
      io_67: 3922,
      io_68: 0,
    },
  },
  {
    t: 1677479197,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2737133,
      x: 30.4563783,
      c: 201,
      z: 87,
      s: 8,
      sc: 19,
    },
    lc: 0,
    rt: 1677479203,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 31,
      pwr_ext: 14.654,
      io_66: 14654,
      pwr_int: 3.933,
      io_67: 3933,
      io_68: 0,
    },
  },
  {
    t: 1677479394,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2735966,
      x: 30.4562983,
      c: 15,
      z: 87,
      s: 7,
      sc: 19,
    },
    lc: 0,
    rt: 1677479395,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.024,
      io_66: 14024,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479398,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27367,
      x: 30.4563583,
      c: 29,
      z: 88,
      s: 6,
      sc: 20,
    },
    lc: 0,
    rt: 1677479399,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.885,
      io_66: 13885,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479420,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2738199,
      x: 30.4563583,
      c: 244,
      z: 84,
      s: 13,
      sc: 20,
    },
    lc: 0,
    rt: 1677479422,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.951,
      io_66: 13951,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479421,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.273805,
      x: 30.4562966,
      c: 228,
      z: 84,
      s: 13,
      sc: 20,
    },
    lc: 0,
    rt: 1677479422,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.947,
      io_66: 13947,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479423,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2737666,
      x: 30.456185,
      c: 214,
      z: 84,
      s: 13,
      sc: 20,
    },
    lc: 0,
    rt: 1677479425,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.875,
      io_66: 13875,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677479432,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2734333,
      x: 30.4557983,
      c: 204,
      z: 88,
      s: 18,
      sc: 20,
    },
    lc: 0,
    rt: 1677479433,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.953,
      io_66: 13953,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479438,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2731516,
      x: 30.4555833,
      c: 194,
      z: 89,
      s: 16,
      sc: 20,
    },
    lc: 0,
    rt: 1677479440,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.876,
      io_66: 13876,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479439,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2731066,
      x: 30.4555716,
      c: 189,
      z: 89,
      s: 17,
      sc: 20,
    },
    lc: 0,
    rt: 1677479444,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 54,
      pwr_ext: 13.861,
      io_66: 13861,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479443,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2729033,
      x: 30.455545,
      c: 177,
      z: 89,
      s: 23,
      sc: 20,
    },
    lc: 0,
    rt: 1677479444,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.865,
      io_66: 13865,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479457,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2719566,
      x: 30.45578,
      c: 164,
      z: 88,
      s: 16,
      sc: 20,
    },
    lc: 0,
    rt: 1677479458,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.715,
      io_66: 13715,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479458,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2719283,
      x: 30.4558099,
      c: 140,
      z: 88,
      s: 12,
      sc: 20,
    },
    lc: 0,
    rt: 1677479459,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.769,
      io_66: 13769,
      pwr_int: 3.923,
      io_67: 3923,
      io_68: 0,
    },
  },
  {
    t: 1677479459,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27191,
      x: 30.4558666,
      c: 115,
      z: 89,
      s: 14,
      sc: 20,
    },
    lc: 0,
    rt: 1677479460,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.763,
      io_66: 13763,
      pwr_int: 3.922,
      io_67: 3922,
      io_68: 0,
    },
  },
  {
    t: 1677479460,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2718983,
      x: 30.45594,
      c: 99,
      z: 89,
      s: 16,
      sc: 20,
    },
    lc: 0,
    rt: 1677479461,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.74,
      io_66: 13740,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677479461,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2718966,
      x: 30.456025,
      c: 89,
      z: 89,
      s: 16,
      sc: 20,
    },
    lc: 0,
    rt: 1677479462,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.708,
      io_66: 13708,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677479465,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2719066,
      x: 30.45628,
      c: 120,
      z: 90,
      s: 6,
      sc: 20,
    },
    lc: 0,
    rt: 1677479466,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.67,
      io_66: 13670,
      pwr_int: 3.933,
      io_67: 3933,
      io_68: 0,
    },
  },
  {
    t: 1677479469,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2718616,
      x: 30.4563533,
      c: 164,
      z: 92,
      s: 6,
      sc: 20,
    },
    lc: 0,
    rt: 1677479471,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.811,
      io_66: 13811,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677479489,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2715,
      x: 30.45655,
      c: 150,
      z: 88,
      s: 8,
      sc: 20,
    },
    lc: 0,
    rt: 1677479491,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.824,
      io_66: 13824,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479491,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2714616,
      x: 30.4566049,
      c: 138,
      z: 89,
      s: 9,
      sc: 20,
    },
    lc: 0,
    rt: 1677479492,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.8,
      io_66: 13800,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677479492,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2714416,
      x: 30.456645,
      c: 128,
      z: 89,
      s: 10,
      sc: 20,
    },
    lc: 0,
    rt: 1677479493,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.79,
      io_66: 13790,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677479493,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2714266,
      x: 30.4566883,
      c: 113,
      z: 89,
      s: 9,
      sc: 20,
    },
    lc: 0,
    rt: 1677479494,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.797,
      io_66: 13797,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677479496,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2714033,
      x: 30.45682,
      c: 103,
      z: 89,
      s: 6,
      sc: 20,
    },
    lc: 0,
    rt: 1677479498,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.78,
      io_66: 13780,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479502,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27137,
      x: 30.4570633,
      c: 82,
      z: 87,
      s: 6,
      sc: 20,
    },
    lc: 0,
    rt: 1677479503,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.755,
      io_66: 13755,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479669,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2713466,
      x: 30.4571883,
      c: 267,
      z: 92,
      s: 7,
      sc: 19,
    },
    lc: 0,
    rt: 1677479670,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.679,
      io_66: 13679,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479670,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2713533,
      x: 30.45715,
      c: 277,
      z: 91,
      s: 7,
      sc: 19,
    },
    lc: 0,
    rt: 1677479671,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.681,
      io_66: 13681,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479675,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2713683,
      x: 30.45699,
      c: 265,
      z: 90,
      s: 6,
      sc: 19,
    },
    lc: 0,
    rt: 1677479676,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.682,
      io_66: 13682,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479678,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2713799,
      x: 30.45688,
      c: 292,
      z: 89,
      s: 7,
      sc: 19,
    },
    lc: 0,
    rt: 1677479679,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.631,
      io_66: 13631,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677479679,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.271385,
      x: 30.45685,
      c: 277,
      z: 89,
      s: 6,
      sc: 19,
    },
    lc: 0,
    rt: 1677479679,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.684,
      io_66: 13684,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677479683,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2713916,
      x: 30.45668,
      c: 260,
      z: 88,
      s: 9,
      sc: 19,
    },
    lc: 0,
    rt: 1677479684,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.715,
      io_66: 13715,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677479685,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2713833,
      x: 30.4565766,
      c: 248,
      z: 87,
      s: 9,
      sc: 19,
    },
    lc: 0,
    rt: 1677479686,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.646,
      io_66: 13646,
      pwr_int: 3.923,
      io_67: 3923,
      io_68: 0,
    },
  },
  {
    t: 1677479687,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.271365,
      x: 30.456495,
      c: 226,
      z: 87,
      s: 7,
      sc: 19,
    },
    lc: 0,
    rt: 1677479687,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.582,
      io_66: 13582,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677479707,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.271315,
      x: 30.4564233,
      c: 197,
      z: 86,
      s: 10,
      sc: 19,
    },
    lc: 0,
    rt: 1677479708,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.739,
      io_66: 13739,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479707,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.271315,
      x: 30.4564233,
      c: 197,
      z: 86,
      s: 10,
      sc: 19,
    },
    lc: 0,
    rt: 1677479708,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 1,
      io_254: 34,
      pwr_ext: 13.738,
      io_66: 13738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479708,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.271285,
      x: 30.4564133,
      c: 186,
      z: 86,
      s: 12,
      sc: 19,
    },
    lc: 0,
    rt: 1677479709,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.723,
      io_66: 13723,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677479713,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2711133,
      x: 30.4564116,
      c: 176,
      z: 87,
      s: 18,
      sc: 19,
    },
    lc: 0,
    rt: 1677479714,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.723,
      io_66: 13723,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479725,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2706116,
      x: 30.45645,
      c: 190,
      z: 89,
      s: 12,
      sc: 19,
    },
    lc: 0,
    rt: 1677479726,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.653,
      io_66: 13653,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479726,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27058,
      x: 30.45643,
      c: 209,
      z: 88,
      s: 11,
      sc: 19,
    },
    lc: 0,
    rt: 1677479727,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.669,
      io_66: 13669,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677479727,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2705566,
      x: 30.456395,
      c: 236,
      z: 89,
      s: 10,
      sc: 19,
    },
    lc: 0,
    rt: 1677479728,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.639,
      io_66: 13639,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677479729,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27053,
      x: 30.45631,
      c: 253,
      z: 88,
      s: 8,
      sc: 19,
    },
    lc: 0,
    rt: 1677479731,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.709,
      io_66: 13709,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677479730,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2705283,
      x: 30.4562666,
      c: 263,
      z: 88,
      s: 8,
      sc: 19,
    },
    lc: 0,
    rt: 1677479731,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.716,
      io_66: 13716,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677479732,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27052,
      x: 30.4561516,
      c: 243,
      z: 89,
      s: 14,
      sc: 19,
    },
    lc: 0,
    rt: 1677479733,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.71,
      io_66: 13710,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677479733,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2705016,
      x: 30.4560866,
      c: 228,
      z: 89,
      s: 16,
      sc: 19,
    },
    lc: 0,
    rt: 1677479734,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.702,
      io_66: 13702,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677479734,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2704633,
      x: 30.4560316,
      c: 205,
      z: 89,
      s: 20,
      sc: 19,
    },
    lc: 0,
    rt: 1677479735,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.712,
      io_66: 13712,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677479735,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.270405,
      x: 30.4560016,
      c: 188,
      z: 89,
      s: 27,
      sc: 19,
    },
    lc: 0,
    rt: 1677479736,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.709,
      io_66: 13709,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677479740,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.27001,
      x: 30.4558866,
      c: 191,
      z: 90,
      s: 38,
      sc: 19,
    },
    lc: 0,
    rt: 1677479741,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.723,
      io_66: 13723,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479749,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.26906,
      x: 30.45533,
      c: 201,
      z: 87,
      s: 46,
      sc: 19,
    },
    lc: 0,
    rt: 1677479750,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.732,
      io_66: 13732,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479771,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.266495,
      x: 30.453235,
      c: 193,
      z: 89,
      s: 44,
      sc: 19,
    },
    lc: 0,
    rt: 1677479772,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.746,
      io_66: 13746,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479776,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.26597,
      x: 30.4530916,
      c: 181,
      z: 92,
      s: 43,
      sc: 19,
    },
    lc: 0,
    rt: 1677479777,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.75,
      io_66: 13750,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479779,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2655833,
      x: 30.4531,
      c: 176,
      z: 96,
      s: 53,
      sc: 19,
    },
    lc: 0,
    rt: 1677479780,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.731,
      io_66: 13731,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479784,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2648833,
      x: 30.453375,
      c: 164,
      z: 91,
      s: 58,
      sc: 19,
    },
    lc: 0,
    rt: 1677479788,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.73,
      io_66: 13730,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677479794,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2633233,
      x: 30.4541649,
      c: 176,
      z: 85,
      s: 66,
      sc: 20,
    },
    lc: 0,
    rt: 1677479795,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.744,
      io_66: 13744,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479809,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2605766,
      x: 30.4542316,
      c: 182,
      z: 88,
      s: 63,
      sc: 20,
    },
    lc: 0,
    rt: 1677479810,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.461,
      io_66: 13461,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479816,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2594833,
      x: 30.4540149,
      c: 187,
      z: 89,
      s: 52,
      sc: 19,
    },
    lc: 0,
    rt: 1677479817,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.391,
      io_66: 13391,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479819,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2591833,
      x: 30.45392,
      c: 191,
      z: 85,
      s: 40,
      sc: 20,
    },
    lc: 0,
    rt: 1677479820,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.37,
      io_66: 13370,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677479829,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2582883,
      x: 30.4534166,
      c: 197,
      z: 77,
      s: 30,
      sc: 20,
    },
    lc: 0,
    rt: 1677479830,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.328,
      io_66: 13328,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479838,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2577383,
      x: 30.4530566,
      c: 196,
      z: 75,
      s: 20,
      sc: 20,
    },
    lc: 0,
    rt: 1677479839,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.404,
      io_66: 13404,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479842,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2575916,
      x: 30.4529683,
      c: 196,
      z: 75,
      s: 9,
      sc: 20,
    },
    lc: 0,
    rt: 1677479843,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.332,
      io_66: 13332,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677479843,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.257575,
      x: 30.4529583,
      c: 183,
      z: 75,
      s: 7,
      sc: 20,
    },
    lc: 0,
    rt: 1677479844,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.353,
      io_66: 13353,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677479966,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2575316,
      x: 30.452915,
      c: 199,
      z: 74,
      s: 8,
      sc: 20,
    },
    lc: 0,
    rt: 1677479967,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.602,
      io_66: 13602,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480000,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2565416,
      x: 30.4522483,
      c: 197,
      z: 79,
      s: 18,
      sc: 21,
    },
    lc: 0,
    rt: 1677480004,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.688,
      io_66: 13688,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480004,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.256305,
      x: 30.4520933,
      c: 197,
      z: 80,
      s: 30,
      sc: 21,
    },
    lc: 0,
    rt: 1677480005,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.693,
      io_66: 13693,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677480012,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2555799,
      x: 30.4516416,
      c: 198,
      z: 81,
      s: 40,
      sc: 21,
    },
    lc: 0,
    rt: 1677480016,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.87,
      io_66: 13870,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480023,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2544966,
      x: 30.4507366,
      c: 208,
      z: 78,
      s: 41,
      sc: 21,
    },
    lc: 0,
    rt: 1677480024,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.861,
      io_66: 13861,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480029,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2539116,
      x: 30.4499816,
      c: 211,
      z: 81,
      s: 51,
      sc: 20,
    },
    lc: 0,
    rt: 1677480030,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.88,
      io_66: 13880,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480038,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.252865,
      x: 30.44882,
      c: 200,
      z: 86,
      s: 49,
      sc: 19,
    },
    lc: 0,
    rt: 1677480040,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.868,
      io_66: 13868,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480040,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2526233,
      x: 30.4487333,
      c: 188,
      z: 87,
      s: 46,
      sc: 19,
    },
    lc: 0,
    rt: 1677480041,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.826,
      io_66: 13826,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677480052,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2511716,
      x: 30.44836,
      c: 201,
      z: 92,
      s: 51,
      sc: 19,
    },
    lc: 0,
    rt: 1677480054,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.602,
      io_66: 13602,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480056,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2507066,
      x: 30.4478533,
      c: 211,
      z: 94,
      s: 53,
      sc: 19,
    },
    lc: 0,
    rt: 1677480058,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.608,
      io_66: 13608,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480062,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.249995,
      x: 30.4470716,
      c: 201,
      z: 97,
      s: 56,
      sc: 19,
    },
    lc: 0,
    rt: 1677480064,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.485,
      io_66: 13485,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480081,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2473416,
      x: 30.4454616,
      c: 196,
      z: 104,
      s: 63,
      sc: 19,
    },
    lc: 0,
    rt: 1677480083,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.486,
      io_66: 13486,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480089,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.246105,
      x: 30.4447983,
      c: 184,
      z: 107,
      s: 64,
      sc: 19,
    },
    lc: 0,
    rt: 1677480091,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.49,
      io_66: 13490,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480092,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2456233,
      x: 30.4448133,
      c: 174,
      z: 107,
      s: 63,
      sc: 19,
    },
    lc: 0,
    rt: 1677480094,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.465,
      io_66: 13465,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677480102,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.244075,
      x: 30.4450933,
      c: 186,
      z: 113,
      s: 55,
      sc: 19,
    },
    lc: 0,
    rt: 1677480104,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.488,
      io_66: 13488,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480104,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2438066,
      x: 30.44498,
      c: 197,
      z: 116,
      s: 53,
      sc: 19,
    },
    lc: 0,
    rt: 1677480105,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.448,
      io_66: 13448,
      pwr_int: 3.923,
      io_67: 3923,
      io_68: 0,
    },
  },
  {
    t: 1677480108,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2433233,
      x: 30.44456,
      c: 209,
      z: 121,
      s: 52,
      sc: 19,
    },
    lc: 0,
    rt: 1677480110,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.488,
      io_66: 13488,
      pwr_int: 3.932,
      io_67: 3932,
      io_68: 0,
    },
  },
  {
    t: 1677480112,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2429033,
      x: 30.4438766,
      c: 219,
      z: 122,
      s: 54,
      sc: 18,
    },
    lc: 0,
    rt: 1677480113,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.481,
      io_66: 13481,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480121,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.24195,
      x: 30.4424516,
      c: 209,
      z: 128,
      s: 53,
      sc: 17,
    },
    lc: 0,
    rt: 1677480123,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.497,
      io_66: 13497,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480141,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2393716,
      x: 30.4401133,
      c: 202,
      z: 129,
      s: 59,
      sc: 18,
    },
    lc: 0,
    rt: 1677480143,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.468,
      io_66: 13468,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480157,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2369566,
      x: 30.4381566,
      c: 201,
      z: 105,
      s: 69,
      sc: 17,
    },
    lc: 0,
    rt: 1677480159,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.475,
      io_66: 13475,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480165,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2357149,
      x: 30.4371933,
      c: 201,
      z: 96,
      s: 58,
      sc: 18,
    },
    lc: 0,
    rt: 1677480167,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.413,
      io_66: 13413,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480171,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2349983,
      x: 30.436505,
      c: 212,
      z: 92,
      s: 51,
      sc: 19,
    },
    lc: 0,
    rt: 1677480173,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.48,
      io_66: 13480,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480189,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2333416,
      x: 30.43384,
      c: 215,
      z: 92,
      s: 40,
      sc: 18,
    },
    lc: 0,
    rt: 1677480191,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.393,
      io_66: 13393,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480194,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.232955,
      x: 30.4334283,
      c: 207,
      z: 90,
      s: 29,
      sc: 17,
    },
    lc: 0,
    rt: 1677480195,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.3,
      io_66: 13300,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677480197,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2327966,
      x: 30.43328,
      c: 206,
      z: 88,
      s: 17,
      sc: 17,
    },
    lc: 0,
    rt: 1677480198,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.282,
      io_66: 13282,
      pwr_int: 3.913,
      io_67: 3913,
      io_68: 0,
    },
  },
  {
    t: 1677480201,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.232665,
      x: 30.433145,
      c: 216,
      z: 87,
      s: 14,
      sc: 18,
    },
    lc: 0,
    rt: 1677480202,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.409,
      io_66: 13409,
      pwr_int: 3.932,
      io_67: 3932,
      io_68: 0,
    },
  },
  {
    t: 1677480208,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2324266,
      x: 30.4328283,
      c: 205,
      z: 87,
      s: 6,
      sc: 18,
    },
    lc: 0,
    rt: 1677480209,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.257,
      io_66: 13257,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480272,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.232405,
      x: 30.43281,
      c: 198,
      z: 89,
      s: 10,
      sc: 17,
    },
    lc: 0,
    rt: 1677480273,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 26,
      pwr_ext: 13.384,
      io_66: 13384,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480275,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2322683,
      x: 30.4327366,
      c: 201,
      z: 89,
      s: 28,
      sc: 17,
    },
    lc: 0,
    rt: 1677480276,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.369,
      io_66: 13369,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677480275,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2321933,
      x: 30.43268,
      c: 202,
      z: 89,
      s: 34,
      sc: 17,
    },
    lc: 0,
    rt: 1677480277,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 27,
      pwr_ext: 13.374,
      io_66: 13374,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677480278,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2319083,
      x: 30.4325149,
      c: 199,
      z: 90,
      s: 33,
      sc: 17,
    },
    lc: 0,
    rt: 1677480280,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 35,
      pwr_ext: 13.325,
      io_66: 13325,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677480280,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2318433,
      x: 30.4324633,
      c: 199,
      z: 89,
      s: 23,
      sc: 17,
    },
    lc: 0,
    rt: 1677480281,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.357,
      io_66: 13357,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480283,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2316533,
      x: 30.4323716,
      c: 192,
      z: 86,
      s: 33,
      sc: 17,
    },
    lc: 0,
    rt: 1677480284,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.468,
      io_66: 13468,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677480287,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2312833,
      x: 30.4322049,
      c: 189,
      z: 82,
      s: 44,
      sc: 17,
    },
    lc: 0,
    rt: 1677480288,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.458,
      io_66: 13458,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480289,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2310283,
      x: 30.4321633,
      c: 187,
      z: 80,
      s: 55,
      sc: 17,
    },
    lc: 0,
    rt: 1677480290,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.461,
      io_66: 13461,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677480293,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2304016,
      x: 30.4319499,
      c: 186,
      z: 76,
      s: 68,
      sc: 17,
    },
    lc: 0,
    rt: 1677480294,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.475,
      io_66: 13475,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480296,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2299033,
      x: 30.4319066,
      c: 176,
      z: 75,
      s: 71,
      sc: 17,
    },
    lc: 0,
    rt: 1677480297,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.439,
      io_66: 13439,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677480299,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2293266,
      x: 30.4320983,
      c: 166,
      z: 78,
      s: 77,
      sc: 17,
    },
    lc: 0,
    rt: 1677480300,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.461,
      io_66: 13461,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480313,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.226615,
      x: 30.433575,
      c: 164,
      z: 86,
      s: 78,
      sc: 17,
    },
    lc: 0,
    rt: 1677480314,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.497,
      io_66: 13497,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480318,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2257516,
      x: 30.4339883,
      c: 172,
      z: 87,
      s: 67,
      sc: 17,
    },
    lc: 0,
    rt: 1677480319,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.494,
      io_66: 13494,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480321,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2252483,
      x: 30.434055,
      c: 182,
      z: 88,
      s: 66,
      sc: 17,
    },
    lc: 0,
    rt: 1677480322,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.469,
      io_66: 13469,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480336,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2225333,
      x: 30.4337766,
      c: 172,
      z: 80,
      s: 65,
      sc: 17,
    },
    lc: 0,
    rt: 1677480337,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.352,
      io_66: 13352,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480342,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.22165,
      x: 30.434015,
      c: 175,
      z: 84,
      s: 54,
      sc: 17,
    },
    lc: 0,
    rt: 1677480343,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.401,
      io_66: 13401,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480347,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2209483,
      x: 30.4339916,
      c: 187,
      z: 83,
      s: 57,
      sc: 17,
    },
    lc: 0,
    rt: 1677480348,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.4,
      io_66: 13400,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480357,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2194983,
      x: 30.433355,
      c: 198,
      z: 73,
      s: 60,
      sc: 17,
    },
    lc: 0,
    rt: 1677480358,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.345,
      io_66: 13345,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480360,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.219065,
      x: 30.4330033,
      c: 208,
      z: 69,
      s: 60,
      sc: 17,
    },
    lc: 0,
    rt: 1677480361,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.385,
      io_66: 13385,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480378,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2167583,
      x: 30.4298416,
      c: 215,
      z: 64,
      s: 69,
      sc: 17,
    },
    lc: 0,
    rt: 1677480379,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.42,
      io_66: 13420,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480392,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.21463,
      x: 30.4270016,
      c: 202,
      z: 67,
      s: 74,
      sc: 16,
    },
    lc: 0,
    rt: 1677480393,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.419,
      io_66: 13419,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480395,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2140916,
      x: 30.4266766,
      c: 189,
      z: 66,
      s: 71,
      sc: 16,
    },
    lc: 0,
    rt: 1677480396,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.401,
      io_66: 13401,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677480399,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2133616,
      x: 30.4266,
      c: 179,
      z: 65,
      s: 82,
      sc: 17,
    },
    lc: 0,
    rt: 1677480400,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.423,
      io_66: 13423,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677480410,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.21092,
      x: 30.4266099,
      c: 180,
      z: 61,
      s: 93,
      sc: 17,
    },
    lc: 0,
    rt: 1677480411,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.439,
      io_66: 13439,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480416,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2094249,
      x: 30.4265416,
      c: 180,
      z: 60,
      s: 103,
      sc: 17,
    },
    lc: 0,
    rt: 1677480417,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.458,
      io_66: 13458,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480424,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2073983,
      x: 30.4264633,
      c: 181,
      z: 56,
      s: 90,
      sc: 17,
    },
    lc: 0,
    rt: 1677480425,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.392,
      io_66: 13392,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480428,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2064633,
      x: 30.4264333,
      c: 179,
      z: 53,
      s: 100,
      sc: 16,
    },
    lc: 0,
    rt: 1677480429,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.383,
      io_66: 13383,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480433,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2051599,
      x: 30.4264149,
      c: 180,
      z: 52,
      s: 111,
      sc: 16,
    },
    lc: 0,
    rt: 1677480434,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.384,
      io_66: 13384,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480443,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2022449,
      x: 30.4264016,
      c: 177,
      z: 56,
      s: 114,
      sc: 16,
    },
    lc: 0,
    rt: 1677480444,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.464,
      io_66: 13464,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480450,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.2003033,
      x: 30.4264183,
      c: 187,
      z: 56,
      s: 105,
      sc: 17,
    },
    lc: 0,
    rt: 1677480452,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.455,
      io_66: 13455,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480454,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1993316,
      x: 30.42592,
      c: 197,
      z: 58,
      s: 91,
      sc: 17,
    },
    lc: 0,
    rt: 1677480455,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.405,
      io_66: 13405,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480457,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1987366,
      x: 30.425425,
      c: 208,
      z: 56,
      s: 87,
      sc: 17,
    },
    lc: 0,
    rt: 1677480459,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.443,
      io_66: 13443,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677480460,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.198235,
      x: 30.42463,
      c: 222,
      z: 54,
      s: 86,
      sc: 17,
    },
    lc: 0,
    rt: 1677480463,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.405,
      io_66: 13405,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677480463,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1977766,
      x: 30.4235566,
      c: 230,
      z: 52,
      s: 96,
      sc: 17,
    },
    lc: 0,
    rt: 1677480465,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.4,
      io_66: 13400,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677480474,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1961016,
      x: 30.4194533,
      c: 231,
      z: 53,
      s: 84,
      sc: 17,
    },
    lc: 0,
    rt: 1677480475,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.402,
      io_66: 13402,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480484,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1948516,
      x: 30.4164333,
      c: 219,
      z: 55,
      s: 76,
      sc: 17,
    },
    lc: 0,
    rt: 1677480485,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.445,
      io_66: 13445,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480486,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1945416,
      x: 30.4159983,
      c: 206,
      z: 55,
      s: 75,
      sc: 17,
    },
    lc: 0,
    rt: 1677480488,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.426,
      io_66: 13426,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677480491,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.193685,
      x: 30.4153416,
      c: 201,
      z: 56,
      s: 65,
      sc: 17,
    },
    lc: 0,
    rt: 1677480492,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.379,
      io_66: 13379,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480509,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1910033,
      x: 30.4131933,
      c: 201,
      z: 58,
      s: 73,
      sc: 17,
    },
    lc: 0,
    rt: 1677480510,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.833,
      io_66: 13833,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480520,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1891933,
      x: 30.4117716,
      c: 201,
      z: 61,
      s: 59,
      sc: 17,
    },
    lc: 0,
    rt: 1677480521,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.762,
      io_66: 13762,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480525,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.188615,
      x: 30.4111316,
      c: 213,
      z: 61,
      s: 48,
      sc: 17,
    },
    lc: 0,
    rt: 1677480529,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.698,
      io_66: 13698,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480527,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.18844,
      x: 30.410835,
      c: 225,
      z: 59,
      s: 46,
      sc: 17,
    },
    lc: 0,
    rt: 1677480529,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.775,
      io_66: 13775,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480529,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1882849,
      x: 30.4104666,
      c: 235,
      z: 58,
      s: 48,
      sc: 16,
    },
    lc: 0,
    rt: 1677480530,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.749,
      io_66: 13749,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677480540,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1874483,
      x: 30.4079366,
      c: 236,
      z: 54,
      s: 58,
      sc: 16,
    },
    lc: 0,
    rt: 1677480541,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.792,
      io_66: 13792,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480543,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1871633,
      x: 30.4071683,
      c: 235,
      z: 55,
      s: 68,
      sc: 16,
    },
    lc: 0,
    rt: 1677480544,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.784,
      io_66: 13784,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677480549,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1865816,
      x: 30.4052866,
      c: 238,
      z: 55,
      s: 78,
      sc: 17,
    },
    lc: 0,
    rt: 1677480550,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.799,
      io_66: 13799,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480563,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1853466,
      x: 30.4003566,
      c: 244,
      z: 47,
      s: 69,
      sc: 16,
    },
    lc: 0,
    rt: 1677480565,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.795,
      io_66: 13795,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480579,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.184135,
      x: 30.3952866,
      c: 244,
      z: 41,
      s: 75,
      sc: 17,
    },
    lc: 0,
    rt: 1677480581,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.813,
      io_66: 13813,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480595,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.18294,
      x: 30.39008,
      c: 246,
      z: 34,
      s: 65,
      sc: 17,
    },
    lc: 0,
    rt: 1677480597,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.809,
      io_66: 13809,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480607,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.182185,
      x: 30.3865716,
      c: 247,
      z: 34,
      s: 55,
      sc: 17,
    },
    lc: 0,
    rt: 1677480609,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.818,
      io_66: 13818,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480616,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1816133,
      x: 30.3842816,
      c: 236,
      z: 43,
      s: 60,
      sc: 17,
    },
    lc: 0,
    rt: 1677480618,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.815,
      io_66: 13815,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480619,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1813216,
      x: 30.38357,
      c: 226,
      z: 45,
      s: 63,
      sc: 17,
    },
    lc: 0,
    rt: 1677480621,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.782,
      io_66: 13782,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677480625,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1806733,
      x: 30.38242,
      c: 223,
      z: 49,
      s: 46,
      sc: 17,
    },
    lc: 0,
    rt: 1677480627,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.719,
      io_66: 13719,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480628,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1804583,
      x: 30.3819816,
      c: 234,
      z: 51,
      s: 37,
      sc: 17,
    },
    lc: 0,
    rt: 1677480630,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.788,
      io_66: 13788,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480631,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1803283,
      x: 30.38146,
      c: 245,
      z: 52,
      s: 38,
      sc: 17,
    },
    lc: 0,
    rt: 1677480633,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.77,
      io_66: 13770,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480657,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1794383,
      x: 30.3761633,
      c: 252,
      z: 55,
      s: 41,
      sc: 17,
    },
    lc: 0,
    rt: 1677480659,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.793,
      io_66: 13793,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480675,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.178975,
      x: 30.372715,
      c: 264,
      z: 56,
      s: 41,
      sc: 17,
    },
    lc: 0,
    rt: 1677480677,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.798,
      io_66: 13798,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480677,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1789716,
      x: 30.3723316,
      c: 278,
      z: 56,
      s: 42,
      sc: 17,
    },
    lc: 0,
    rt: 1677480679,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.744,
      io_66: 13744,
      pwr_int: 3.923,
      io_67: 3923,
      io_68: 0,
    },
  },
  {
    t: 1677480687,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.17929,
      x: 30.37007,
      c: 288,
      z: 56,
      s: 49,
      sc: 17,
    },
    lc: 0,
    rt: 1677480689,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.787,
      io_66: 13787,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480696,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.17959,
      x: 30.36797,
      c: 277,
      z: 52,
      s: 47,
      sc: 17,
    },
    lc: 0,
    rt: 1677480698,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.787,
      io_66: 13787,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480698,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.17961,
      x: 30.3674483,
      c: 265,
      z: 53,
      s: 48,
      sc: 17,
    },
    lc: 0,
    rt: 1677480700,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.749,
      io_66: 13749,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677480700,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1795633,
      x: 30.3670016,
      c: 251,
      z: 53,
      s: 48,
      sc: 17,
    },
    lc: 0,
    rt: 1677480702,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.778,
      io_66: 13778,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677480706,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1792783,
      x: 30.3657516,
      c: 241,
      z: 49,
      s: 44,
      sc: 17,
    },
    lc: 0,
    rt: 1677480708,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.791,
      io_66: 13791,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480710,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1790533,
      x: 30.3650283,
      c: 231,
      z: 50,
      s: 41,
      sc: 17,
    },
    lc: 0,
    rt: 1677480712,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.781,
      io_66: 13781,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480719,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.17851,
      x: 30.3637416,
      c: 231,
      z: 51,
      s: 28,
      sc: 17,
    },
    lc: 0,
    rt: 1677480721,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.609,
      io_66: 13609,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480722,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1784466,
      x: 30.363555,
      c: 247,
      z: 50,
      s: 18,
      sc: 17,
    },
    lc: 0,
    rt: 1677480723,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.582,
      io_66: 13582,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677480723,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1784383,
      x: 30.3634683,
      c: 265,
      z: 50,
      s: 16,
      sc: 17,
    },
    lc: 0,
    rt: 1677480723,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.587,
      io_66: 13587,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677480725,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1784433,
      x: 30.3633116,
      c: 276,
      z: 50,
      s: 14,
      sc: 17,
    },
    lc: 0,
    rt: 1677480726,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.69,
      io_66: 13690,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677480726,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.178445,
      x: 30.3632516,
      c: 264,
      z: 51,
      s: 13,
      sc: 17,
    },
    lc: 0,
    rt: 1677480727,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.673,
      io_66: 13673,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677480727,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1784416,
      x: 30.3631916,
      c: 254,
      z: 51,
      s: 11,
      sc: 17,
    },
    lc: 0,
    rt: 1677480728,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.626,
      io_66: 13626,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677480729,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1784166,
      x: 30.3630966,
      c: 240,
      z: 52,
      s: 10,
      sc: 17,
    },
    lc: 0,
    rt: 1677480730,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.64,
      io_66: 13640,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677480730,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1783966,
      x: 30.3630633,
      c: 225,
      z: 52,
      s: 10,
      sc: 17,
    },
    lc: 0,
    rt: 1677480731,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.63,
      io_66: 13630,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480735,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1783483,
      x: 30.3629566,
      c: 0,
      z: 52,
      s: 0,
      sc: 17,
    },
    lc: 0,
    rt: 1677480737,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 13.715,
      io_66: 13715,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480884,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1783,
      x: 30.3629083,
      c: 222,
      z: 55,
      s: 8,
      sc: 17,
    },
    lc: 0,
    rt: 1677480885,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.29,
      io_66: 14290,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480887,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1782433,
      x: 30.362815,
      c: 212,
      z: 55,
      s: 9,
      sc: 17,
    },
    lc: 0,
    rt: 1677480888,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.033,
      io_66: 14033,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480888,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.17822,
      x: 30.36279,
      c: 199,
      z: 56,
      s: 11,
      sc: 17,
    },
    lc: 0,
    rt: 1677480889,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.973,
      io_66: 13973,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677480889,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1781933,
      x: 30.3627766,
      c: 187,
      z: 56,
      s: 10,
      sc: 17,
    },
    lc: 0,
    rt: 1677480890,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.939,
      io_66: 13939,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677480892,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1781483,
      x: 30.3627516,
      c: 0,
      z: 56,
      s: 0,
      sc: 17,
    },
    lc: 0,
    rt: 1677480894,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.929,
      io_66: 13929,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480905,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1780466,
      x: 30.36262,
      c: 220,
      z: 56,
      s: 16,
      sc: 17,
    },
    lc: 0,
    rt: 1677480906,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.921,
      io_66: 13921,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480908,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.17793,
      x: 30.3624116,
      c: 229,
      z: 55,
      s: 26,
      sc: 17,
    },
    lc: 0,
    rt: 1677480909,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.939,
      io_66: 13939,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677480921,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1773633,
      x: 30.3611316,
      c: 232,
      z: 55,
      s: 15,
      sc: 17,
    },
    lc: 0,
    rt: 1677480922,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.828,
      io_66: 13828,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480924,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1773283,
      x: 30.36104,
      c: 0,
      z: 55,
      s: 0,
      sc: 17,
    },
    lc: 0,
    rt: 1677480927,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.782,
      io_66: 13782,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480931,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1772916,
      x: 30.3609033,
      c: 238,
      z: 54,
      s: 13,
      sc: 17,
    },
    lc: 0,
    rt: 1677480932,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.914,
      io_66: 13914,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480937,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1771466,
      x: 30.360555,
      c: 219,
      z: 52,
      s: 18,
      sc: 17,
    },
    lc: 0,
    rt: 1677480938,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.908,
      io_66: 13908,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480938,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.177105,
      x: 30.3605083,
      c: 207,
      z: 52,
      s: 17,
      sc: 17,
    },
    lc: 0,
    rt: 1677480939,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.918,
      io_66: 13918,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677480939,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.177055,
      x: 30.3604866,
      c: 182,
      z: 52,
      s: 23,
      sc: 17,
    },
    lc: 0,
    rt: 1677480940,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.898,
      io_66: 13898,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677480940,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1769866,
      x: 30.3605033,
      c: 167,
      z: 52,
      s: 28,
      sc: 17,
    },
    lc: 0,
    rt: 1677480941,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.896,
      io_66: 13896,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677480941,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1769033,
      x: 30.36056,
      c: 156,
      z: 52,
      s: 36,
      sc: 17,
    },
    lc: 0,
    rt: 1677480942,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.9,
      io_66: 13900,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677480946,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1763816,
      x: 30.3610683,
      c: 153,
      z: 54,
      s: 48,
      sc: 17,
    },
    lc: 0,
    rt: 1677480947,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.921,
      io_66: 13921,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480951,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1758533,
      x: 30.361515,
      c: 165,
      z: 54,
      s: 42,
      sc: 17,
    },
    lc: 0,
    rt: 1677480952,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.921,
      io_66: 13921,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480952,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1757533,
      x: 30.3615233,
      c: 177,
      z: 53,
      s: 40,
      sc: 17,
    },
    lc: 0,
    rt: 1677480953,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.877,
      io_66: 13877,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677480953,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1756483,
      x: 30.3615033,
      c: 191,
      z: 54,
      s: 38,
      sc: 17,
    },
    lc: 0,
    rt: 1677480954,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.865,
      io_66: 13865,
      pwr_int: 3.923,
      io_67: 3923,
      io_68: 0,
    },
  },
  {
    t: 1677480954,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.175555,
      x: 30.3614483,
      c: 204,
      z: 55,
      s: 38,
      sc: 17,
    },
    lc: 0,
    rt: 1677480955,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.876,
      io_66: 13876,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677480955,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1754883,
      x: 30.3613616,
      c: 216,
      z: 54,
      s: 39,
      sc: 17,
    },
    lc: 0,
    rt: 1677480956,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.888,
      io_66: 13888,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480956,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1754099,
      x: 30.3612366,
      c: 229,
      z: 55,
      s: 40,
      sc: 17,
    },
    lc: 0,
    rt: 1677480957,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.862,
      io_66: 13862,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677480957,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1753583,
      x: 30.3610366,
      c: 242,
      z: 55,
      s: 40,
      sc: 17,
    },
    lc: 0,
    rt: 1677480958,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.848,
      io_66: 13848,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677480958,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1753166,
      x: 30.36085,
      c: 252,
      z: 55,
      s: 41,
      sc: 17,
    },
    lc: 0,
    rt: 1677480959,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.869,
      io_66: 13869,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677480959,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.175305,
      x: 30.3606399,
      c: 263,
      z: 55,
      s: 42,
      sc: 17,
    },
    lc: 0,
    rt: 1677480960,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.876,
      io_66: 13876,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480962,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1753083,
      x: 30.3599016,
      c: 272,
      z: 56,
      s: 53,
      sc: 17,
    },
    lc: 0,
    rt: 1677480963,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.898,
      io_66: 13898,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677480978,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1754783,
      x: 30.3556116,
      c: 284,
      z: 55,
      s: 48,
      sc: 17,
    },
    lc: 0,
    rt: 1677480979,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.919,
      io_66: 13919,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480980,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.17558,
      x: 30.3552233,
      c: 305,
      z: 55,
      s: 48,
      sc: 17,
    },
    lc: 0,
    rt: 1677480981,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.882,
      io_66: 13882,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677480981,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.17567,
      x: 30.3550466,
      c: 316,
      z: 54,
      s: 51,
      sc: 17,
    },
    lc: 0,
    rt: 1677480982,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.894,
      io_66: 13894,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480982,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1757616,
      x: 30.3548916,
      c: 327,
      z: 54,
      s: 48,
      sc: 17,
    },
    lc: 0,
    rt: 1677480983,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.886,
      io_66: 13886,
      pwr_int: 3.922,
      io_67: 3922,
      io_68: 0,
    },
  },
  {
    t: 1677480983,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1758766,
      x: 30.3548333,
      c: 338,
      z: 54,
      s: 45,
      sc: 17,
    },
    lc: 0,
    rt: 1677480984,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.892,
      io_66: 13892,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480984,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.175985,
      x: 30.3547916,
      c: 354,
      z: 53,
      s: 44,
      sc: 17,
    },
    lc: 0,
    rt: 1677480985,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.89,
      io_66: 13890,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677480985,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1760916,
      x: 30.354805,
      c: 6,
      z: 51,
      s: 45,
      sc: 17,
    },
    lc: 0,
    rt: 1677480986,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.899,
      io_66: 13899,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480987,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1763283,
      x: 30.354975,
      c: 26,
      z: 50,
      s: 48,
      sc: 17,
    },
    lc: 0,
    rt: 1677480988,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.899,
      io_66: 13899,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677480988,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1764233,
      x: 30.3550883,
      c: 37,
      z: 49,
      s: 47,
      sc: 17,
    },
    lc: 0,
    rt: 1677480989,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.908,
      io_66: 13908,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677480989,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.17652,
      x: 30.3552716,
      c: 53,
      z: 48,
      s: 46,
      sc: 17,
    },
    lc: 0,
    rt: 1677480990,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.882,
      io_66: 13882,
      pwr_int: 3.923,
      io_67: 3923,
      io_68: 0,
    },
  },
  {
    t: 1677480990,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1765716,
      x: 30.3555116,
      c: 66,
      z: 47,
      s: 47,
      sc: 17,
    },
    lc: 0,
    rt: 1677480991,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.898,
      io_66: 13898,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480991,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1766083,
      x: 30.3557533,
      c: 78,
      z: 47,
      s: 47,
      sc: 17,
    },
    lc: 0,
    rt: 1677480992,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.901,
      io_66: 13901,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480992,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1766149,
      x: 30.3559733,
      c: 90,
      z: 47,
      s: 46,
      sc: 17,
    },
    lc: 0,
    rt: 1677480993,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.894,
      io_66: 13894,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677480993,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.17659,
      x: 30.35622,
      c: 103,
      z: 45,
      s: 47,
      sc: 17,
    },
    lc: 0,
    rt: 1677480994,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.887,
      io_66: 13887,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480994,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.17655,
      x: 30.3564516,
      c: 116,
      z: 45,
      s: 47,
      sc: 17,
    },
    lc: 0,
    rt: 1677480995,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.887,
      io_66: 13887,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677480995,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1764783,
      x: 30.3566383,
      c: 129,
      z: 45,
      s: 46,
      sc: 17,
    },
    lc: 0,
    rt: 1677480996,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.878,
      io_66: 13878,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677480996,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1763833,
      x: 30.3568083,
      c: 141,
      z: 44,
      s: 43,
      sc: 17,
    },
    lc: 0,
    rt: 1677480997,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.84,
      io_66: 13840,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677480998,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1762266,
      x: 30.3570116,
      c: 159,
      z: 45,
      s: 33,
      sc: 17,
    },
    lc: 0,
    rt: 1677481000,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.808,
      io_66: 13808,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677481000,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1760866,
      x: 30.3570816,
      c: 168,
      z: 45,
      s: 23,
      sc: 17,
    },
    lc: 0,
    rt: 1677481001,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.839,
      io_66: 13839,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677481002,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.175955,
      x: 30.3570883,
      c: 180,
      z: 43,
      s: 28,
      sc: 17,
    },
    lc: 0,
    rt: 1677481004,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.89,
      io_66: 13890,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677481004,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.17578,
      x: 30.3570733,
      c: 184,
      z: 43,
      s: 38,
      sc: 17,
    },
    lc: 0,
    rt: 1677481006,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.899,
      io_66: 13899,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677481007,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1754466,
      x: 30.3569783,
      c: 188,
      z: 42,
      s: 49,
      sc: 17,
    },
    lc: 0,
    rt: 1677481008,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.864,
      io_66: 13864,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677481010,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1750433,
      x: 30.356835,
      c: 188,
      z: 43,
      s: 59,
      sc: 17,
    },
    lc: 0,
    rt: 1677481011,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.9,
      io_66: 13900,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481015,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1742133,
      x: 30.35663,
      c: 188,
      z: 44,
      s: 71,
      sc: 17,
    },
    lc: 0,
    rt: 1677481016,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.845,
      io_66: 13845,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481018,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1736233,
      x: 30.3564683,
      c: 186,
      z: 43,
      s: 85,
      sc: 17,
    },
    lc: 0,
    rt: 1677481020,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.839,
      io_66: 13839,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677481022,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.17271,
      x: 30.35624,
      c: 188,
      z: 42,
      s: 95,
      sc: 17,
    },
    lc: 0,
    rt: 1677481023,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.855,
      io_66: 13855,
      pwr_int: 3.931,
      io_67: 3931,
      io_68: 0,
    },
  },
  {
    t: 1677481030,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.17074,
      x: 30.355695,
      c: 188,
      z: 37,
      s: 105,
      sc: 17,
    },
    lc: 0,
    rt: 1677481031,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.936,
      io_66: 13936,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481041,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1678083,
      x: 30.3548383,
      c: 188,
      z: 32,
      s: 105,
      sc: 17,
    },
    lc: 0,
    rt: 1677481043,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.887,
      io_66: 13887,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481052,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1649616,
      x: 30.3540899,
      c: 188,
      z: 32,
      s: 103,
      sc: 17,
    },
    lc: 0,
    rt: 1677481054,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.89,
      io_66: 13890,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677481063,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1620666,
      x: 30.35327,
      c: 187,
      z: 33,
      s: 107,
      sc: 17,
    },
    lc: 0,
    rt: 1677481065,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.908,
      io_66: 13908,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481072,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.15974,
      x: 30.35305,
      c: 177,
      z: 30,
      s: 104,
      sc: 17,
    },
    lc: 0,
    rt: 1677481073,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.908,
      io_66: 13908,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481082,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.157165,
      x: 30.3537499,
      c: 167,
      z: 30,
      s: 105,
      sc: 17,
    },
    lc: 0,
    rt: 1677481085,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.911,
      io_66: 13911,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481093,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1544533,
      x: 30.355575,
      c: 158,
      z: 31,
      s: 103,
      sc: 17,
    },
    lc: 0,
    rt: 1677481095,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.908,
      io_66: 13908,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481104,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1517616,
      x: 30.357925,
      c: 156,
      z: 31,
      s: 112,
      sc: 19,
    },
    lc: 0,
    rt: 1677481106,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.918,
      io_66: 13918,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481114,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1491766,
      x: 30.3600833,
      c: 157,
      z: 28,
      s: 109,
      sc: 18,
    },
    lc: 0,
    rt: 1677481116,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.903,
      io_66: 13903,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481117,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1484616,
      x: 30.36072,
      c: 155,
      z: 29,
      s: 99,
      sc: 18,
    },
    lc: 0,
    rt: 1677481119,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.832,
      io_66: 13832,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677481121,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1476433,
      x: 30.361505,
      c: 150,
      z: 31,
      s: 89,
      sc: 18,
    },
    lc: 0,
    rt: 1677481123,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.852,
      io_66: 13852,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677481129,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.14625,
      x: 30.3634033,
      c: 141,
      z: 31,
      s: 78,
      sc: 18,
    },
    lc: 0,
    rt: 1677481131,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.865,
      io_66: 13865,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481143,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1442666,
      x: 30.3672616,
      c: 133,
      z: 31,
      s: 83,
      sc: 18,
    },
    lc: 0,
    rt: 1677481145,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.886,
      io_66: 13886,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481157,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1423216,
      x: 30.3714083,
      c: 132,
      z: 31,
      s: 82,
      sc: 19,
    },
    lc: 0,
    rt: 1677481159,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.918,
      io_66: 13918,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481165,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.141145,
      x: 30.3738516,
      c: 135,
      z: 32,
      s: 92,
      sc: 19,
    },
    lc: 0,
    rt: 1677481167,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.852,
      io_66: 13852,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677481169,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1404683,
      x: 30.3752533,
      c: 135,
      z: 31,
      s: 102,
      sc: 19,
    },
    lc: 0,
    rt: 1677481172,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.874,
      io_66: 13874,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677481176,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.139145,
      x: 30.3778916,
      c: 136,
      z: 31,
      s: 112,
      sc: 19,
    },
    lc: 0,
    rt: 1677481180,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.763,
      io_66: 13763,
      pwr_int: 3.915,
      io_67: 3915,
      io_68: 0,
    },
  },
  {
    t: 1677481183,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1376783,
      x: 30.38027,
      c: 146,
      z: 28,
      s: 105,
      sc: 18,
    },
    lc: 0,
    rt: 1677481185,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.905,
      io_66: 13905,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481193,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.13542,
      x: 30.382785,
      c: 156,
      z: 27,
      s: 102,
      sc: 18,
    },
    lc: 0,
    rt: 1677481196,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.937,
      io_66: 13937,
      pwr_int: 3.931,
      io_67: 3931,
      io_68: 0,
    },
  },
  {
    t: 1677481200,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1336366,
      x: 30.3840366,
      c: 164,
      z: 31,
      s: 112,
      sc: 18,
    },
    lc: 0,
    rt: 1677481202,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.937,
      io_66: 13937,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481208,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1313733,
      x: 30.3847766,
      c: 175,
      z: 30,
      s: 118,
      sc: 17,
    },
    lc: 0,
    rt: 1677481215,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.954,
      io_66: 13954,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481217,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1286633,
      x: 30.3846516,
      c: 183,
      z: 36,
      s: 118,
      sc: 17,
    },
    lc: 0,
    rt: 1677481219,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.925,
      io_66: 13925,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677481227,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.125725,
      x: 30.3844083,
      c: 181,
      z: 36,
      s: 118,
      sc: 18,
    },
    lc: 0,
    rt: 1677481229,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.951,
      io_66: 13951,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481237,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.122865,
      x: 30.384505,
      c: 178,
      z: 47,
      s: 113,
      sc: 19,
    },
    lc: 0,
    rt: 1677481239,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.954,
      io_66: 13954,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481247,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1200116,
      x: 30.3850983,
      c: 169,
      z: 56,
      s: 112,
      sc: 19,
    },
    lc: 0,
    rt: 1677481249,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.867,
      io_66: 13867,
      pwr_int: 3.918,
      io_67: 3918,
      io_68: 0,
    },
  },
  {
    t: 1677481257,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1172483,
      x: 30.3861,
      c: 173,
      z: 54,
      s: 110,
      sc: 17,
    },
    lc: 0,
    rt: 1677481259,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.945,
      io_66: 13945,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481268,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1144916,
      x: 30.38626,
      c: 178,
      z: 48,
      s: 117,
      sc: 18,
    },
    lc: 0,
    rt: 1677481269,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.958,
      io_66: 13958,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481278,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1115733,
      x: 30.3869849,
      c: 170,
      z: 40,
      s: 114,
      sc: 19,
    },
    lc: 0,
    rt: 1677481279,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.945,
      io_66: 13945,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481286,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1093,
      x: 30.3873383,
      c: 180,
      z: 32,
      s: 118,
      sc: 19,
    },
    lc: 0,
    rt: 1677481287,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.954,
      io_66: 13954,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481293,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.1072716,
      x: 30.3868616,
      c: 190,
      z: 32,
      s: 115,
      sc: 19,
    },
    lc: 0,
    rt: 1677481294,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.954,
      io_66: 13954,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481303,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.104435,
      x: 30.3857133,
      c: 191,
      z: 35,
      s: 110,
      sc: 18,
    },
    lc: 0,
    rt: 1677481305,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.945,
      io_66: 13945,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481314,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.101635,
      x: 30.3845316,
      c: 192,
      z: 37,
      s: 100,
      sc: 18,
    },
    lc: 0,
    rt: 1677481315,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.947,
      io_66: 13947,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481323,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0995333,
      x: 30.3831416,
      c: 203,
      z: 36,
      s: 98,
      sc: 19,
    },
    lc: 0,
    rt: 1677481324,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.95,
      io_66: 13950,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481330,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.098,
      x: 30.3814866,
      c: 213,
      z: 33,
      s: 97,
      sc: 19,
    },
    lc: 0,
    rt: 1677481331,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.924,
      io_66: 13924,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481338,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0965183,
      x: 30.3791566,
      c: 223,
      z: 33,
      s: 92,
      sc: 19,
    },
    lc: 0,
    rt: 1677481339,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.947,
      io_66: 13947,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481350,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.094675,
      x: 30.3748233,
      c: 232,
      z: 29,
      s: 98,
      sc: 19,
    },
    lc: 0,
    rt: 1677481351,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.936,
      io_66: 13936,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481362,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0927916,
      x: 30.3704716,
      c: 225,
      z: 31,
      s: 92,
      sc: 19,
    },
    lc: 0,
    rt: 1677481363,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.915,
      io_66: 13915,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481375,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.09063,
      x: 30.3665816,
      c: 218,
      z: 30,
      s: 87,
      sc: 19,
    },
    lc: 0,
    rt: 1677481376,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.926,
      io_66: 13926,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481388,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0882933,
      x: 30.3634883,
      c: 210,
      z: 22,
      s: 74,
      sc: 19,
    },
    lc: 0,
    rt: 1677481390,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.858,
      io_66: 13858,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481392,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0877066,
      x: 30.3628183,
      c: 208,
      z: 22,
      s: 62,
      sc: 19,
    },
    lc: 0,
    rt: 1677481393,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.797,
      io_66: 13797,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677481395,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0873316,
      x: 30.3624433,
      c: 206,
      z: 22,
      s: 50,
      sc: 19,
    },
    lc: 0,
    rt: 1677481396,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.845,
      io_66: 13845,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677481407,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0860933,
      x: 30.3613933,
      c: 200,
      z: 15,
      s: 40,
      sc: 19,
    },
    lc: 0,
    rt: 1677481408,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.852,
      io_66: 13852,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481416,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0853033,
      x: 30.3608133,
      c: 201,
      z: 16,
      s: 30,
      sc: 18,
    },
    lc: 0,
    rt: 1677481418,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.789,
      io_66: 13789,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481421,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0850266,
      x: 30.3605949,
      c: 200,
      z: 16,
      s: 18,
      sc: 18,
    },
    lc: 0,
    rt: 1677481422,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.783,
      io_66: 13783,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677481432,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0844516,
      x: 30.36012,
      c: 201,
      z: 16,
      s: 31,
      sc: 18,
    },
    lc: 0,
    rt: 1677481433,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.878,
      io_66: 13878,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481443,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.08367,
      x: 30.3594616,
      c: 202,
      z: 13,
      s: 21,
      sc: 19,
    },
    lc: 0,
    rt: 1677481444,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.768,
      io_66: 13768,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481445,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0835966,
      x: 30.3593983,
      c: 203,
      z: 12,
      s: 10,
      sc: 18,
    },
    lc: 0,
    rt: 1677481446,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.736,
      io_66: 13736,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677481447,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0835783,
      x: 30.3593816,
      c: 0,
      z: 12,
      s: 0,
      sc: 18,
    },
    lc: 0,
    rt: 1677481449,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.727,
      io_66: 13727,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677481479,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0835016,
      x: 30.3593166,
      c: 202,
      z: 12,
      s: 11,
      sc: 18,
    },
    lc: 0,
    rt: 1677481480,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.844,
      io_66: 13844,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481483,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0833516,
      x: 30.3592,
      c: 200,
      z: 12,
      s: 21,
      sc: 18,
    },
    lc: 0,
    rt: 1677481484,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.828,
      io_66: 13828,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481486,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0831533,
      x: 30.359045,
      c: 202,
      z: 12,
      s: 35,
      sc: 18,
    },
    lc: 0,
    rt: 1677481487,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.813,
      io_66: 13813,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677481491,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0827583,
      x: 30.3587099,
      c: 199,
      z: 12,
      s: 23,
      sc: 18,
    },
    lc: 0,
    rt: 1677481492,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.673,
      io_66: 13673,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481493,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0826766,
      x: 30.3586566,
      c: 201,
      z: 13,
      s: 9,
      sc: 18,
    },
    lc: 0,
    rt: 1677481494,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.634,
      io_66: 13634,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677481528,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0822983,
      x: 30.35836,
      c: 176,
      z: 16,
      s: 18,
      sc: 18,
    },
    lc: 0,
    rt: 1677481530,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.741,
      io_66: 13741,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481529,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.08225,
      x: 30.3583883,
      c: 152,
      z: 16,
      s: 20,
      sc: 18,
    },
    lc: 0,
    rt: 1677481530,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.739,
      io_66: 13739,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677481530,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0822083,
      x: 30.3584566,
      c: 126,
      z: 16,
      s: 21,
      sc: 18,
    },
    lc: 0,
    rt: 1677481531,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.747,
      io_66: 13747,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677481531,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0821783,
      x: 30.35855,
      c: 115,
      z: 16,
      s: 22,
      sc: 18,
    },
    lc: 0,
    rt: 1677481532,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.769,
      io_66: 13769,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677481539,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0819566,
      x: 30.3594833,
      c: 131,
      z: 21,
      s: 11,
      sc: 19,
    },
    lc: 0,
    rt: 1677481540,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.622,
      io_66: 13622,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481541,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.08194,
      x: 30.3595116,
      c: 0,
      z: 22,
      s: 0,
      sc: 19,
    },
    lc: 0,
    rt: 1677481542,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.665,
      io_66: 13665,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677481543,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.081895,
      x: 30.35951,
      c: 192,
      z: 22,
      s: 9,
      sc: 19,
    },
    lc: 0,
    rt: 1677481544,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.76,
      io_66: 13760,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677481548,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0817933,
      x: 30.3594683,
      c: 203,
      z: 23,
      s: 6,
      sc: 19,
    },
    lc: 0,
    rt: 1677481550,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.67,
      io_66: 13670,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481549,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0817783,
      x: 30.359445,
      c: 232,
      z: 23,
      s: 8,
      sc: 19,
    },
    lc: 0,
    rt: 1677481550,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.764,
      io_66: 13764,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481550,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.08177,
      x: 30.3594083,
      c: 258,
      z: 23,
      s: 7,
      sc: 19,
    },
    lc: 0,
    rt: 1677481551,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.654,
      io_66: 13654,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677481850,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.08177,
      x: 30.3594083,
      c: 0,
      z: 23,
      s: 0,
      sc: 19,
    },
    lc: 0,
    rt: 1677481852,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 12.384,
      io_66: 12384,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481964,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0817633,
      x: 30.35947,
      c: 333,
      z: 22,
      s: 6,
      sc: 20,
    },
    lc: 0,
    rt: 1677481967,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.188,
      io_66: 14188,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481965,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.08178,
      x: 30.3594583,
      c: 343,
      z: 23,
      s: 6,
      sc: 20,
    },
    lc: 0,
    rt: 1677481967,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.21,
      io_66: 14210,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481966,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0818016,
      x: 30.35946,
      c: 5,
      z: 23,
      s: 10,
      sc: 20,
    },
    lc: 0,
    rt: 1677481967,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.199,
      io_66: 14199,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481967,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0818266,
      x: 30.3594716,
      c: 19,
      z: 23,
      s: 8,
      sc: 20,
    },
    lc: 0,
    rt: 1677481968,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.162,
      io_66: 14162,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677481998,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0819749,
      x: 30.3596066,
      c: 342,
      z: 22,
      s: 11,
      sc: 20,
    },
    lc: 0,
    rt: 1677481999,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.844,
      io_66: 13844,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677481999,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.082,
      x: 30.3595766,
      c: 310,
      z: 23,
      s: 12,
      sc: 19,
    },
    lc: 0,
    rt: 1677482000,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.798,
      io_66: 13798,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677482001,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0820349,
      x: 30.3594766,
      c: 296,
      z: 22,
      s: 10,
      sc: 20,
    },
    lc: 0,
    rt: 1677482002,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.695,
      io_66: 13695,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677482006,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0820733,
      x: 30.3593266,
      c: 284,
      z: 23,
      s: 6,
      sc: 20,
    },
    lc: 0,
    rt: 1677482007,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.398,
      io_66: 13398,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482011,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0821033,
      x: 30.3591666,
      c: 300,
      z: 24,
      s: 12,
      sc: 20,
    },
    lc: 0,
    rt: 1677482012,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.368,
      io_66: 13368,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482017,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0822333,
      x: 30.3585733,
      c: 266,
      z: 21,
      s: 14,
      sc: 19,
    },
    lc: 0,
    rt: 1677482018,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.269,
      io_66: 13269,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482019,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0822283,
      x: 30.3585083,
      c: 0,
      z: 21,
      s: 0,
      sc: 19,
    },
    lc: 0,
    rt: 1677482021,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.274,
      io_66: 13274,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677482023,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0821866,
      x: 30.3583383,
      c: 227,
      z: 20,
      s: 16,
      sc: 19,
    },
    lc: 0,
    rt: 1677482025,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.38,
      io_66: 13380,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677482024,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0821099,
      x: 30.3582233,
      c: 205,
      z: 20,
      s: 22,
      sc: 19,
    },
    lc: 0,
    rt: 1677482025,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.385,
      io_66: 13385,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482027,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0818983,
      x: 30.3580216,
      c: 201,
      z: 19,
      s: 34,
      sc: 20,
    },
    lc: 0,
    rt: 1677482028,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.394,
      io_66: 13394,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677482033,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.081365,
      x: 30.3575466,
      c: 201,
      z: 19,
      s: 45,
      sc: 19,
    },
    lc: 0,
    rt: 1677482035,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.455,
      io_66: 13455,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482038,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.08075,
      x: 30.3570516,
      c: 202,
      z: 17,
      s: 55,
      sc: 19,
    },
    lc: 0,
    rt: 1677482039,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.425,
      io_66: 13425,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482053,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0789366,
      x: 30.3555866,
      c: 202,
      z: 15,
      s: 45,
      sc: 19,
    },
    lc: 0,
    rt: 1677482054,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.442,
      io_66: 13442,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482061,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0781166,
      x: 30.3549083,
      c: 205,
      z: 15,
      s: 31,
      sc: 20,
    },
    lc: 0,
    rt: 1677482062,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.3,
      io_66: 13300,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482065,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.07781,
      x: 30.354635,
      c: 201,
      z: 15,
      s: 41,
      sc: 19,
    },
    lc: 0,
    rt: 1677482066,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.436,
      io_66: 13436,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482067,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0775833,
      x: 30.3544883,
      c: 198,
      z: 15,
      s: 51,
      sc: 19,
    },
    lc: 0,
    rt: 1677482069,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.387,
      io_66: 13387,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677482086,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.07506,
      x: 30.3524516,
      c: 202,
      z: 13,
      s: 57,
      sc: 19,
    },
    lc: 0,
    rt: 1677482094,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.424,
      io_66: 13424,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482105,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0724583,
      x: 30.35032,
      c: 202,
      z: 17,
      s: 58,
      sc: 19,
    },
    lc: 0,
    rt: 1677482106,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.427,
      io_66: 13427,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482115,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0712,
      x: 30.3493083,
      c: 202,
      z: 17,
      s: 44,
      sc: 19,
    },
    lc: 0,
    rt: 1677482117,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.42,
      io_66: 13420,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482121,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0705516,
      x: 30.3487849,
      c: 202,
      z: 16,
      s: 55,
      sc: 20,
    },
    lc: 0,
    rt: 1677482122,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.425,
      io_66: 13425,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482139,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0680416,
      x: 30.3467233,
      c: 202,
      z: 19,
      s: 60,
      sc: 20,
    },
    lc: 0,
    rt: 1677482140,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.4,
      io_66: 13400,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482144,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0673516,
      x: 30.3462133,
      c: 209,
      z: 18,
      s: 48,
      sc: 20,
    },
    lc: 0,
    rt: 1677482145,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.312,
      io_66: 13312,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482147,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0670916,
      x: 30.3458933,
      c: 210,
      z: 18,
      s: 36,
      sc: 20,
    },
    lc: 0,
    rt: 1677482148,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.333,
      io_66: 13333,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677482154,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0666216,
      x: 30.3452933,
      c: 208,
      z: 17,
      s: 21,
      sc: 20,
    },
    lc: 0,
    rt: 1677482155,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.303,
      io_66: 13303,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482156,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0665549,
      x: 30.3452149,
      c: 206,
      z: 17,
      s: 10,
      sc: 20,
    },
    lc: 0,
    rt: 1677482157,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.256,
      io_66: 13256,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677482158,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.066535,
      x: 30.3452016,
      c: 0,
      z: 16,
      s: 0,
      sc: 20,
    },
    lc: 0,
    rt: 1677482161,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.325,
      io_66: 13325,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677482166,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0664283,
      x: 30.345065,
      c: 207,
      z: 18,
      s: 18,
      sc: 20,
    },
    lc: 0,
    rt: 1677482167,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.419,
      io_66: 13419,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482168,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0663183,
      x: 30.3449699,
      c: 202,
      z: 17,
      s: 28,
      sc: 20,
    },
    lc: 0,
    rt: 1677482169,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.407,
      io_66: 13407,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677482171,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.06608,
      x: 30.3447666,
      c: 202,
      z: 17,
      s: 39,
      sc: 20,
    },
    lc: 0,
    rt: 1677482172,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.402,
      io_66: 13402,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677482180,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0652266,
      x: 30.3440416,
      c: 210,
      z: 17,
      s: 25,
      sc: 20,
    },
    lc: 0,
    rt: 1677482182,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.224,
      io_66: 13224,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482182,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0651466,
      x: 30.3439183,
      c: 223,
      z: 17,
      s: 17,
      sc: 20,
    },
    lc: 0,
    rt: 1677482183,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.25,
      io_66: 13250,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677482185,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.06507,
      x: 30.343765,
      c: 212,
      z: 18,
      s: 12,
      sc: 20,
    },
    lc: 0,
    rt: 1677482189,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.399,
      io_66: 13399,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677482187,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.065025,
      x: 30.34371,
      c: 201,
      z: 20,
      s: 9,
      sc: 20,
    },
    lc: 0,
    rt: 1677482189,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.314,
      io_66: 13314,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482199,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0648433,
      x: 30.3436033,
      c: 190,
      z: 19,
      s: 9,
      sc: 20,
    },
    lc: 0,
    rt: 1677482201,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.397,
      io_66: 13397,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482203,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0646966,
      x: 30.3435516,
      c: 193,
      z: 18,
      s: 19,
      sc: 20,
    },
    lc: 0,
    rt: 1677482204,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.383,
      io_66: 13383,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482214,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0640133,
      x: 30.3432216,
      c: 206,
      z: 20,
      s: 29,
      sc: 20,
    },
    lc: 0,
    rt: 1677482215,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.384,
      io_66: 13384,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482217,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.063815,
      x: 30.3429633,
      c: 218,
      z: 19,
      s: 33,
      sc: 20,
    },
    lc: 0,
    rt: 1677482219,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.374,
      io_66: 13374,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677482223,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.063435,
      x: 30.3423683,
      c: 208,
      z: 19,
      s: 29,
      sc: 20,
    },
    lc: 0,
    rt: 1677482225,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.404,
      io_66: 13404,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482228,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0630933,
      x: 30.3421033,
      c: 198,
      z: 17,
      s: 28,
      sc: 20,
    },
    lc: 0,
    rt: 1677482230,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.376,
      io_66: 13376,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482233,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0627833,
      x: 30.341945,
      c: 182,
      z: 16,
      s: 21,
      sc: 19,
    },
    lc: 0,
    rt: 1677482241,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.32,
      io_66: 13320,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482235,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0626833,
      x: 30.34197,
      c: 165,
      z: 16,
      s: 19,
      sc: 20,
    },
    lc: 0,
    rt: 1677482241,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.337,
      io_66: 13337,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482236,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.06264,
      x: 30.3420016,
      c: 154,
      z: 17,
      s: 17,
      sc: 20,
    },
    lc: 0,
    rt: 1677482241,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.367,
      io_66: 13367,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482237,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0626033,
      x: 30.34206,
      c: 137,
      z: 16,
      s: 19,
      sc: 20,
    },
    lc: 0,
    rt: 1677482241,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.381,
      io_66: 13381,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482238,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0625716,
      x: 30.342135,
      c: 123,
      z: 16,
      s: 19,
      sc: 20,
    },
    lc: 0,
    rt: 1677482241,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.375,
      io_66: 13375,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482240,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0625283,
      x: 30.3423216,
      c: 110,
      z: 16,
      s: 20,
      sc: 20,
    },
    lc: 0,
    rt: 1677482242,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.353,
      io_66: 13353,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677482242,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0625,
      x: 30.3425,
      c: 98,
      z: 17,
      s: 17,
      sc: 20,
    },
    lc: 0,
    rt: 1677482248,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.348,
      io_66: 13348,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677482243,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0624983,
      x: 30.34259,
      c: 81,
      z: 17,
      s: 18,
      sc: 20,
    },
    lc: 0,
    rt: 1677482248,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.366,
      io_66: 13366,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482244,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0625166,
      x: 30.3426816,
      c: 66,
      z: 18,
      s: 18,
      sc: 20,
    },
    lc: 0,
    rt: 1677482248,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.367,
      io_66: 13367,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482245,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0625466,
      x: 30.3427766,
      c: 54,
      z: 18,
      s: 20,
      sc: 19,
    },
    lc: 0,
    rt: 1677482252,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.367,
      io_66: 13367,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482246,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0625866,
      x: 30.3428516,
      c: 36,
      z: 18,
      s: 21,
      sc: 19,
    },
    lc: 0,
    rt: 1677482252,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.36,
      io_66: 13360,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482247,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0626383,
      x: 30.3429133,
      c: 26,
      z: 18,
      s: 24,
      sc: 19,
    },
    lc: 0,
    rt: 1677482252,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.375,
      io_66: 13375,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482264,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0636883,
      x: 30.34389,
      c: 38,
      z: 20,
      s: 23,
      sc: 19,
    },
    lc: 0,
    rt: 1677482265,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.251,
      io_66: 13251,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482269,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0638716,
      x: 30.34417,
      c: 26,
      z: 19,
      s: 17,
      sc: 19,
    },
    lc: 0,
    rt: 1677482270,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.389,
      io_66: 13389,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482270,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0639116,
      x: 30.3442,
      c: 5,
      z: 19,
      s: 17,
      sc: 20,
    },
    lc: 0,
    rt: 1677482272,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.389,
      io_66: 13389,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482271,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.063955,
      x: 30.3442,
      c: 352,
      z: 19,
      s: 18,
      sc: 20,
    },
    lc: 0,
    rt: 1677482272,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.37,
      io_66: 13370,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677482272,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.064,
      x: 30.3441766,
      c: 342,
      z: 19,
      s: 18,
      sc: 19,
    },
    lc: 0,
    rt: 1677482273,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.343,
      io_66: 13343,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677482275,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0641066,
      x: 30.3440666,
      c: 332,
      z: 19,
      s: 14,
      sc: 20,
    },
    lc: 0,
    rt: 1677482276,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.336,
      io_66: 13336,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482279,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0642133,
      x: 30.3439699,
      c: 345,
      z: 19,
      s: 6,
      sc: 20,
    },
    lc: 0,
    rt: 1677482283,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.281,
      io_66: 13281,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677482282,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0642899,
      x: 30.343945,
      c: 1,
      z: 18,
      s: 13,
      sc: 20,
    },
    lc: 0,
    rt: 1677482283,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.355,
      io_66: 13355,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482284,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0643633,
      x: 30.3439733,
      c: 15,
      z: 18,
      s: 16,
      sc: 20,
    },
    lc: 0,
    rt: 1677482286,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.384,
      io_66: 13384,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677482286,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0644733,
      x: 30.3440416,
      c: 19,
      z: 17,
      s: 27,
      sc: 20,
    },
    lc: 0,
    rt: 1677482287,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.373,
      io_66: 13373,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677482289,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0647133,
      x: 30.3442233,
      c: 18,
      z: 17,
      s: 38,
      sc: 20,
    },
    lc: 0,
    rt: 1677482290,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.371,
      io_66: 13371,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482291,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.064935,
      x: 30.3443883,
      c: 20,
      z: 17,
      s: 48,
      sc: 20,
    },
    lc: 0,
    rt: 1677482293,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.367,
      io_66: 13367,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677482297,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0656816,
      x: 30.34496,
      c: 20,
      z: 21,
      s: 58,
      sc: 20,
    },
    lc: 0,
    rt: 1677482299,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.401,
      io_66: 13401,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482316,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0682483,
      x: 30.3470516,
      c: 22,
      z: 23,
      s: 53,
      sc: 20,
    },
    lc: 0,
    rt: 1677482318,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.406,
      io_66: 13406,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482338,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0707583,
      x: 30.3491216,
      c: 21,
      z: 24,
      s: 50,
      sc: 20,
    },
    lc: 0,
    rt: 1677482340,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.4,
      io_66: 13400,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482348,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0718566,
      x: 30.349975,
      c: 22,
      z: 23,
      s: 40,
      sc: 20,
    },
    lc: 0,
    rt: 1677482350,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.358,
      io_66: 13358,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482350,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0720033,
      x: 30.3501116,
      c: 23,
      z: 23,
      s: 30,
      sc: 20,
    },
    lc: 0,
    rt: 1677482351,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.234,
      io_66: 13234,
      pwr_int: 3.923,
      io_67: 3923,
      io_68: 0,
    },
  },
  {
    t: 1677482368,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0731,
      x: 30.3510016,
      c: 22,
      z: 22,
      s: 18,
      sc: 20,
    },
    lc: 0,
    rt: 1677482370,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.298,
      io_66: 13298,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482375,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.07339,
      x: 30.3512916,
      c: 23,
      z: 22,
      s: 28,
      sc: 20,
    },
    lc: 0,
    rt: 1677482377,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.404,
      io_66: 13404,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482380,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0737883,
      x: 30.3516083,
      c: 21,
      z: 22,
      s: 40,
      sc: 20,
    },
    lc: 0,
    rt: 1677482382,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.416,
      io_66: 13416,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482384,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0742316,
      x: 30.3519616,
      c: 22,
      z: 22,
      s: 50,
      sc: 19,
    },
    lc: 0,
    rt: 1677482386,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.404,
      io_66: 13404,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677482405,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0768016,
      x: 30.3540733,
      c: 21,
      z: 20,
      s: 46,
      sc: 19,
    },
    lc: 0,
    rt: 1677482407,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.423,
      io_66: 13423,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482413,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0776283,
      x: 30.3547183,
      c: 28,
      z: 22,
      s: 29,
      sc: 19,
    },
    lc: 0,
    rt: 1677482415,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.279,
      io_66: 13279,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482414,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0776733,
      x: 30.3548099,
      c: 55,
      z: 21,
      s: 22,
      sc: 19,
    },
    lc: 0,
    rt: 1677482416,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.319,
      io_66: 13319,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482416,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0776916,
      x: 30.3549016,
      c: 81,
      z: 21,
      s: 20,
      sc: 20,
    },
    lc: 0,
    rt: 1677482418,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.349,
      io_66: 13349,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677482417,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0776833,
      x: 30.35501,
      c: 101,
      z: 21,
      s: 24,
      sc: 20,
    },
    lc: 0,
    rt: 1677482420,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.405,
      io_66: 13405,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677482419,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0776333,
      x: 30.3552766,
      c: 112,
      z: 20,
      s: 33,
      sc: 19,
    },
    lc: 0,
    rt: 1677482420,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.415,
      io_66: 13415,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677482427,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0773816,
      x: 30.356535,
      c: 111,
      z: 19,
      s: 19,
      sc: 19,
    },
    lc: 0,
    rt: 1677482430,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.309,
      io_66: 13309,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482429,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.07736,
      x: 30.3566416,
      c: 113,
      z: 19,
      s: 6,
      sc: 19,
    },
    lc: 0,
    rt: 1677482431,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.342,
      io_66: 13342,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482434,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0773083,
      x: 30.3568633,
      c: 114,
      z: 18,
      s: 16,
      sc: 20,
    },
    lc: 0,
    rt: 1677482435,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.41,
      io_66: 13410,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482439,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.077225,
      x: 30.35729,
      c: 111,
      z: 19,
      s: 26,
      sc: 20,
    },
    lc: 0,
    rt: 1677482440,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.406,
      io_66: 13406,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482444,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0770733,
      x: 30.3580283,
      c: 113,
      z: 19,
      s: 38,
      sc: 20,
    },
    lc: 0,
    rt: 1677482445,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.396,
      io_66: 13396,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482471,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.076015,
      x: 30.3631266,
      c: 112,
      z: 24,
      s: 35,
      sc: 17,
    },
    lc: 0,
    rt: 1677482472,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.404,
      io_66: 13404,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482477,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.075795,
      x: 30.3641666,
      c: 112,
      z: 24,
      s: 30,
      sc: 17,
    },
    lc: 0,
    rt: 1677482487,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 42,
      pwr_ext: 13.348,
      io_66: 13348,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482486,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0755816,
      x: 30.3652066,
      c: 111,
      z: 24,
      s: 20,
      sc: 16,
    },
    lc: 0,
    rt: 1677482488,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.318,
      io_66: 13318,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482494,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0754566,
      x: 30.3657449,
      c: 127,
      z: 26,
      s: 12,
      sc: 19,
    },
    lc: 0,
    rt: 1677482495,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.237,
      io_66: 13237,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482495,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0754366,
      x: 30.36578,
      c: 146,
      z: 27,
      s: 7,
      sc: 19,
    },
    lc: 0,
    rt: 1677482496,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.259,
      io_66: 13259,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677482530,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0752616,
      x: 30.365805,
      c: 106,
      z: 25,
      s: 6,
      sc: 19,
    },
    lc: 0,
    rt: 1677482531,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.379,
      io_66: 13379,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482531,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0752516,
      x: 30.3658466,
      c: 116,
      z: 25,
      s: 8,
      sc: 19,
    },
    lc: 0,
    rt: 1677482532,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.379,
      io_66: 13379,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677482532,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0752416,
      x: 30.3658983,
      c: 106,
      z: 25,
      s: 9,
      sc: 19,
    },
    lc: 0,
    rt: 1677482533,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.355,
      io_66: 13355,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677482535,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0752149,
      x: 30.36604,
      c: 117,
      z: 25,
      s: 10,
      sc: 19,
    },
    lc: 0,
    rt: 1677482536,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.358,
      io_66: 13358,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482544,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0751233,
      x: 30.36637,
      c: 0,
      z: 26,
      s: 0,
      sc: 18,
    },
    lc: 0,
    rt: 1677482547,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.257,
      io_66: 13257,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482552,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.075135,
      x: 30.3662783,
      c: 288,
      z: 25,
      s: 9,
      sc: 18,
    },
    lc: 0,
    rt: 1677482553,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.282,
      io_66: 13282,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482566,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0752333,
      x: 30.36586,
      c: 306,
      z: 23,
      s: 6,
      sc: 16,
    },
    lc: 0,
    rt: 1677482567,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.277,
      io_66: 13277,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677482568,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0752483,
      x: 30.3658066,
      c: 320,
      z: 23,
      s: 6,
      sc: 16,
    },
    lc: 0,
    rt: 1677482569,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.257,
      io_66: 13257,
      pwr_int: 3.923,
      io_67: 3923,
      io_68: 0,
    },
  },
  {
    t: 1677482570,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0752666,
      x: 30.36575,
      c: 308,
      z: 23,
      s: 7,
      sc: 16,
    },
    lc: 0,
    rt: 1677482571,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.281,
      io_66: 13281,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677482571,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.075275,
      x: 30.3657249,
      c: 292,
      z: 23,
      s: 6,
      sc: 17,
    },
    lc: 0,
    rt: 1677482572,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.265,
      io_66: 13265,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677482871,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0753033,
      x: 30.3656049,
      c: 0,
      z: 24,
      s: 0,
      sc: 17,
    },
    lc: 0,
    rt: 1677482873,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 13.222,
      io_66: 13222,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483148,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0752166,
      x: 30.3654633,
      c: 116,
      z: 16,
      s: 10,
      sc: 20,
    },
    lc: 0,
    rt: 1677483149,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.93,
      io_66: 13930,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483151,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0752016,
      x: 30.365615,
      c: 80,
      z: 16,
      s: 13,
      sc: 19,
    },
    lc: 0,
    rt: 1677483152,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.786,
      io_66: 13786,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677483152,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.07521,
      x: 30.365665,
      c: 62,
      z: 16,
      s: 9,
      sc: 20,
    },
    lc: 0,
    rt: 1677483153,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.801,
      io_66: 13801,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483153,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0752299,
      x: 30.3657099,
      c: 36,
      z: 17,
      s: 10,
      sc: 20,
    },
    lc: 0,
    rt: 1677483154,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.743,
      io_66: 13743,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677483154,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0752583,
      x: 30.36574,
      c: 18,
      z: 17,
      s: 9,
      sc: 20,
    },
    lc: 0,
    rt: 1677483155,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.645,
      io_66: 13645,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677483162,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0754266,
      x: 30.365715,
      c: 304,
      z: 19,
      s: 17,
      sc: 20,
    },
    lc: 0,
    rt: 1677483163,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.782,
      io_66: 13782,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483164,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0754833,
      x: 30.3655533,
      c: 292,
      z: 19,
      s: 18,
      sc: 20,
    },
    lc: 0,
    rt: 1677483165,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.739,
      io_66: 13739,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677483166,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0755333,
      x: 30.36539,
      c: 293,
      z: 19,
      s: 17,
      sc: 20,
    },
    lc: 0,
    rt: 1677483167,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 47,
      pwr_ext: 13.705,
      io_66: 13705,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677483170,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.075695,
      x: 30.3648733,
      c: 291,
      z: 22,
      s: 28,
      sc: 20,
    },
    lc: 0,
    rt: 1677483171,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.781,
      io_66: 13781,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483209,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.07677,
      x: 30.3597766,
      c: 292,
      z: 22,
      s: 36,
      sc: 19,
    },
    lc: 0,
    rt: 1677483210,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.747,
      io_66: 13747,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483213,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0769149,
      x: 30.3590716,
      c: 292,
      z: 22,
      s: 38,
      sc: 20,
    },
    lc: 0,
    rt: 1677483214,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 2,
      io_254: 61,
      pwr_ext: 13.746,
      io_66: 13746,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677483220,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.077135,
      x: 30.35788,
      c: 292,
      z: 19,
      s: 27,
      sc: 20,
    },
    lc: 0,
    rt: 1677483222,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.646,
      io_66: 13646,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483227,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0772983,
      x: 30.35714,
      c: 292,
      z: 18,
      s: 17,
      sc: 20,
    },
    lc: 0,
    rt: 1677483228,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.714,
      io_66: 13714,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483231,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0773883,
      x: 30.3567466,
      c: 291,
      z: 17,
      s: 29,
      sc: 19,
    },
    lc: 0,
    rt: 1677483232,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.73,
      io_66: 13730,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677483241,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.077685,
      x: 30.3553383,
      c: 291,
      z: 16,
      s: 19,
      sc: 19,
    },
    lc: 0,
    rt: 1677483242,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.569,
      io_66: 13569,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483245,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0777283,
      x: 30.3551433,
      c: 0,
      z: 16,
      s: 0,
      sc: 19,
    },
    lc: 0,
    rt: 1677483247,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.593,
      io_66: 13593,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483310,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.07778,
      x: 30.3550116,
      c: 316,
      z: 17,
      s: 16,
      sc: 21,
    },
    lc: 0,
    rt: 1677483311,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.645,
      io_66: 13645,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483311,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0778149,
      x: 30.354965,
      c: 335,
      z: 17,
      s: 16,
      sc: 21,
    },
    lc: 0,
    rt: 1677483313,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.647,
      io_66: 13647,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677483312,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0778583,
      x: 30.354935,
      c: 353,
      z: 17,
      s: 19,
      sc: 21,
    },
    lc: 0,
    rt: 1677483313,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.653,
      io_66: 13653,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677483313,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0779133,
      x: 30.3549483,
      c: 21,
      z: 17,
      s: 23,
      sc: 21,
    },
    lc: 0,
    rt: 1677483314,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.668,
      io_66: 13668,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677483316,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.078125,
      x: 30.35512,
      c: 22,
      z: 18,
      s: 33,
      sc: 20,
    },
    lc: 0,
    rt: 1677483318,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.717,
      io_66: 13717,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483320,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0784766,
      x: 30.3554016,
      c: 21,
      z: 22,
      s: 45,
      sc: 19,
    },
    lc: 0,
    rt: 1677483321,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.693,
      io_66: 13693,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677483339,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0806566,
      x: 30.3571716,
      c: 21,
      z: 20,
      s: 35,
      sc: 20,
    },
    lc: 0,
    rt: 1677483341,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.604,
      io_66: 13604,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483342,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0808483,
      x: 30.3573283,
      c: 22,
      z: 20,
      s: 21,
      sc: 20,
    },
    lc: 0,
    rt: 1677483344,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.58,
      io_66: 13580,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677483344,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0809216,
      x: 30.3573883,
      c: 22,
      z: 21,
      s: 10,
      sc: 20,
    },
    lc: 0,
    rt: 1677483345,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.583,
      io_66: 13583,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677483345,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0809216,
      x: 30.3573883,
      c: 0,
      z: 21,
      s: 0,
      sc: 20,
    },
    lc: 0,
    rt: 1677483348,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.631,
      io_66: 13631,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677483354,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0809916,
      x: 30.35745,
      c: 22,
      z: 20,
      s: 10,
      sc: 21,
    },
    lc: 0,
    rt: 1677483355,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.723,
      io_66: 13723,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483358,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0811483,
      x: 30.3575733,
      c: 22,
      z: 21,
      s: 22,
      sc: 21,
    },
    lc: 0,
    rt: 1677483359,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.721,
      io_66: 13721,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483366,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0816533,
      x: 30.35798,
      c: 22,
      z: 21,
      s: 35,
      sc: 21,
    },
    lc: 0,
    rt: 1677483367,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.692,
      io_66: 13692,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483381,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.08308,
      x: 30.359125,
      c: 22,
      z: 22,
      s: 45,
      sc: 20,
    },
    lc: 0,
    rt: 1677483383,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.665,
      io_66: 13665,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483403,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.085635,
      x: 30.3612283,
      c: 23,
      z: 24,
      s: 55,
      sc: 20,
    },
    lc: 0,
    rt: 1677483404,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.672,
      io_66: 13672,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483422,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0881166,
      x: 30.3636183,
      c: 30,
      z: 27,
      s: 61,
      sc: 20,
    },
    lc: 0,
    rt: 1677483423,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.67,
      io_66: 13670,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483429,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0889516,
      x: 30.3647766,
      c: 42,
      z: 28,
      s: 55,
      sc: 20,
    },
    lc: 0,
    rt: 1677483434,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.697,
      io_66: 13697,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483432,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0892033,
      x: 30.36537,
      c: 55,
      z: 29,
      s: 50,
      sc: 20,
    },
    lc: 0,
    rt: 1677483435,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.683,
      io_66: 13683,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483435,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0893933,
      x: 30.3660216,
      c: 67,
      z: 31,
      s: 49,
      sc: 21,
    },
    lc: 0,
    rt: 1677483437,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.644,
      io_66: 13644,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677483449,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0898633,
      x: 30.3695716,
      c: 79,
      z: 24,
      s: 55,
      sc: 21,
    },
    lc: 0,
    rt: 1677483451,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.672,
      io_66: 13672,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483451,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.089875,
      x: 30.370135,
      c: 90,
      z: 22,
      s: 53,
      sc: 21,
    },
    lc: 0,
    rt: 1677483452,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.657,
      io_66: 13657,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677483453,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0898666,
      x: 30.370615,
      c: 101,
      z: 24,
      s: 51,
      sc: 21,
    },
    lc: 0,
    rt: 1677483455,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.653,
      io_66: 13653,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677483455,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0897883,
      x: 30.37114,
      c: 111,
      z: 26,
      s: 52,
      sc: 21,
    },
    lc: 0,
    rt: 1677483456,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.635,
      io_66: 13635,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677483457,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0896733,
      x: 30.371595,
      c: 124,
      z: 26,
      s: 41,
      sc: 21,
    },
    lc: 0,
    rt: 1677483459,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.646,
      io_66: 13646,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677483461,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0893683,
      x: 30.372225,
      c: 135,
      z: 26,
      s: 49,
      sc: 21,
    },
    lc: 0,
    rt: 1677483463,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.69,
      io_66: 13690,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677483464,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0890516,
      x: 30.372765,
      c: 141,
      z: 26,
      s: 60,
      sc: 21,
    },
    lc: 0,
    rt: 1677483466,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.658,
      io_66: 13658,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677483477,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0873816,
      x: 30.3750883,
      c: 148,
      z: 29,
      s: 70,
      sc: 21,
    },
    lc: 0,
    rt: 1677483479,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.671,
      io_66: 13671,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483492,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0850516,
      x: 30.37732,
      c: 158,
      z: 29,
      s: 67,
      sc: 21,
    },
    lc: 0,
    rt: 1677483494,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.765,
      io_66: 13765,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483498,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.084055,
      x: 30.3780833,
      c: 162,
      z: 29,
      s: 80,
      sc: 21,
    },
    lc: 0,
    rt: 1677483500,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.711,
      io_66: 13711,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483502,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0832466,
      x: 30.3785283,
      c: 168,
      z: 25,
      s: 91,
      sc: 20,
    },
    lc: 0,
    rt: 1677483504,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.723,
      io_66: 13723,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677483509,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0816016,
      x: 30.3791416,
      c: 170,
      z: 23,
      s: 101,
      sc: 21,
    },
    lc: 0,
    rt: 1677483511,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.742,
      io_66: 13742,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483520,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.078765,
      x: 30.3799683,
      c: 171,
      z: 20,
      s: 108,
      sc: 21,
    },
    lc: 0,
    rt: 1677483522,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.737,
      io_66: 13737,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483530,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0760033,
      x: 30.3808283,
      c: 171,
      z: 24,
      s: 114,
      sc: 21,
    },
    lc: 0,
    rt: 1677483532,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.75,
      io_66: 13750,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483540,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0731766,
      x: 30.3817433,
      c: 169,
      z: 24,
      s: 115,
      sc: 21,
    },
    lc: 0,
    rt: 1677483542,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.82,
      io_66: 13820,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483550,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0704699,
      x: 30.38278,
      c: 167,
      z: 24,
      s: 112,
      sc: 21,
    },
    lc: 0,
    rt: 1677483552,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.747,
      io_66: 13747,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483557,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0686266,
      x: 30.383675,
      c: 167,
      z: 25,
      s: 102,
      sc: 21,
    },
    lc: 0,
    rt: 1677483559,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.729,
      io_66: 13729,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483569,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0658233,
      x: 30.384975,
      c: 166,
      z: 26,
      s: 104,
      sc: 21,
    },
    lc: 0,
    rt: 1677483570,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.724,
      io_66: 13724,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483580,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0631499,
      x: 30.3863066,
      c: 163,
      z: 25,
      s: 104,
      sc: 21,
    },
    lc: 0,
    rt: 1677483581,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.715,
      io_66: 13715,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483588,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0610649,
      x: 30.3878583,
      c: 156,
      z: 30,
      s: 115,
      sc: 20,
    },
    lc: 0,
    rt: 1677483597,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.726,
      io_66: 13726,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483598,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0584666,
      x: 30.390685,
      c: 146,
      z: 30,
      s: 119,
      sc: 21,
    },
    lc: 0,
    rt: 1677483603,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.697,
      io_66: 13697,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677483607,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0563266,
      x: 30.3941183,
      c: 137,
      z: 27,
      s: 123,
      sc: 21,
    },
    lc: 0,
    rt: 1677483608,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.734,
      io_66: 13734,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483616,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0544016,
      x: 30.3982083,
      c: 129,
      z: 21,
      s: 125,
      sc: 21,
    },
    lc: 0,
    rt: 1677483625,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.743,
      io_66: 13743,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483625,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.052835,
      x: 30.4027933,
      c: 120,
      z: 23,
      s: 121,
      sc: 21,
    },
    lc: 0,
    rt: 1677483626,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.655,
      io_66: 13655,
      pwr_int: 3.92,
      io_67: 3920,
      io_68: 0,
    },
  },
  {
    t: 1677483635,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0515316,
      x: 30.4080016,
      c: 115,
      z: 23,
      s: 117,
      sc: 21,
    },
    lc: 0,
    rt: 1677483637,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.681,
      io_66: 13681,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677483642,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0505716,
      x: 30.4118316,
      c: 115,
      z: 23,
      s: 127,
      sc: 21,
    },
    lc: 0,
    rt: 1677483651,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.734,
      io_66: 13734,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483651,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0493899,
      x: 30.4168949,
      c: 115,
      z: 20,
      s: 121,
      sc: 21,
    },
    lc: 0,
    rt: 1677483652,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.727,
      io_66: 13727,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677483661,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0480966,
      x: 30.4223133,
      c: 115,
      z: 24,
      s: 124,
      sc: 20,
    },
    lc: 0,
    rt: 1677483662,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.74,
      io_66: 13740,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483670,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0467616,
      x: 30.4271716,
      c: 122,
      z: 24,
      s: 120,
      sc: 20,
    },
    lc: 0,
    rt: 1677483671,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.739,
      io_66: 13739,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483680,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0449733,
      x: 30.4318216,
      c: 131,
      z: 30,
      s: 116,
      sc: 20,
    },
    lc: 0,
    rt: 1677483681,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.725,
      io_66: 13725,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483689,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.04308,
      x: 30.4354133,
      c: 141,
      z: 38,
      s: 116,
      sc: 20,
    },
    lc: 0,
    rt: 1677483693,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.708,
      io_66: 13708,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483695,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0416616,
      x: 30.4372666,
      c: 151,
      z: 35,
      s: 114,
      sc: 21,
    },
    lc: 0,
    rt: 1677483696,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.658,
      io_66: 13658,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483702,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.03984,
      x: 30.4387816,
      c: 161,
      z: 32,
      s: 109,
      sc: 21,
    },
    lc: 0,
    rt: 1677483703,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.665,
      io_66: 13665,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677483709,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0380283,
      x: 30.439645,
      c: 171,
      z: 27,
      s: 107,
      sc: 21,
    },
    lc: 0,
    rt: 1677483710,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.682,
      io_66: 13682,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483718,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0355233,
      x: 30.4405016,
      c: 165,
      z: 20,
      s: 117,
      sc: 21,
    },
    lc: 0,
    rt: 1677483719,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.725,
      io_66: 13725,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483724,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.03361,
      x: 30.4418866,
      c: 155,
      z: 18,
      s: 113,
      sc: 20,
    },
    lc: 0,
    rt: 1677483734,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.686,
      io_66: 13686,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483734,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.031145,
      x: 30.4443516,
      c: 153,
      z: 17,
      s: 111,
      sc: 19,
    },
    lc: 0,
    rt: 1677483744,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.674,
      io_66: 13674,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677483745,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0284966,
      x: 30.4470416,
      c: 152,
      z: 16,
      s: 108,
      sc: 19,
    },
    lc: 0,
    rt: 1677483747,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.689,
      io_66: 13689,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677483755,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0260116,
      x: 30.4494683,
      c: 153,
      z: 14,
      s: 108,
      sc: 19,
    },
    lc: 0,
    rt: 1677483761,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.699,
      io_66: 13699,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483765,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0235299,
      x: 30.4519883,
      c: 153,
      z: 13,
      s: 115,
      sc: 20,
    },
    lc: 0,
    rt: 1677483771,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.683,
      io_66: 13683,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483775,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0209383,
      x: 30.4546349,
      c: 151,
      z: 16,
      s: 116,
      sc: 21,
    },
    lc: 0,
    rt: 1677483776,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.69,
      io_66: 13690,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483781,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0194833,
      x: 30.45657,
      c: 141,
      z: 15,
      s: 115,
      sc: 21,
    },
    lc: 0,
    rt: 1677483782,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.785,
      io_66: 13785,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483789,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.017875,
      x: 30.4596866,
      c: 130,
      z: 17,
      s: 108,
      sc: 21,
    },
    lc: 0,
    rt: 1677483791,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.798,
      io_66: 13798,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483799,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0163183,
      x: 30.464355,
      c: 123,
      z: 18,
      s: 116,
      sc: 21,
    },
    lc: 0,
    rt: 1677483802,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.796,
      io_66: 13796,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483807,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0148533,
      x: 30.4680033,
      c: 133,
      z: 18,
      s: 119,
      sc: 21,
    },
    lc: 0,
    rt: 1677483809,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.803,
      io_66: 13803,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483815,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0130733,
      x: 30.4711549,
      c: 143,
      z: 16,
      s: 118,
      sc: 21,
    },
    lc: 0,
    rt: 1677483816,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.798,
      io_66: 13798,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483823,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0110266,
      x: 30.4736366,
      c: 154,
      z: 15,
      s: 120,
      sc: 20,
    },
    lc: 0,
    rt: 1677483825,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.806,
      io_66: 13806,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483832,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0084866,
      x: 30.475485,
      c: 164,
      z: 14,
      s: 119,
      sc: 18,
    },
    lc: 0,
    rt: 1677483834,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.801,
      io_66: 13801,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483839,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0065,
      x: 30.4761916,
      c: 174,
      z: 13,
      s: 114,
      sc: 18,
    },
    lc: 0,
    rt: 1677483841,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.795,
      io_66: 13795,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483849,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.00356,
      x: 30.4762483,
      c: 181,
      z: 11,
      s: 114,
      sc: 18,
    },
    lc: 0,
    rt: 1677483851,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.787,
      io_66: 13787,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483859,
    f: 1,
    tp: 'ud',
    pos: {
      y: 60.0007999,
      x: 30.476195,
      c: 177,
      z: 9,
      s: 111,
      sc: 19,
    },
    lc: 0,
    rt: 1677483860,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.808,
      io_66: 13808,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483869,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9980816,
      x: 30.4768033,
      c: 170,
      z: 11,
      s: 110,
      sc: 19,
    },
    lc: 0,
    rt: 1677483871,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.768,
      io_66: 13768,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483879,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9953633,
      x: 30.4782216,
      c: 162,
      z: 12,
      s: 112,
      sc: 19,
    },
    lc: 0,
    rt: 1677483880,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.826,
      io_66: 13826,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483883,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9943533,
      x: 30.4789166,
      c: 158,
      z: 13,
      s: 96,
      sc: 20,
    },
    lc: 0,
    rt: 1677483884,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.729,
      io_66: 13729,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483895,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9918783,
      x: 30.4812533,
      c: 150,
      z: 12,
      s: 97,
      sc: 20,
    },
    lc: 0,
    rt: 1677483896,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.801,
      io_66: 13801,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483907,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9894883,
      x: 30.48439,
      c: 143,
      z: 10,
      s: 100,
      sc: 20,
    },
    lc: 0,
    rt: 1677483909,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.802,
      io_66: 13802,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483918,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.98738,
      x: 30.4881233,
      c: 133,
      z: 11,
      s: 106,
      sc: 20,
    },
    lc: 0,
    rt: 1677483920,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.801,
      io_66: 13801,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483928,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9855483,
      x: 30.4922883,
      c: 129,
      z: 13,
      s: 112,
      sc: 20,
    },
    lc: 0,
    rt: 1677483930,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.804,
      io_66: 13804,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483938,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.98396,
      x: 30.49677,
      c: 120,
      z: 14,
      s: 108,
      sc: 20,
    },
    lc: 0,
    rt: 1677483940,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.8,
      io_66: 13800,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483945,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9831883,
      x: 30.5001116,
      c: 110,
      z: 16,
      s: 105,
      sc: 20,
    },
    lc: 0,
    rt: 1677483946,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.8,
      io_66: 13800,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483955,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9824466,
      x: 30.5055383,
      c: 102,
      z: 11,
      s: 112,
      sc: 20,
    },
    lc: 0,
    rt: 1677483956,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.815,
      io_66: 13815,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483965,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.981925,
      x: 30.5109083,
      c: 100,
      z: 10,
      s: 107,
      sc: 20,
    },
    lc: 0,
    rt: 1677483967,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.798,
      io_66: 13798,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483976,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9812833,
      x: 30.5165766,
      c: 107,
      z: 19,
      s: 107,
      sc: 19,
    },
    lc: 0,
    rt: 1677483978,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.809,
      io_66: 13809,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483986,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.980285,
      x: 30.52165,
      c: 115,
      z: 24,
      s: 108,
      sc: 20,
    },
    lc: 0,
    rt: 1677483988,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.798,
      io_66: 13798,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677483996,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9789266,
      x: 30.526365,
      c: 121,
      z: 21,
      s: 111,
      sc: 20,
    },
    lc: 0,
    rt: 1677483998,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.781,
      io_66: 13781,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484006,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9775016,
      x: 30.5309666,
      c: 121,
      z: 21,
      s: 104,
      sc: 20,
    },
    lc: 0,
    rt: 1677484008,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.786,
      io_66: 13786,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484017,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9760616,
      x: 30.535555,
      c: 122,
      z: 17,
      s: 95,
      sc: 20,
    },
    lc: 0,
    rt: 1677484019,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.771,
      io_66: 13771,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677484026,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.97491,
      x: 30.5393066,
      c: 121,
      z: 10,
      s: 105,
      sc: 20,
    },
    lc: 0,
    rt: 1677484028,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.771,
      io_66: 13771,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484036,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9733716,
      x: 30.543825,
      c: 128,
      z: 10,
      s: 110,
      sc: 19,
    },
    lc: 0,
    rt: 1677484039,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.786,
      io_66: 13786,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484045,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9716283,
      x: 30.5474583,
      c: 139,
      z: 12,
      s: 116,
      sc: 20,
    },
    lc: 0,
    rt: 1677484047,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.801,
      io_66: 13801,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484053,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.969745,
      x: 30.5501966,
      c: 149,
      z: 15,
      s: 118,
      sc: 20,
    },
    lc: 0,
    rt: 1677484061,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.785,
      io_66: 13785,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484061,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9675616,
      x: 30.5522116,
      c: 159,
      z: 18,
      s: 121,
      sc: 20,
    },
    lc: 0,
    rt: 1677484063,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.765,
      io_66: 13765,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677484069,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.965315,
      x: 30.5534166,
      c: 169,
      z: 17,
      s: 112,
      sc: 20,
    },
    lc: 0,
    rt: 1677484071,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.774,
      io_66: 13774,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484078,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9628916,
      x: 30.5538783,
      c: 180,
      z: 18,
      s: 106,
      sc: 19,
    },
    lc: 0,
    rt: 1677484080,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.785,
      io_66: 13785,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484088,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.960245,
      x: 30.55331,
      c: 191,
      z: 17,
      s: 106,
      sc: 19,
    },
    lc: 0,
    rt: 1677484090,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.787,
      io_66: 13787,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484097,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.958065,
      x: 30.5520116,
      c: 201,
      z: 13,
      s: 107,
      sc: 19,
    },
    lc: 0,
    rt: 1677484099,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.806,
      io_66: 13806,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484103,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9564983,
      x: 30.5506016,
      c: 207,
      z: 15,
      s: 118,
      sc: 20,
    },
    lc: 0,
    rt: 1677484105,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.781,
      io_66: 13781,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484110,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9547366,
      x: 30.5487166,
      c: 207,
      z: 14,
      s: 108,
      sc: 20,
    },
    lc: 0,
    rt: 1677484111,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.704,
      io_66: 13704,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677484122,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9522466,
      x: 30.5459366,
      c: 208,
      z: 14,
      s: 102,
      sc: 19,
    },
    lc: 0,
    rt: 1677484123,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.804,
      io_66: 13804,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484125,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9516033,
      x: 30.5452616,
      c: 208,
      z: 17,
      s: 88,
      sc: 19,
    },
    lc: 0,
    rt: 1677484126,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.683,
      io_66: 13683,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677484130,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9507033,
      x: 30.5442849,
      c: 208,
      z: 17,
      s: 78,
      sc: 19,
    },
    lc: 0,
    rt: 1677484131,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.736,
      io_66: 13736,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484138,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.949365,
      x: 30.5429183,
      c: 206,
      z: 17,
      s: 68,
      sc: 19,
    },
    lc: 0,
    rt: 1677484139,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.74,
      io_66: 13740,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484143,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9486983,
      x: 30.5421133,
      c: 218,
      z: 22,
      s: 60,
      sc: 19,
    },
    lc: 0,
    rt: 1677484144,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.803,
      io_66: 13803,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484146,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9483966,
      x: 30.541495,
      c: 228,
      z: 22,
      s: 61,
      sc: 20,
    },
    lc: 0,
    rt: 1677484147,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.78,
      io_66: 13780,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677484151,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9478933,
      x: 30.5402133,
      c: 231,
      z: 19,
      s: 72,
      sc: 20,
    },
    lc: 0,
    rt: 1677484152,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.779,
      io_66: 13779,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484160,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9469583,
      x: 30.53778,
      c: 232,
      z: 12,
      s: 61,
      sc: 19,
    },
    lc: 0,
    rt: 1677484161,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.747,
      io_66: 13747,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484165,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.946525,
      x: 30.5366466,
      c: 234,
      z: 13,
      s: 49,
      sc: 20,
    },
    lc: 0,
    rt: 1677484167,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.672,
      io_66: 13672,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484168,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.94635,
      x: 30.5361433,
      c: 241,
      z: 13,
      s: 31,
      sc: 20,
    },
    lc: 0,
    rt: 1677484169,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.636,
      io_66: 13636,
      pwr_int: 3.923,
      io_67: 3923,
      io_68: 0,
    },
  },
  {
    t: 1677484170,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9462966,
      x: 30.5359366,
      c: 245,
      z: 14,
      s: 19,
      sc: 20,
    },
    lc: 0,
    rt: 1677484171,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.648,
      io_66: 13648,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677484173,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9462633,
      x: 30.5357566,
      c: 250,
      z: 13,
      s: 9,
      sc: 20,
    },
    lc: 0,
    rt: 1677484174,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.752,
      io_66: 13752,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484177,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9462283,
      x: 30.5355233,
      c: 256,
      z: 12,
      s: 20,
      sc: 20,
    },
    lc: 0,
    rt: 1677484180,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.725,
      io_66: 13725,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484182,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9461866,
      x: 30.5349283,
      c: 265,
      z: 13,
      s: 32,
      sc: 20,
    },
    lc: 0,
    rt: 1677484183,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.67,
      io_66: 13670,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677484187,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.946155,
      x: 30.533945,
      c: 263,
      z: 12,
      s: 43,
      sc: 20,
    },
    lc: 0,
    rt: 1677484188,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.733,
      io_66: 13733,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484190,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9460816,
      x: 30.53318,
      c: 261,
      z: 10,
      s: 57,
      sc: 20,
    },
    lc: 0,
    rt: 1677484191,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.752,
      io_66: 13752,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484194,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9460416,
      x: 30.5319133,
      c: 267,
      z: 10,
      s: 68,
      sc: 20,
    },
    lc: 0,
    rt: 1677484195,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.785,
      io_66: 13785,
      pwr_int: 3.936,
      io_67: 3936,
      io_68: 0,
    },
  },
  {
    t: 1677484210,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9459266,
      x: 30.526225,
      c: 267,
      z: 9,
      s: 67,
      sc: 20,
    },
    lc: 0,
    rt: 1677484214,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.775,
      io_66: 13775,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484227,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.94582,
      x: 30.52051,
      c: 268,
      z: 11,
      s: 70,
      sc: 19,
    },
    lc: 0,
    rt: 1677484228,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.776,
      io_66: 13776,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484243,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9457166,
      x: 30.5149916,
      c: 268,
      z: 13,
      s: 72,
      sc: 20,
    },
    lc: 0,
    rt: 1677484244,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.782,
      io_66: 13782,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484259,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9456283,
      x: 30.50941,
      c: 267,
      z: 16,
      s: 72,
      sc: 20,
    },
    lc: 0,
    rt: 1677484260,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.795,
      io_66: 13795,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484275,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.945535,
      x: 30.5036816,
      c: 267,
      z: 13,
      s: 67,
      sc: 20,
    },
    lc: 0,
    rt: 1677484276,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.745,
      io_66: 13745,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677484288,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.94543,
      x: 30.4995299,
      c: 268,
      z: 9,
      s: 55,
      sc: 16,
    },
    lc: 0,
    rt: 1677484289,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 1,
      io_254: 35,
      pwr_ext: 13.721,
      io_66: 13721,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484290,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9454216,
      x: 30.4990233,
      c: 269,
      z: 11,
      s: 44,
      sc: 17,
    },
    lc: 0,
    rt: 1677484291,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.688,
      io_66: 13688,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677484293,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9454166,
      x: 30.4984183,
      c: 269,
      z: 12,
      s: 34,
      sc: 18,
    },
    lc: 0,
    rt: 1677484294,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.689,
      io_66: 13689,
      pwr_int: 3.937,
      io_67: 3937,
      io_68: 0,
    },
  },
  {
    t: 1677484295,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9454116,
      x: 30.4981433,
      c: 268,
      z: 13,
      s: 19,
      sc: 18,
    },
    lc: 0,
    rt: 1677484296,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.625,
      io_66: 13625,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677484296,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9454066,
      x: 30.4980716,
      c: 272,
      z: 13,
      s: 9,
      sc: 18,
    },
    lc: 0,
    rt: 1677484297,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.602,
      io_66: 13602,
      pwr_int: 3.916,
      io_67: 3916,
      io_68: 0,
    },
  },
  {
    t: 1677484308,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9454116,
      x: 30.4977849,
      c: 267,
      z: 11,
      s: 21,
      sc: 18,
    },
    lc: 0,
    rt: 1677484309,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.778,
      io_66: 13778,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484310,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9454166,
      x: 30.49751,
      c: 269,
      z: 10,
      s: 32,
      sc: 19,
    },
    lc: 0,
    rt: 1677484312,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.755,
      io_66: 13755,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677484313,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.945415,
      x: 30.4969233,
      c: 268,
      z: 10,
      s: 45,
      sc: 18,
    },
    lc: 0,
    rt: 1677484315,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.769,
      io_66: 13769,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677484318,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9453616,
      x: 30.4956433,
      c: 265,
      z: 7,
      s: 57,
      sc: 17,
    },
    lc: 0,
    rt: 1677484319,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.787,
      io_66: 13787,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484320,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9453333,
      x: 30.4950516,
      c: 268,
      z: 5,
      s: 57,
      sc: 18,
    },
    lc: 0,
    rt: 1677484321,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 2,
      io_254: 44,
      pwr_ext: 13.757,
      io_66: 13757,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677484325,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9453216,
      x: 30.493705,
      c: 267,
      z: 5,
      s: 45,
      sc: 18,
    },
    lc: 0,
    rt: 1677484326,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.629,
      io_66: 13629,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484328,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9453233,
      x: 30.4931083,
      c: 268,
      z: 6,
      s: 32,
      sc: 18,
    },
    lc: 0,
    rt: 1677484330,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.643,
      io_66: 13643,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677484331,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9453083,
      x: 30.4927283,
      c: 267,
      z: 6,
      s: 19,
      sc: 20,
    },
    lc: 0,
    rt: 1677484341,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.663,
      io_66: 13663,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677484336,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.945285,
      x: 30.4923849,
      c: 273,
      z: 2,
      s: 9,
      sc: 18,
    },
    lc: 0,
    rt: 1677484344,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.655,
      io_66: 13655,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484392,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9453349,
      x: 30.4919233,
      c: 267,
      z: 3,
      s: 19,
      sc: 20,
    },
    lc: 0,
    rt: 1677484393,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.703,
      io_66: 13703,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484395,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.94533,
      x: 30.4915583,
      c: 268,
      z: 2,
      s: 31,
      sc: 19,
    },
    lc: 0,
    rt: 1677484396,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.692,
      io_66: 13692,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677484398,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9453083,
      x: 30.4909916,
      c: 263,
      z: 3,
      s: 42,
      sc: 19,
    },
    lc: 0,
    rt: 1677484400,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.702,
      io_66: 13702,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677484400,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9452866,
      x: 30.4905133,
      c: 266,
      z: 3,
      s: 52,
      sc: 19,
    },
    lc: 0,
    rt: 1677484401,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.678,
      io_66: 13678,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677484403,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.94526,
      x: 30.489665,
      c: 266,
      z: 2,
      s: 62,
      sc: 19,
    },
    lc: 0,
    rt: 1677484404,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.693,
      io_66: 13693,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677484406,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9452233,
      x: 30.48859,
      c: 267,
      z: 1,
      s: 73,
      sc: 19,
    },
    lc: 0,
    rt: 1677484408,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.693,
      io_66: 13693,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677484412,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.94521,
      x: 30.486485,
      c: 271,
      z: 3,
      s: 63,
      sc: 20,
    },
    lc: 0,
    rt: 1677484413,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.592,
      io_66: 13592,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484421,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9452033,
      x: 30.4838716,
      c: 268,
      z: 2,
      s: 52,
      sc: 20,
    },
    lc: 0,
    rt: 1677484423,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.595,
      io_66: 13595,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484425,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9451866,
      x: 30.482945,
      c: 269,
      z: 2,
      s: 40,
      sc: 17,
    },
    lc: 0,
    rt: 1677484426,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.524,
      io_66: 13524,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677484428,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.945145,
      x: 30.48247,
      c: 266,
      z: 4,
      s: 27,
      sc: 18,
    },
    lc: 0,
    rt: 1677484432,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.528,
      io_66: 13528,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677484432,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.945145,
      x: 30.4820233,
      c: 269,
      z: 4,
      s: 17,
      sc: 18,
    },
    lc: 0,
    rt: 1677484433,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.532,
      io_66: 13532,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484435,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9451433,
      x: 30.4818533,
      c: 268,
      z: 4,
      s: 5,
      sc: 20,
    },
    lc: 0,
    rt: 1677484436,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.552,
      io_66: 13552,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677484441,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.94512,
      x: 30.4816466,
      c: 261,
      z: 4,
      s: 15,
      sc: 20,
    },
    lc: 0,
    rt: 1677484442,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.693,
      io_66: 13693,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484448,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9451016,
      x: 30.4810133,
      c: 271,
      z: 4,
      s: 20,
      sc: 20,
    },
    lc: 0,
    rt: 1677484450,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.7,
      io_66: 13700,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484451,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9450983,
      x: 30.4807866,
      c: 267,
      z: 3,
      s: 7,
      sc: 20,
    },
    lc: 0,
    rt: 1677484452,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.525,
      io_66: 13525,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677484570,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9450883,
      x: 30.4804233,
      c: 271,
      z: 1,
      s: 18,
      sc: 19,
    },
    lc: 0,
    rt: 1677484572,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.671,
      io_66: 13671,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484578,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9450916,
      x: 30.4797633,
      c: 264,
      z: 0,
      s: 5,
      sc: 20,
    },
    lc: 0,
    rt: 1677484580,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.532,
      io_66: 13532,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484701,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.94507,
      x: 30.4793116,
      c: 269,
      z: 0,
      s: 18,
      sc: 20,
    },
    lc: 0,
    rt: 1677484702,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.645,
      io_66: 13645,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484707,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9450683,
      x: 30.4787466,
      c: 0,
      z: -1,
      s: 0,
      sc: 20,
    },
    lc: 0,
    rt: 1677484709,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.51,
      io_66: 13510,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484830,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9450583,
      x: 30.4784866,
      c: 260,
      z: 0,
      s: 18,
      sc: 20,
    },
    lc: 0,
    rt: 1677484831,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.571,
      io_66: 13571,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484832,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9450233,
      x: 30.4782849,
      c: 240,
      z: 0,
      s: 23,
      sc: 20,
    },
    lc: 0,
    rt: 1677484833,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.539,
      io_66: 13539,
      pwr_int: 3.922,
      io_67: 3922,
      io_68: 0,
    },
  },
  {
    t: 1677484833,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9449833,
      x: 30.4781816,
      c: 227,
      z: 0,
      s: 26,
      sc: 20,
    },
    lc: 0,
    rt: 1677484834,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.554,
      io_66: 13554,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677484834,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.944935,
      x: 30.4780933,
      c: 215,
      z: 0,
      s: 26,
      sc: 20,
    },
    lc: 0,
    rt: 1677484835,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.528,
      io_66: 13528,
      pwr_int: 3.919,
      io_67: 3919,
      io_68: 0,
    },
  },
  {
    t: 1677484835,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.944865,
      x: 30.47802,
      c: 201,
      z: 0,
      s: 31,
      sc: 20,
    },
    lc: 0,
    rt: 1677484836,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.593,
      io_66: 13593,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677484836,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.94478,
      x: 30.4779683,
      c: 191,
      z: 0,
      s: 35,
      sc: 20,
    },
    lc: 0,
    rt: 1677484837,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.631,
      io_66: 13631,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677484840,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9443183,
      x: 30.47786,
      c: 186,
      z: -2,
      s: 46,
      sc: 19,
    },
    lc: 0,
    rt: 1677484841,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.673,
      io_66: 13673,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484842,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9440416,
      x: 30.4777833,
      c: 187,
      z: -4,
      s: 58,
      sc: 19,
    },
    lc: 0,
    rt: 1677484843,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.637,
      io_66: 13637,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677484847,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.94312,
      x: 30.4774683,
      c: 189,
      z: -4,
      s: 68,
      sc: 19,
    },
    lc: 0,
    rt: 1677484850,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.688,
      io_66: 13688,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484859,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9409483,
      x: 30.4768183,
      c: 188,
      z: -1,
      s: 78,
      sc: 17,
    },
    lc: 0,
    rt: 1677484861,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.714,
      io_66: 13714,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484873,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9381683,
      x: 30.47592,
      c: 189,
      z: 6,
      s: 77,
      sc: 18,
    },
    lc: 0,
    rt: 1677484875,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.702,
      io_66: 13702,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677484887,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.93548,
      x: 30.4750733,
      c: 189,
      z: 12,
      s: 79,
      sc: 20,
    },
    lc: 0,
    rt: 1677484888,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.745,
      io_66: 13745,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484902,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9327266,
      x: 30.47417,
      c: 188,
      z: 11,
      s: 72,
      sc: 20,
    },
    lc: 0,
    rt: 1677484903,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.759,
      io_66: 13759,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484917,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9300316,
      x: 30.4734116,
      c: 188,
      z: 0,
      s: 72,
      sc: 20,
    },
    lc: 0,
    rt: 1677484918,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.768,
      io_66: 13768,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484923,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.929015,
      x: 30.4731033,
      c: 188,
      z: 0,
      s: 61,
      sc: 20,
    },
    lc: 0,
    rt: 1677484924,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.731,
      io_66: 13731,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484927,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.928455,
      x: 30.4729416,
      c: 188,
      z: 1,
      s: 46,
      sc: 19,
    },
    lc: 0,
    rt: 1677484928,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.653,
      io_66: 13653,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484929,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.928275,
      x: 30.47289,
      c: 188,
      z: 1,
      s: 29,
      sc: 19,
    },
    lc: 0,
    rt: 1677484933,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.602,
      io_66: 13602,
      pwr_int: 3.92,
      io_67: 3920,
      io_68: 0,
    },
  },
  {
    t: 1677484931,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9281733,
      x: 30.4728616,
      c: 188,
      z: 0,
      s: 15,
      sc: 19,
    },
    lc: 0,
    rt: 1677484934,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.647,
      io_66: 13647,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677484933,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9281333,
      x: 30.4728566,
      c: 188,
      z: 0,
      s: 5,
      sc: 19,
    },
    lc: 0,
    rt: 1677484934,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.657,
      io_66: 13657,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484945,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9281183,
      x: 30.4728566,
      c: 191,
      z: 1,
      s: 14,
      sc: 18,
    },
    lc: 0,
    rt: 1677484946,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 26,
      pwr_ext: 13.769,
      io_66: 13769,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484948,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92797,
      x: 30.4728033,
      c: 188,
      z: 0,
      s: 24,
      sc: 18,
    },
    lc: 0,
    rt: 1677484949,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.749,
      io_66: 13749,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677484951,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9277416,
      x: 30.4727316,
      c: 188,
      z: 1,
      s: 39,
      sc: 19,
    },
    lc: 0,
    rt: 1677484952,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.74,
      io_66: 13740,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677484957,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9271,
      x: 30.4724866,
      c: 191,
      z: 2,
      s: 51,
      sc: 19,
    },
    lc: 0,
    rt: 1677484958,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.775,
      io_66: 13775,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484960,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9267183,
      x: 30.4723583,
      c: 187,
      z: 0,
      s: 41,
      sc: 18,
    },
    lc: 0,
    rt: 1677484961,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.557,
      io_66: 13557,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677484962,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9265533,
      x: 30.4723233,
      c: 182,
      z: 0,
      s: 28,
      sc: 18,
    },
    lc: 0,
    rt: 1677484963,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.627,
      io_66: 13627,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677484964,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.926435,
      x: 30.47232,
      c: 185,
      z: 0,
      s: 16,
      sc: 17,
    },
    lc: 0,
    rt: 1677484969,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.609,
      io_66: 13609,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677484966,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9264066,
      x: 30.472315,
      c: 0,
      z: 1,
      s: 0,
      sc: 17,
    },
    lc: 0,
    rt: 1677484970,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.639,
      io_66: 13639,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484988,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9262933,
      x: 30.4722766,
      c: 186,
      z: 2,
      s: 16,
      sc: 18,
    },
    lc: 0,
    rt: 1677484990,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.757,
      io_66: 13757,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677484991,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92614,
      x: 30.47224,
      c: 186,
      z: 2,
      s: 27,
      sc: 18,
    },
    lc: 0,
    rt: 1677484993,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.745,
      io_66: 13745,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677484994,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9258633,
      x: 30.4721633,
      c: 190,
      z: 6,
      s: 38,
      sc: 18,
    },
    lc: 0,
    rt: 1677484995,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.747,
      io_66: 13747,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677484997,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92549,
      x: 30.4720683,
      c: 186,
      z: 4,
      s: 53,
      sc: 19,
    },
    lc: 0,
    rt: 1677484998,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.704,
      io_66: 13704,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677485001,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9249149,
      x: 30.4719383,
      c: 189,
      z: 4,
      s: 65,
      sc: 19,
    },
    lc: 0,
    rt: 1677485003,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.778,
      io_66: 13778,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485007,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9238466,
      x: 30.4716466,
      c: 189,
      z: 5,
      s: 75,
      sc: 19,
    },
    lc: 0,
    rt: 1677485008,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.761,
      io_66: 13761,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485015,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9224933,
      x: 30.4711933,
      c: 189,
      z: 4,
      s: 65,
      sc: 17,
    },
    lc: 0,
    rt: 1677485016,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.715,
      io_66: 13715,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485021,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9216083,
      x: 30.4708633,
      c: 188,
      z: 4,
      s: 50,
      sc: 17,
    },
    lc: 0,
    rt: 1677485023,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.676,
      io_66: 13676,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485023,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.921405,
      x: 30.4708033,
      c: 189,
      z: 4,
      s: 33,
      sc: 17,
    },
    lc: 0,
    rt: 1677485030,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.644,
      io_66: 13644,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677485025,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9212866,
      x: 30.4707366,
      c: 202,
      z: 4,
      s: 19,
      sc: 17,
    },
    lc: 0,
    rt: 1677485030,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.635,
      io_66: 13635,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677485027,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9212316,
      x: 30.47066,
      c: 221,
      z: 3,
      s: 12,
      sc: 18,
    },
    lc: 0,
    rt: 1677485030,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.651,
      io_66: 13651,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677485029,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9212166,
      x: 30.470625,
      c: 0,
      z: 3,
      s: 0,
      sc: 18,
    },
    lc: 0,
    rt: 1677485031,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.644,
      io_66: 13644,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485057,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9211899,
      x: 30.470505,
      c: 231,
      z: 4,
      s: 19,
      sc: 18,
    },
    lc: 0,
    rt: 1677485060,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.752,
      io_66: 13752,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485060,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9210883,
      x: 30.4701833,
      c: 243,
      z: 5,
      s: 32,
      sc: 18,
    },
    lc: 0,
    rt: 1677485061,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.748,
      io_66: 13748,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677485063,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9209433,
      x: 30.4696083,
      c: 240,
      z: 6,
      s: 44,
      sc: 18,
    },
    lc: 0,
    rt: 1677485065,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.711,
      io_66: 13711,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677485069,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92065,
      x: 30.468485,
      c: 227,
      z: 6,
      s: 31,
      sc: 18,
    },
    lc: 0,
    rt: 1677485070,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.67,
      io_66: 13670,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485070,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.920595,
      x: 30.4683899,
      c: 214,
      z: 5,
      s: 27,
      sc: 18,
    },
    lc: 0,
    rt: 1677485071,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.732,
      io_66: 13732,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485071,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9205299,
      x: 30.4683266,
      c: 197,
      z: 5,
      s: 27,
      sc: 18,
    },
    lc: 0,
    rt: 1677485072,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.64,
      io_66: 13640,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677485072,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9204633,
      x: 30.46831,
      c: 180,
      z: 5,
      s: 24,
      sc: 18,
    },
    lc: 0,
    rt: 1677485073,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.605,
      io_66: 13605,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677485073,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.920405,
      x: 30.4683316,
      c: 157,
      z: 5,
      s: 23,
      sc: 18,
    },
    lc: 0,
    rt: 1677485074,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.703,
      io_66: 13703,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677485074,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9203516,
      x: 30.468385,
      c: 144,
      z: 4,
      s: 23,
      sc: 18,
    },
    lc: 0,
    rt: 1677485075,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.674,
      io_66: 13674,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677485075,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9203066,
      x: 30.468465,
      c: 134,
      z: 4,
      s: 22,
      sc: 18,
    },
    lc: 0,
    rt: 1677485076,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.591,
      io_66: 13591,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677485078,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.920215,
      x: 30.46868,
      c: 134,
      z: 3,
      s: 12,
      sc: 18,
    },
    lc: 0,
    rt: 1677485079,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.552,
      io_66: 13552,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677485080,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9202,
      x: 30.4687166,
      c: 0,
      z: 3,
      s: 0,
      sc: 18,
    },
    lc: 0,
    rt: 1677485083,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.629,
      io_66: 13629,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677485104,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9201433,
      x: 30.4689116,
      c: 121,
      z: 7,
      s: 14,
      sc: 17,
    },
    lc: 0,
    rt: 1677485106,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.721,
      io_66: 13721,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485106,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9200916,
      x: 30.4690766,
      c: 122,
      z: 7,
      s: 24,
      sc: 17,
    },
    lc: 0,
    rt: 1677485107,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.743,
      io_66: 13743,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677485112,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.919915,
      x: 30.4697333,
      c: 136,
      z: 9,
      s: 16,
      sc: 18,
    },
    lc: 0,
    rt: 1677485114,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.563,
      io_66: 13563,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485113,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9198816,
      x: 30.4697749,
      c: 155,
      z: 9,
      s: 13,
      sc: 18,
    },
    lc: 0,
    rt: 1677485114,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.549,
      io_66: 13549,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485114,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9198533,
      x: 30.4697883,
      c: 168,
      z: 8,
      s: 8,
      sc: 18,
    },
    lc: 0,
    rt: 1677485115,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.538,
      io_66: 13538,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677485131,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9197566,
      x: 30.4696433,
      c: 238,
      z: 8,
      s: 18,
      sc: 18,
    },
    lc: 0,
    rt: 1677485133,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.752,
      io_66: 13752,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485134,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9196816,
      x: 30.4693083,
      c: 247,
      z: 8,
      s: 34,
      sc: 18,
    },
    lc: 0,
    rt: 1677485136,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.712,
      io_66: 13712,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677485137,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9195583,
      x: 30.4687866,
      c: 244,
      z: 9,
      s: 44,
      sc: 17,
    },
    lc: 0,
    rt: 1677485138,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.73,
      io_66: 13730,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677485139,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.91944,
      x: 30.4683366,
      c: 243,
      z: 7,
      s: 54,
      sc: 17,
    },
    lc: 0,
    rt: 1677485141,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.709,
      io_66: 13709,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677485147,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.918855,
      x: 30.4661066,
      c: 243,
      z: 2,
      s: 64,
      sc: 17,
    },
    lc: 0,
    rt: 1677485152,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.774,
      io_66: 13774,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485165,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9176449,
      x: 30.4609983,
      c: 244,
      z: 6,
      s: 53,
      sc: 18,
    },
    lc: 0,
    rt: 1677485167,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.77,
      io_66: 13770,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485176,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.917035,
      x: 30.4584616,
      c: 243,
      z: 8,
      s: 41,
      sc: 18,
    },
    lc: 0,
    rt: 1677485178,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.667,
      io_66: 13667,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485178,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9169633,
      x: 30.458165,
      c: 244,
      z: 8,
      s: 27,
      sc: 17,
    },
    lc: 0,
    rt: 1677485182,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.54,
      io_66: 13540,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677485181,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9168916,
      x: 30.4578783,
      c: 244,
      z: 9,
      s: 14,
      sc: 17,
    },
    lc: 0,
    rt: 1677485185,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.573,
      io_66: 13573,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485185,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.916855,
      x: 30.4577566,
      c: 0,
      z: 9,
      s: 0,
      sc: 18,
    },
    lc: 0,
    rt: 1677485188,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.548,
      io_66: 13548,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677485201,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.91684,
      x: 30.4576249,
      c: 244,
      z: 7,
      s: 17,
      sc: 17,
    },
    lc: 0,
    rt: 1677485203,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.695,
      io_66: 13695,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485208,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.916655,
      x: 30.456905,
      c: 229,
      z: 7,
      s: 18,
      sc: 17,
    },
    lc: 0,
    rt: 1677485210,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.578,
      io_66: 13578,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485209,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9166216,
      x: 30.4568533,
      c: 205,
      z: 7,
      s: 16,
      sc: 18,
    },
    lc: 0,
    rt: 1677485211,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.657,
      io_66: 13657,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485210,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9165866,
      x: 30.45684,
      c: 170,
      z: 7,
      s: 13,
      sc: 17,
    },
    lc: 0,
    rt: 1677485212,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.677,
      io_66: 13677,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677485211,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9165516,
      x: 30.4568716,
      c: 149,
      z: 7,
      s: 15,
      sc: 18,
    },
    lc: 0,
    rt: 1677485213,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.664,
      io_66: 13664,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677485212,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.916525,
      x: 30.4569333,
      c: 118,
      z: 7,
      s: 15,
      sc: 18,
    },
    lc: 0,
    rt: 1677485214,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.719,
      io_66: 13719,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677485213,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9165216,
      x: 30.4570283,
      c: 90,
      z: 7,
      s: 22,
      sc: 18,
    },
    lc: 0,
    rt: 1677485215,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.764,
      io_66: 13764,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677485214,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9165316,
      x: 30.4571566,
      c: 75,
      z: 8,
      s: 27,
      sc: 18,
    },
    lc: 0,
    rt: 1677485216,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.758,
      io_66: 13758,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677485221,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9167016,
      x: 30.4581333,
      c: 89,
      z: 4,
      s: 26,
      sc: 16,
    },
    lc: 0,
    rt: 1677485223,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.767,
      io_66: 13767,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485222,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9166966,
      x: 30.4582566,
      c: 101,
      z: 4,
      s: 23,
      sc: 17,
    },
    lc: 0,
    rt: 1677485224,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.767,
      io_66: 13767,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485224,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9166716,
      x: 30.4585066,
      c: 89,
      z: 2,
      s: 23,
      sc: 16,
    },
    lc: 0,
    rt: 1677485227,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.763,
      io_66: 13763,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677485225,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9166816,
      x: 30.458625,
      c: 74,
      z: 2,
      s: 24,
      sc: 16,
    },
    lc: 0,
    rt: 1677485227,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.759,
      io_66: 13759,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677485227,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9167333,
      x: 30.4588583,
      c: 64,
      z: 1,
      s: 26,
      sc: 16,
    },
    lc: 0,
    rt: 1677485229,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.735,
      io_66: 13735,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677485243,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9171616,
      x: 30.460725,
      c: 81,
      z: 3,
      s: 11,
      sc: 17,
    },
    lc: 0,
    rt: 1677485244,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 23,
      pwr_ext: 13.541,
      io_66: 13541,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485244,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9171616,
      x: 30.460725,
      c: 0,
      z: 3,
      s: 0,
      sc: 17,
    },
    lc: 0,
    rt: 1677485245,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 23,
      pwr_ext: 13.552,
      io_66: 13552,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677485544,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9171783,
      x: 30.460875,
      c: 0,
      z: 8,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677485545,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 13.224,
      io_66: 13224,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677485844,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9171783,
      x: 30.460875,
      c: 0,
      z: 8,
      s: 0,
      sc: 16,
    },
    lc: 0,
    rt: 1677485845,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 13.049,
      io_66: 13049,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486143,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9172183,
      x: 30.4608966,
      c: 61,
      z: 0,
      s: 12,
      sc: 18,
    },
    lc: 0,
    rt: 1677486145,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.074,
      io_66: 14074,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486146,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9172816,
      x: 30.4611483,
      c: 66,
      z: 0,
      s: 22,
      sc: 18,
    },
    lc: 0,
    rt: 1677486147,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.028,
      io_66: 14028,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677486155,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9175283,
      x: 30.4621816,
      c: 67,
      z: 1,
      s: 11,
      sc: 19,
    },
    lc: 0,
    rt: 1677486157,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.964,
      io_66: 13964,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486157,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9175383,
      x: 30.4622183,
      c: 0,
      z: 1,
      s: 0,
      sc: 19,
    },
    lc: 0,
    rt: 1677486160,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.961,
      io_66: 13961,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677486163,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9175699,
      x: 30.4623449,
      c: 59,
      z: 1,
      s: 11,
      sc: 19,
    },
    lc: 0,
    rt: 1677486164,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.086,
      io_66: 14086,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486165,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9175999,
      x: 30.4624833,
      c: 69,
      z: 1,
      s: 17,
      sc: 19,
    },
    lc: 0,
    rt: 1677486167,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 14.052,
      io_66: 14052,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677486170,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9176716,
      x: 30.4628166,
      c: 66,
      z: 2,
      s: 5,
      sc: 19,
    },
    lc: 0,
    rt: 1677486171,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.645,
      io_66: 13645,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486175,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9177116,
      x: 30.462995,
      c: 65,
      z: 1,
      s: 16,
      sc: 18,
    },
    lc: 0,
    rt: 1677486176,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.756,
      io_66: 13756,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486186,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9179266,
      x: 30.464045,
      c: 65,
      z: 2,
      s: 26,
      sc: 19,
    },
    lc: 0,
    rt: 1677486187,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.741,
      io_66: 13741,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486219,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9189,
      x: 30.4680516,
      c: 33,
      z: 2,
      s: 18,
      sc: 18,
    },
    lc: 0,
    rt: 1677486220,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.63,
      io_66: 13630,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486223,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9190699,
      x: 30.4682666,
      c: 46,
      z: 5,
      s: 26,
      sc: 18,
    },
    lc: 0,
    rt: 1677486226,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.632,
      io_66: 13632,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486228,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9192766,
      x: 30.4687666,
      c: 61,
      z: 7,
      s: 21,
      sc: 18,
    },
    lc: 0,
    rt: 1677486230,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.478,
      io_66: 13478,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486231,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9193316,
      x: 30.4690066,
      c: 60,
      z: 6,
      s: 11,
      sc: 19,
    },
    lc: 0,
    rt: 1677486233,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.468,
      io_66: 13468,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486233,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9193416,
      x: 30.46904,
      c: 0,
      z: 5,
      s: 0,
      sc: 17,
    },
    lc: 0,
    rt: 1677486236,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.486,
      io_66: 13486,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677486303,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9194166,
      x: 30.4691866,
      c: 66,
      z: 8,
      s: 16,
      sc: 19,
    },
    lc: 0,
    rt: 1677486305,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.587,
      io_66: 13587,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486307,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9195183,
      x: 30.4695716,
      c: 62,
      z: 9,
      s: 31,
      sc: 19,
    },
    lc: 0,
    rt: 1677486309,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.57,
      io_66: 13570,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486311,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.919645,
      x: 30.4700916,
      c: 54,
      z: 7,
      s: 21,
      sc: 18,
    },
    lc: 0,
    rt: 1677486313,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.501,
      io_66: 13501,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486312,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.919675,
      x: 30.47016,
      c: 26,
      z: 7,
      s: 16,
      sc: 19,
    },
    lc: 0,
    rt: 1677486314,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.558,
      io_66: 13558,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486313,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9197133,
      x: 30.4701816,
      c: 356,
      z: 6,
      s: 15,
      sc: 18,
    },
    lc: 0,
    rt: 1677486314,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.556,
      io_66: 13556,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677486314,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9197516,
      x: 30.4701633,
      c: 327,
      z: 6,
      s: 17,
      sc: 19,
    },
    lc: 0,
    rt: 1677486315,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.54,
      io_66: 13540,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677486315,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9197766,
      x: 30.4701049,
      c: 292,
      z: 5,
      s: 16,
      sc: 18,
    },
    lc: 0,
    rt: 1677486317,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.477,
      io_66: 13477,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677486316,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9197833,
      x: 30.470035,
      c: 263,
      z: 5,
      s: 14,
      sc: 18,
    },
    lc: 0,
    rt: 1677486317,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.408,
      io_66: 13408,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486317,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9197733,
      x: 30.46997,
      c: 245,
      z: 5,
      s: 14,
      sc: 19,
    },
    lc: 0,
    rt: 1677486318,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.507,
      io_66: 13507,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677486320,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9197516,
      x: 30.4698666,
      c: 0,
      z: 4,
      s: 0,
      sc: 19,
    },
    lc: 0,
    rt: 1677486323,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.514,
      io_66: 13514,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486351,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9196833,
      x: 30.4696016,
      c: 244,
      z: 1,
      s: 18,
      sc: 19,
    },
    lc: 0,
    rt: 1677486353,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.641,
      io_66: 13641,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486353,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9196333,
      x: 30.4693833,
      c: 245,
      z: 2,
      s: 30,
      sc: 19,
    },
    lc: 0,
    rt: 1677486355,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.58,
      io_66: 13580,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677486355,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9195566,
      x: 30.4690466,
      c: 244,
      z: 2,
      s: 41,
      sc: 19,
    },
    lc: 0,
    rt: 1677486356,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.595,
      io_66: 13595,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677486358,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.91941,
      x: 30.468415,
      c: 244,
      z: 3,
      s: 51,
      sc: 19,
    },
    lc: 0,
    rt: 1677486360,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.63,
      io_66: 13630,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486365,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.918985,
      x: 30.4666383,
      c: 243,
      z: 0,
      s: 62,
      sc: 19,
    },
    lc: 0,
    rt: 1677486368,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.663,
      io_66: 13663,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486371,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9185883,
      x: 30.4650566,
      c: 244,
      z: 0,
      s: 48,
      sc: 18,
    },
    lc: 0,
    rt: 1677486373,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.496,
      io_66: 13496,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486375,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.91843,
      x: 30.4642566,
      c: 248,
      z: 2,
      s: 36,
      sc: 19,
    },
    lc: 0,
    rt: 1677486377,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.519,
      io_66: 13519,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486378,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9183383,
      x: 30.4638366,
      c: 245,
      z: 2,
      s: 26,
      sc: 19,
    },
    lc: 0,
    rt: 1677486380,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.49,
      io_66: 13490,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677486381,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.918275,
      x: 30.4635733,
      c: 243,
      z: 2,
      s: 12,
      sc: 19,
    },
    lc: 0,
    rt: 1677486383,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.486,
      io_66: 13486,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677486384,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9182566,
      x: 30.4634999,
      c: 0,
      z: 2,
      s: 0,
      sc: 19,
    },
    lc: 0,
    rt: 1677486388,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.49,
      io_66: 13490,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486408,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9182183,
      x: 30.4633133,
      c: 245,
      z: 4,
      s: 19,
      sc: 19,
    },
    lc: 0,
    rt: 1677486409,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.647,
      io_66: 13647,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486411,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9181399,
      x: 30.46295,
      c: 245,
      z: 4,
      s: 35,
      sc: 19,
    },
    lc: 0,
    rt: 1677486412,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.602,
      io_66: 13602,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677486416,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9179216,
      x: 30.46207,
      c: 241,
      z: 3,
      s: 46,
      sc: 18,
    },
    lc: 0,
    rt: 1677486417,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.651,
      io_66: 13651,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486422,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9175733,
      x: 30.4606533,
      c: 244,
      z: -2,
      s: 58,
      sc: 19,
    },
    lc: 0,
    rt: 1677486423,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.658,
      io_66: 13658,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486431,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.917,
      x: 30.4582916,
      c: 243,
      z: 0,
      s: 47,
      sc: 19,
    },
    lc: 0,
    rt: 1677486435,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.53,
      io_66: 13530,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486434,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.916875,
      x: 30.4577416,
      c: 244,
      z: 0,
      s: 34,
      sc: 19,
    },
    lc: 0,
    rt: 1677486436,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.534,
      io_66: 13534,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486437,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9167916,
      x: 30.4573633,
      c: 245,
      z: 1,
      s: 21,
      sc: 18,
    },
    lc: 0,
    rt: 1677486438,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.497,
      io_66: 13497,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677486440,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.916745,
      x: 30.4571566,
      c: 246,
      z: 2,
      s: 9,
      sc: 19,
    },
    lc: 0,
    rt: 1677486441,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.497,
      io_66: 13497,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486462,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.916705,
      x: 30.4569316,
      c: 244,
      z: 4,
      s: 19,
      sc: 18,
    },
    lc: 0,
    rt: 1677486463,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.639,
      io_66: 13639,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486465,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9166216,
      x: 30.4566133,
      c: 243,
      z: 5,
      s: 33,
      sc: 18,
    },
    lc: 0,
    rt: 1677486466,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.623,
      io_66: 13623,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677486469,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.91647,
      x: 30.4558933,
      c: 244,
      z: 6,
      s: 45,
      sc: 18,
    },
    lc: 0,
    rt: 1677486470,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.646,
      io_66: 13646,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486473,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.916235,
      x: 30.4549816,
      c: 243,
      z: 3,
      s: 55,
      sc: 17,
    },
    lc: 0,
    rt: 1677486474,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.638,
      io_66: 13638,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486492,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.91505,
      x: 30.44989,
      c: 244,
      z: 5,
      s: 63,
      sc: 18,
    },
    lc: 0,
    rt: 1677486493,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.68,
      io_66: 13680,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486510,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.913905,
      x: 30.445005,
      c: 245,
      z: 3,
      s: 59,
      sc: 16,
    },
    lc: 0,
    rt: 1677486511,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.706,
      io_66: 13706,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486520,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9132616,
      x: 30.4422883,
      c: 244,
      z: 0,
      s: 44,
      sc: 19,
    },
    lc: 0,
    rt: 1677486521,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.588,
      io_66: 13588,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486524,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9131033,
      x: 30.4416083,
      c: 242,
      z: 0,
      s: 34,
      sc: 19,
    },
    lc: 0,
    rt: 1677486525,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.563,
      io_66: 13563,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486527,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.91302,
      x: 30.4412416,
      c: 245,
      z: 3,
      s: 22,
      sc: 19,
    },
    lc: 0,
    rt: 1677486528,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.545,
      io_66: 13545,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677486531,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.912945,
      x: 30.4409366,
      c: 244,
      z: 3,
      s: 11,
      sc: 19,
    },
    lc: 0,
    rt: 1677486533,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.579,
      io_66: 13579,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486534,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9129266,
      x: 30.44086,
      c: 0,
      z: 3,
      s: 0,
      sc: 19,
    },
    lc: 0,
    rt: 1677486536,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.6,
      io_66: 13600,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677486540,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.91291,
      x: 30.4407033,
      c: 254,
      z: 5,
      s: 7,
      sc: 19,
    },
    lc: 0,
    rt: 1677486541,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.586,
      io_66: 13586,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486541,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9129083,
      x: 30.4406649,
      c: 244,
      z: 5,
      s: 7,
      sc: 19,
    },
    lc: 0,
    rt: 1677486542,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.543,
      io_66: 13543,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486577,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9128766,
      x: 30.440475,
      c: 244,
      z: 5,
      s: 17,
      sc: 19,
    },
    lc: 0,
    rt: 1677486578,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.697,
      io_66: 13697,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486580,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9128,
      x: 30.4402083,
      c: 242,
      z: 5,
      s: 27,
      sc: 19,
    },
    lc: 0,
    rt: 1677486581,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.678,
      io_66: 13678,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677486588,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9125816,
      x: 30.4392516,
      c: 253,
      z: 0,
      s: 24,
      sc: 19,
    },
    lc: 0,
    rt: 1677486589,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.692,
      io_66: 13692,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486589,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9125716,
      x: 30.4391283,
      c: 264,
      z: 0,
      s: 23,
      sc: 19,
    },
    lc: 0,
    rt: 1677486590,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.694,
      io_66: 13694,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486590,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9125816,
      x: 30.439015,
      c: 289,
      z: 1,
      s: 25,
      sc: 19,
    },
    lc: 0,
    rt: 1677486591,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.681,
      io_66: 13681,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677486591,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.912615,
      x: 30.4389016,
      c: 304,
      z: 1,
      s: 28,
      sc: 19,
    },
    lc: 0,
    rt: 1677486592,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.666,
      io_66: 13666,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677486594,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.91278,
      x: 30.4385,
      c: 308,
      z: 2,
      s: 39,
      sc: 19,
    },
    lc: 0,
    rt: 1677486595,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.661,
      io_66: 13661,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486599,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.91315,
      x: 30.4375833,
      c: 308,
      z: 4,
      s: 49,
      sc: 18,
    },
    lc: 0,
    rt: 1677486600,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.694,
      io_66: 13694,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486603,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9134966,
      x: 30.4367133,
      c: 309,
      z: 6,
      s: 59,
      sc: 18,
    },
    lc: 0,
    rt: 1677486604,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.698,
      io_66: 13698,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486607,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9138833,
      x: 30.435865,
      c: 310,
      z: 7,
      s: 47,
      sc: 19,
    },
    lc: 0,
    rt: 1677486611,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.631,
      io_66: 13631,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486616,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9146533,
      x: 30.4340883,
      c: 311,
      z: 5,
      s: 57,
      sc: 19,
    },
    lc: 0,
    rt: 1677486617,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.649,
      io_66: 13649,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486634,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9163716,
      x: 30.4301833,
      c: 309,
      z: 8,
      s: 69,
      sc: 18,
    },
    lc: 0,
    rt: 1677486646,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.677,
      io_66: 13677,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677486644,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9175116,
      x: 30.4274683,
      c: 310,
      z: 6,
      s: 59,
      sc: 19,
    },
    lc: 0,
    rt: 1677486646,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.649,
      io_66: 13649,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486652,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9182733,
      x: 30.4257683,
      c: 310,
      z: 5,
      s: 46,
      sc: 19,
    },
    lc: 0,
    rt: 1677486653,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.576,
      io_66: 13576,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486653,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9183316,
      x: 30.42564,
      c: 310,
      z: 5,
      s: 36,
      sc: 19,
    },
    lc: 0,
    rt: 1677486654,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.561,
      io_66: 13561,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486654,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9183783,
      x: 30.4255183,
      c: 311,
      z: 5,
      s: 22,
      sc: 19,
    },
    lc: 0,
    rt: 1677486656,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.479,
      io_66: 13479,
      pwr_int: 3.911,
      io_67: 3911,
      io_68: 0,
    },
  },
  {
    t: 1677486656,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9184283,
      x: 30.4254016,
      c: 313,
      z: 6,
      s: 9,
      sc: 19,
    },
    lc: 0,
    rt: 1677486657,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.505,
      io_66: 13505,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677486761,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9184699,
      x: 30.42533,
      c: 314,
      z: 9,
      s: 13,
      sc: 19,
    },
    lc: 0,
    rt: 1677486762,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 25,
      pwr_ext: 13.673,
      io_66: 13673,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486764,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9185716,
      x: 30.4251,
      c: 311,
      z: 9,
      s: 27,
      sc: 19,
    },
    lc: 0,
    rt: 1677486766,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.645,
      io_66: 13645,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677486766,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9186866,
      x: 30.4248566,
      c: 315,
      z: 10,
      s: 39,
      sc: 19,
    },
    lc: 0,
    rt: 1677486767,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.638,
      io_66: 13638,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677486773,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9193233,
      x: 30.4238683,
      c: 324,
      z: 4,
      s: 49,
      sc: 19,
    },
    lc: 0,
    rt: 1677486774,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.66,
      io_66: 13660,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486795,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.921635,
      x: 30.4208033,
      c: 327,
      z: 4,
      s: 52,
      sc: 18,
    },
    lc: 0,
    rt: 1677486796,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.703,
      io_66: 13703,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486803,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9224333,
      x: 30.4196766,
      c: 324,
      z: 6,
      s: 41,
      sc: 16,
    },
    lc: 0,
    rt: 1677486807,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.735,
      io_66: 13735,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486809,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9229833,
      x: 30.41896,
      c: 333,
      z: 8,
      s: 52,
      sc: 19,
    },
    lc: 0,
    rt: 1677486811,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.718,
      io_66: 13718,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486816,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.923785,
      x: 30.4179249,
      c: 323,
      z: 8,
      s: 51,
      sc: 18,
    },
    lc: 0,
    rt: 1677486818,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.744,
      io_66: 13744,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486824,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9245816,
      x: 30.4168683,
      c: 328,
      z: 2,
      s: 39,
      sc: 17,
    },
    lc: 0,
    rt: 1677486826,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.62,
      io_66: 13620,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486826,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9247116,
      x: 30.4166883,
      c: 330,
      z: 4,
      s: 27,
      sc: 17,
    },
    lc: 0,
    rt: 1677486829,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.555,
      io_66: 13555,
      pwr_int: 3.921,
      io_67: 3921,
      io_68: 0,
    },
  },
  {
    t: 1677486832,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9249733,
      x: 30.4163633,
      c: 324,
      z: 5,
      s: 16,
      sc: 17,
    },
    lc: 0,
    rt: 1677486848,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.604,
      io_66: 13604,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486835,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92504,
      x: 30.4162666,
      c: 324,
      z: 5,
      s: 6,
      sc: 17,
    },
    lc: 0,
    rt: 1677486848,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.656,
      io_66: 13656,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486857,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92551,
      x: 30.4157666,
      c: 334,
      z: 10,
      s: 10,
      sc: 19,
    },
    lc: 0,
    rt: 1677486859,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.665,
      io_66: 13665,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486870,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.925785,
      x: 30.4155183,
      c: 0,
      z: 13,
      s: 0,
      sc: 19,
    },
    lc: 0,
    rt: 1677486873,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.625,
      io_66: 13625,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486874,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9258166,
      x: 30.4154033,
      c: 341,
      z: 16,
      s: 6,
      sc: 19,
    },
    lc: 0,
    rt: 1677486876,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.575,
      io_66: 13575,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677486880,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9259083,
      x: 30.415315,
      c: 326,
      z: 19,
      s: 9,
      sc: 19,
    },
    lc: 0,
    rt: 1677486882,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.736,
      io_66: 13736,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486881,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9259283,
      x: 30.415295,
      c: 337,
      z: 19,
      s: 7,
      sc: 19,
    },
    lc: 0,
    rt: 1677486882,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.714,
      io_66: 13714,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486884,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92598,
      x: 30.41519,
      c: 325,
      z: 19,
      s: 11,
      sc: 19,
    },
    lc: 0,
    rt: 1677486886,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.69,
      io_66: 13690,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486916,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9269666,
      x: 30.4141216,
      c: 0,
      z: 15,
      s: 0,
      sc: 18,
    },
    lc: 0,
    rt: 1677486919,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.699,
      io_66: 13699,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486918,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9270083,
      x: 30.414065,
      c: 323,
      z: 15,
      s: 11,
      sc: 18,
    },
    lc: 0,
    rt: 1677486920,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.691,
      io_66: 13691,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677486922,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9271233,
      x: 30.4138483,
      c: 327,
      z: 15,
      s: 21,
      sc: 18,
    },
    lc: 0,
    rt: 1677486924,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.697,
      io_66: 13697,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677486935,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9279199,
      x: 30.4129583,
      c: 338,
      z: 10,
      s: 29,
      sc: 16,
    },
    lc: 0,
    rt: 1677486938,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.658,
      io_66: 13658,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486949,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92877,
      x: 30.412285,
      c: 335,
      z: 6,
      s: 18,
      sc: 19,
    },
    lc: 0,
    rt: 1677486951,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.527,
      io_66: 13527,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677486951,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9288333,
      x: 30.4122366,
      c: 338,
      z: 5,
      s: 7,
      sc: 19,
    },
    lc: 0,
    rt: 1677486953,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.476,
      io_66: 13476,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677487016,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9289466,
      x: 30.4121283,
      c: 326,
      z: 1,
      s: 17,
      sc: 19,
    },
    lc: 0,
    rt: 1677487017,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.593,
      io_66: 13593,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487019,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9290333,
      x: 30.4119883,
      c: 313,
      z: 2,
      s: 10,
      sc: 19,
    },
    lc: 0,
    rt: 1677487020,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.486,
      io_66: 13486,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677487021,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9290583,
      x: 30.411915,
      c: 294,
      z: 2,
      s: 7,
      sc: 19,
    },
    lc: 0,
    rt: 1677487022,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.576,
      io_66: 13576,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677487022,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.929065,
      x: 30.411875,
      c: 279,
      z: 2,
      s: 8,
      sc: 19,
    },
    lc: 0,
    rt: 1677487023,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.581,
      io_66: 13581,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677487049,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.929045,
      x: 30.4115416,
      c: 249,
      z: 0,
      s: 20,
      sc: 19,
    },
    lc: 0,
    rt: 1677487050,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 26,
      pwr_ext: 13.659,
      io_66: 13659,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487053,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9289699,
      x: 30.411185,
      c: 250,
      z: 0,
      s: 32,
      sc: 18,
    },
    lc: 0,
    rt: 1677487054,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.55,
      io_66: 13550,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677487066,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9286316,
      x: 30.4093633,
      c: 245,
      z: 0,
      s: 21,
      sc: 19,
    },
    lc: 0,
    rt: 1677487067,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.623,
      io_66: 13623,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487075,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9284433,
      x: 30.4085466,
      c: 247,
      z: 1,
      s: 11,
      sc: 19,
    },
    lc: 0,
    rt: 1677487076,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.577,
      io_66: 13577,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487080,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.928415,
      x: 30.408365,
      c: 0,
      z: 3,
      s: 0,
      sc: 18,
    },
    lc: 0,
    rt: 1677487083,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.528,
      io_66: 13528,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487114,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.928335,
      x: 30.4082666,
      c: 248,
      z: 11,
      s: 12,
      sc: 19,
    },
    lc: 0,
    rt: 1677487115,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.644,
      io_66: 13644,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487133,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9280683,
      x: 30.406585,
      c: 245,
      z: 5,
      s: 23,
      sc: 19,
    },
    lc: 0,
    rt: 1677487134,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.668,
      io_66: 13668,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487148,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9277716,
      x: 30.40514,
      c: 251,
      z: 4,
      s: 11,
      sc: 17,
    },
    lc: 0,
    rt: 1677487149,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.541,
      io_66: 13541,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487152,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9277416,
      x: 30.40497,
      c: 241,
      z: 3,
      s: 8,
      sc: 18,
    },
    lc: 0,
    rt: 1677487154,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.554,
      io_66: 13554,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677487176,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9275266,
      x: 30.4041233,
      c: 241,
      z: 4,
      s: 18,
      sc: 18,
    },
    lc: 0,
    rt: 1677487177,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.621,
      io_66: 13621,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487179,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9274499,
      x: 30.4037949,
      c: 245,
      z: 3,
      s: 28,
      sc: 18,
    },
    lc: 0,
    rt: 1677487180,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.632,
      io_66: 13632,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677487186,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92723,
      x: 30.402685,
      c: 247,
      z: 6,
      s: 38,
      sc: 18,
    },
    lc: 0,
    rt: 1677487187,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.696,
      io_66: 13696,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487208,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92635,
      x: 30.398345,
      c: 247,
      z: 11,
      s: 48,
      sc: 19,
    },
    lc: 0,
    rt: 1677487209,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.692,
      io_66: 13692,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487218,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.925915,
      x: 30.396235,
      c: 247,
      z: 13,
      s: 37,
      sc: 19,
    },
    lc: 0,
    rt: 1677487221,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.576,
      io_66: 13576,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487229,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9255283,
      x: 30.3943316,
      c: 248,
      z: 11,
      s: 48,
      sc: 19,
    },
    lc: 0,
    rt: 1677487230,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.701,
      io_66: 13701,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487243,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9248816,
      x: 30.39105,
      c: 248,
      z: 5,
      s: 36,
      sc: 19,
    },
    lc: 0,
    rt: 1677487244,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.563,
      io_66: 13563,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487245,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9248399,
      x: 30.3908233,
      c: 248,
      z: 5,
      s: 18,
      sc: 19,
    },
    lc: 0,
    rt: 1677487250,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.543,
      io_66: 13543,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677487255,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9246683,
      x: 30.3900383,
      c: 248,
      z: 4,
      s: 28,
      sc: 19,
    },
    lc: 0,
    rt: 1677487257,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.686,
      io_66: 13686,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487263,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9244283,
      x: 30.3887783,
      c: 251,
      z: 1,
      s: 39,
      sc: 19,
    },
    lc: 0,
    rt: 1677487268,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.7,
      io_66: 13700,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487270,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9242033,
      x: 30.3873783,
      c: 252,
      z: 4,
      s: 49,
      sc: 19,
    },
    lc: 0,
    rt: 1677487272,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.694,
      io_66: 13694,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677487276,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9239766,
      x: 30.3860733,
      c: 248,
      z: 4,
      s: 37,
      sc: 18,
    },
    lc: 0,
    rt: 1677487278,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.652,
      io_66: 13652,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487281,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9238516,
      x: 30.385225,
      c: 267,
      z: 2,
      s: 32,
      sc: 18,
    },
    lc: 0,
    rt: 1677487283,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.609,
      io_66: 13609,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487282,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9238583,
      x: 30.38506,
      c: 277,
      z: 2,
      s: 33,
      sc: 18,
    },
    lc: 0,
    rt: 1677487283,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.652,
      io_66: 13652,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487284,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9239049,
      x: 30.38473,
      c: 291,
      z: 3,
      s: 36,
      sc: 16,
    },
    lc: 0,
    rt: 1677487286,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.609,
      io_66: 13609,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677487286,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.924005,
      x: 30.38442,
      c: 302,
      z: 5,
      s: 43,
      sc: 17,
    },
    lc: 0,
    rt: 1677487287,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.652,
      io_66: 13652,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677487292,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9244766,
      x: 30.383315,
      c: 306,
      z: 3,
      s: 53,
      sc: 18,
    },
    lc: 0,
    rt: 1677487293,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.738,
      io_66: 13738,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487311,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9261333,
      x: 30.3787683,
      c: 306,
      z: 7,
      s: 57,
      sc: 18,
    },
    lc: 0,
    rt: 1677487313,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.726,
      io_66: 13726,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487320,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9268,
      x: 30.3767516,
      c: 307,
      z: 8,
      s: 38,
      sc: 17,
    },
    lc: 0,
    rt: 1677487322,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 22,
      pwr_ext: 13.622,
      io_66: 13622,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487322,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9268866,
      x: 30.3764899,
      c: 307,
      z: 9,
      s: 27,
      sc: 17,
    },
    lc: 0,
    rt: 1677487323,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.591,
      io_66: 13591,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677487324,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9269599,
      x: 30.3763133,
      c: 306,
      z: 10,
      s: 14,
      sc: 17,
    },
    lc: 0,
    rt: 1677487325,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.56,
      io_66: 13560,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677487325,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9269599,
      x: 30.3763133,
      c: 0,
      z: 10,
      s: 0,
      sc: 17,
    },
    lc: 0,
    rt: 1677487328,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.608,
      io_66: 13608,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677487330,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9269999,
      x: 30.3761649,
      c: 300,
      z: 8,
      s: 11,
      sc: 17,
    },
    lc: 0,
    rt: 1677487332,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.635,
      io_66: 13635,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487333,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9270283,
      x: 30.3760866,
      c: 0,
      z: 7,
      s: 0,
      sc: 17,
    },
    lc: 0,
    rt: 1677487335,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.642,
      io_66: 13642,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677487342,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92698,
      x: 30.3758083,
      c: 305,
      z: 8,
      s: 17,
      sc: 16,
    },
    lc: 0,
    rt: 1677487345,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.736,
      io_66: 13736,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487344,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9270483,
      x: 30.3755933,
      c: 307,
      z: 11,
      s: 28,
      sc: 16,
    },
    lc: 0,
    rt: 1677487345,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.73,
      io_66: 13730,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487347,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9272249,
      x: 30.375165,
      c: 306,
      z: 13,
      s: 41,
      sc: 16,
    },
    lc: 0,
    rt: 1677487348,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.723,
      io_66: 13723,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677487363,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9283433,
      x: 30.3724016,
      c: 307,
      z: 11,
      s: 29,
      sc: 18,
    },
    lc: 0,
    rt: 1677487364,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.601,
      io_66: 13601,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487365,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9283866,
      x: 30.3721833,
      c: 303,
      z: 13,
      s: 19,
      sc: 18,
    },
    lc: 0,
    rt: 1677487367,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.585,
      io_66: 13585,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677487368,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92844,
      x: 30.37202,
      c: 306,
      z: 15,
      s: 5,
      sc: 18,
    },
    lc: 0,
    rt: 1677487369,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.565,
      io_66: 13565,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677487416,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9285199,
      x: 30.371915,
      c: 309,
      z: 15,
      s: 18,
      sc: 17,
    },
    lc: 0,
    rt: 1677487418,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.723,
      io_66: 13723,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487418,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92858,
      x: 30.371735,
      c: 303,
      z: 16,
      s: 28,
      sc: 17,
    },
    lc: 0,
    rt: 1677487420,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.693,
      io_66: 13693,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677487422,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.928755,
      x: 30.371085,
      c: 303,
      z: 17,
      s: 38,
      sc: 16,
    },
    lc: 0,
    rt: 1677487424,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.693,
      io_66: 13693,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487431,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9293116,
      x: 30.369665,
      c: 311,
      z: 12,
      s: 27,
      sc: 18,
    },
    lc: 0,
    rt: 1677487433,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.577,
      io_66: 13577,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487433,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92937,
      x: 30.3695016,
      c: 314,
      z: 11,
      s: 13,
      sc: 18,
    },
    lc: 0,
    rt: 1677487435,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.546,
      io_66: 13546,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677487435,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9293866,
      x: 30.3694583,
      c: 0,
      z: 10,
      s: 0,
      sc: 18,
    },
    lc: 0,
    rt: 1677487438,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.552,
      io_66: 13552,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677487449,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9294233,
      x: 30.3692816,
      c: 307,
      z: 12,
      s: 12,
      sc: 18,
    },
    lc: 0,
    rt: 1677487451,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.635,
      io_66: 13635,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487454,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9295433,
      x: 30.36899,
      c: 319,
      z: 16,
      s: 9,
      sc: 17,
    },
    lc: 0,
    rt: 1677487456,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.572,
      io_66: 13572,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487456,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.929565,
      x: 30.3689066,
      c: 303,
      z: 18,
      s: 8,
      sc: 17,
    },
    lc: 0,
    rt: 1677487458,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.533,
      io_66: 13533,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677487597,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.930015,
      x: 30.3676633,
      c: 318,
      z: 23,
      s: 9,
      sc: 17,
    },
    lc: 0,
    rt: 1677487598,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.54,
      io_66: 13540,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487608,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9301566,
      x: 30.3674433,
      c: 328,
      z: 20,
      s: 7,
      sc: 18,
    },
    lc: 0,
    rt: 1677487609,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.634,
      io_66: 13634,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487609,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9301733,
      x: 30.3674166,
      c: 314,
      z: 20,
      s: 8,
      sc: 18,
    },
    lc: 0,
    rt: 1677487610,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.627,
      io_66: 13627,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677487610,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9301916,
      x: 30.3673833,
      c: 325,
      z: 19,
      s: 8,
      sc: 18,
    },
    lc: 0,
    rt: 1677487611,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.598,
      io_66: 13598,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677487611,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9302066,
      x: 30.3673549,
      c: 343,
      z: 19,
      s: 7,
      sc: 18,
    },
    lc: 0,
    rt: 1677487612,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.589,
      io_66: 13589,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677487612,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9302233,
      x: 30.3673116,
      c: 316,
      z: 19,
      s: 9,
      sc: 18,
    },
    lc: 0,
    rt: 1677487613,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.617,
      io_66: 13617,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677487623,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9304066,
      x: 30.366705,
      c: 296,
      z: 8,
      s: 9,
      sc: 17,
    },
    lc: 0,
    rt: 1677487624,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.488,
      io_66: 13488,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487708,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9304316,
      x: 30.3659633,
      c: 282,
      z: 1,
      s: 12,
      sc: 18,
    },
    lc: 0,
    rt: 1677487709,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.581,
      io_66: 13581,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487714,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9304449,
      x: 30.3655016,
      c: 272,
      z: -2,
      s: 15,
      sc: 18,
    },
    lc: 0,
    rt: 1677487715,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.574,
      io_66: 13574,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487719,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9304383,
      x: 30.3651416,
      c: 256,
      z: -3,
      s: 9,
      sc: 18,
    },
    lc: 0,
    rt: 1677487720,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.475,
      io_66: 13475,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487721,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9304449,
      x: 30.3650533,
      c: 280,
      z: -3,
      s: 6,
      sc: 18,
    },
    lc: 0,
    rt: 1677487722,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.498,
      io_66: 13498,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677487724,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.930465,
      x: 30.3649583,
      c: 300,
      z: -1,
      s: 6,
      sc: 18,
    },
    lc: 0,
    rt: 1677487725,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.502,
      io_66: 13502,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677487725,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9305,
      x: 30.3649316,
      c: 316,
      z: -1,
      s: 9,
      sc: 18,
    },
    lc: 0,
    rt: 1677487726,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.479,
      io_66: 13479,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677487726,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9305083,
      x: 30.3648966,
      c: 292,
      z: 0,
      s: 7,
      sc: 18,
    },
    lc: 0,
    rt: 1677487727,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.512,
      io_66: 13512,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677487730,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9305216,
      x: 30.364775,
      c: 281,
      z: 0,
      s: 6,
      sc: 18,
    },
    lc: 0,
    rt: 1677487732,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.567,
      io_66: 13567,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487748,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.930665,
      x: 30.3645366,
      c: 278,
      z: 0,
      s: 17,
      sc: 19,
    },
    lc: 0,
    rt: 1677487749,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.593,
      io_66: 13593,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487751,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9306766,
      x: 30.3641816,
      c: 277,
      z: 0,
      s: 31,
      sc: 19,
    },
    lc: 0,
    rt: 1677487755,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.569,
      io_66: 13569,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677487757,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9307883,
      x: 30.3631616,
      c: 289,
      z: -2,
      s: 31,
      sc: 17,
    },
    lc: 0,
    rt: 1677487758,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.599,
      io_66: 13599,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677487760,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9309116,
      x: 30.362655,
      c: 300,
      z: -2,
      s: 40,
      sc: 18,
    },
    lc: 0,
    rt: 1677487761,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.59,
      io_66: 13590,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677487763,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9310683,
      x: 30.3621633,
      c: 301,
      z: -4,
      s: 30,
      sc: 18,
    },
    lc: 0,
    rt: 1677487765,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.475,
      io_66: 13475,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677487765,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9311199,
      x: 30.3619599,
      c: 291,
      z: -4,
      s: 17,
      sc: 18,
    },
    lc: 0,
    rt: 1677487766,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.456,
      io_66: 13456,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677487767,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9311333,
      x: 30.3618966,
      c: 0,
      z: -4,
      s: 0,
      sc: 18,
    },
    lc: 0,
    rt: 1677487769,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.489,
      io_66: 13489,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677487807,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9311366,
      x: 30.3617833,
      c: 264,
      z: -4,
      s: 15,
      sc: 19,
    },
    lc: 0,
    rt: 1677487809,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.62,
      io_66: 13620,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487810,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.931115,
      x: 30.3614466,
      c: 262,
      z: -2,
      s: 26,
      sc: 19,
    },
    lc: 0,
    rt: 1677487811,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.587,
      io_66: 13587,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677487812,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.93108,
      x: 30.36117,
      c: 244,
      z: -1,
      s: 29,
      sc: 19,
    },
    lc: 0,
    rt: 1677487813,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.58,
      io_66: 13580,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677487813,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9310383,
      x: 30.361045,
      c: 228,
      z: -1,
      s: 30,
      sc: 19,
    },
    lc: 0,
    rt: 1677487814,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.599,
      io_66: 13599,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677487814,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.930975,
      x: 30.3609366,
      c: 212,
      z: -2,
      s: 35,
      sc: 19,
    },
    lc: 0,
    rt: 1677487815,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.579,
      io_66: 13579,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677487816,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9308116,
      x: 30.3607833,
      c: 197,
      z: -1,
      s: 35,
      sc: 18,
    },
    lc: 0,
    rt: 1677487818,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.586,
      io_66: 13586,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677487822,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9302816,
      x: 30.3605,
      c: 196,
      z: -3,
      s: 25,
      sc: 19,
    },
    lc: 0,
    rt: 1677487824,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.478,
      io_66: 13478,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487825,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9301333,
      x: 30.3604033,
      c: 197,
      z: -4,
      s: 15,
      sc: 19,
    },
    lc: 0,
    rt: 1677487827,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.475,
      io_66: 13475,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677487827,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.930095,
      x: 30.3603666,
      c: 199,
      z: -4,
      s: 5,
      sc: 19,
    },
    lc: 0,
    rt: 1677487828,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.451,
      io_66: 13451,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677487850,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.93001,
      x: 30.36026,
      c: 197,
      z: -2,
      s: 19,
      sc: 19,
    },
    lc: 0,
    rt: 1677487852,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.616,
      io_66: 13616,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487853,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9298299,
      x: 30.3601533,
      c: 196,
      z: 0,
      s: 30,
      sc: 18,
    },
    lc: 0,
    rt: 1677487854,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.583,
      io_66: 13583,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677487859,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9292966,
      x: 30.3598283,
      c: 198,
      z: 0,
      s: 40,
      sc: 18,
    },
    lc: 0,
    rt: 1677487860,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.615,
      io_66: 13615,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487868,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9283016,
      x: 30.3592283,
      c: 197,
      z: 0,
      s: 50,
      sc: 19,
    },
    lc: 0,
    rt: 1677487870,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.61,
      io_66: 13610,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487875,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.927535,
      x: 30.358735,
      c: 197,
      z: 0,
      s: 40,
      sc: 19,
    },
    lc: 0,
    rt: 1677487877,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.561,
      io_66: 13561,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487883,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92683,
      x: 30.35832,
      c: 196,
      z: -1,
      s: 28,
      sc: 19,
    },
    lc: 0,
    rt: 1677487885,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.432,
      io_66: 13432,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487885,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92673,
      x: 30.3582683,
      c: 197,
      z: -2,
      s: 13,
      sc: 19,
    },
    lc: 0,
    rt: 1677487886,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.37,
      io_66: 13370,
      pwr_int: 3.923,
      io_67: 3923,
      io_68: 0,
    },
  },
  {
    t: 1677487888,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.926695,
      x: 30.3582366,
      c: 0,
      z: -3,
      s: 0,
      sc: 19,
    },
    lc: 0,
    rt: 1677487890,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.52,
      io_66: 13520,
      pwr_int: 3.93,
      io_67: 3930,
      io_68: 0,
    },
  },
  {
    t: 1677487905,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9266583,
      x: 30.3582766,
      c: 45,
      z: -3,
      s: 16,
      sc: 19,
    },
    lc: 0,
    rt: 1677487907,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.63,
      io_66: 13630,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487906,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9266916,
      x: 30.3583383,
      c: 35,
      z: -2,
      s: 17,
      sc: 19,
    },
    lc: 0,
    rt: 1677487907,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.616,
      io_66: 13616,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677487907,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9267283,
      x: 30.3583866,
      c: 13,
      z: -2,
      s: 16,
      sc: 19,
    },
    lc: 0,
    rt: 1677487908,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.54,
      io_66: 13540,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677487912,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9269149,
      x: 30.3586383,
      c: 29,
      z: -3,
      s: 14,
      sc: 19,
    },
    lc: 0,
    rt: 1677487914,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.629,
      io_66: 13629,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487916,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.927065,
      x: 30.3588233,
      c: 9,
      z: -2,
      s: 12,
      sc: 19,
    },
    lc: 0,
    rt: 1677487918,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.398,
      io_66: 13398,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677487919,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9271016,
      x: 30.358885,
      c: 0,
      z: -2,
      s: 0,
      sc: 19,
    },
    lc: 0,
    rt: 1677487922,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.444,
      io_66: 13444,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677487970,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9271283,
      x: 30.3590516,
      c: 11,
      z: -3,
      s: 12,
      sc: 19,
    },
    lc: 0,
    rt: 1677487975,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.6,
      io_66: 13600,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487974,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9272916,
      x: 30.3591766,
      c: 21,
      z: -2,
      s: 21,
      sc: 19,
    },
    lc: 0,
    rt: 1677487975,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.591,
      io_66: 13591,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677487980,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9276866,
      x: 30.359465,
      c: 13,
      z: -2,
      s: 31,
      sc: 19,
    },
    lc: 0,
    rt: 1677487982,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.632,
      io_66: 13632,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487989,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9283383,
      x: 30.3598666,
      c: 15,
      z: -3,
      s: 20,
      sc: 16,
    },
    lc: 0,
    rt: 1677487991,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.415,
      io_66: 13415,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677487991,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.928395,
      x: 30.3599616,
      c: 25,
      z: -3,
      s: 9,
      sc: 19,
    },
    lc: 0,
    rt: 1677487993,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.412,
      io_66: 13412,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677487998,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92841,
      x: 30.359985,
      c: 0,
      z: -3,
      s: 0,
      sc: 19,
    },
    lc: 0,
    rt: 1677487999,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 32,
      pwr_ext: 13.463,
      io_66: 13463,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488010,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92841,
      x: 30.359985,
      c: 0,
      z: -3,
      s: 0,
      sc: 19,
    },
    lc: 0,
    rt: 1677488011,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 33,
      pwr_ext: 13.439,
      io_66: 13439,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488012,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.928465,
      x: 30.36001,
      c: 313,
      z: -4,
      s: 7,
      sc: 8,
    },
    lc: 0,
    rt: 1677488014,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.409,
      io_66: 13409,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677488014,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9284683,
      x: 30.35992,
      c: 291,
      z: -4,
      s: 10,
      sc: 8,
    },
    lc: 0,
    rt: 1677488015,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.417,
      io_66: 13417,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677488017,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9284733,
      x: 30.3598683,
      c: 0,
      z: -4,
      s: 0,
      sc: 7,
    },
    lc: 0,
    rt: 1677488019,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.315,
      io_66: 13315,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488023,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9284533,
      x: 30.35997,
      c: 106,
      z: -4,
      s: 8,
      sc: 4,
    },
    lc: 0,
    rt: 1677488025,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.583,
      io_66: 13583,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488323,
    f: 0,
    tp: 'ud',
    pos: null,
    lc: 0,
    rt: 1677488324,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 13.088,
      io_66: 13088,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488623,
    f: 0,
    tp: 'ud',
    pos: null,
    lc: 0,
    rt: 1677488625,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 0,
      io_179: 0,
      pwr_ext: 13.088,
      io_66: 13088,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488758,
    f: 0,
    tp: 'ud',
    pos: null,
    lc: 0,
    rt: 1677488759,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 40,
      pwr_ext: 13.694,
      io_66: 13694,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488815,
    f: 0,
    tp: 'ud',
    pos: null,
    lc: 0,
    rt: 1677488817,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 24,
      pwr_ext: 13.612,
      io_66: 13612,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488828,
    f: 0,
    tp: 'ud',
    pos: null,
    lc: 0,
    rt: 1677488831,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 43,
      pwr_ext: 13.434,
      io_66: 13434,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488829,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9267833,
      x: 30.3620766,
      c: 158,
      z: 12,
      s: 19,
      sc: 10,
    },
    lc: 0,
    rt: 1677488831,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.464,
      io_66: 13464,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488831,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9266899,
      x: 30.3621033,
      c: 168,
      z: 13,
      s: 20,
      sc: 10,
    },
    lc: 0,
    rt: 1677488832,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.674,
      io_66: 13674,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677488839,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9263416,
      x: 30.3621916,
      c: 182,
      z: 4,
      s: 14,
      sc: 14,
    },
    lc: 0,
    rt: 1677488842,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.653,
      io_66: 13653,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488840,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9262866,
      x: 30.3622283,
      c: 212,
      z: 4,
      s: 12,
      sc: 16,
    },
    lc: 0,
    rt: 1677488843,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.648,
      io_66: 13648,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677488841,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9262716,
      x: 30.362205,
      c: 243,
      z: 5,
      s: 9,
      sc: 16,
    },
    lc: 0,
    rt: 1677488843,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.635,
      io_66: 13635,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677488842,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9262649,
      x: 30.3621483,
      c: 258,
      z: 6,
      s: 14,
      sc: 17,
    },
    lc: 0,
    rt: 1677488843,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.607,
      io_66: 13607,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677488848,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9262516,
      x: 30.3618083,
      c: 235,
      z: 9,
      s: 6,
      sc: 17,
    },
    lc: 0,
    rt: 1677488854,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.624,
      io_66: 13624,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488849,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9262533,
      x: 30.3617483,
      c: 277,
      z: 9,
      s: 16,
      sc: 17,
    },
    lc: 0,
    rt: 1677488854,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.631,
      io_66: 13631,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488852,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.926275,
      x: 30.3616166,
      c: 299,
      z: 10,
      s: 6,
      sc: 16,
    },
    lc: 0,
    rt: 1677488854,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.611,
      io_66: 13611,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677488853,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92629,
      x: 30.3616083,
      c: 325,
      z: 10,
      s: 6,
      sc: 16,
    },
    lc: 0,
    rt: 1677488854,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.543,
      io_66: 13543,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488859,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9264766,
      x: 30.36153,
      c: 353,
      z: 12,
      s: 12,
      sc: 13,
    },
    lc: 0,
    rt: 1677488868,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.609,
      io_66: 13609,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488860,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.926515,
      x: 30.36159,
      c: 9,
      z: 12,
      s: 12,
      sc: 11,
    },
    lc: 0,
    rt: 1677488869,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.611,
      io_66: 13611,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488861,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9265366,
      x: 30.3616266,
      c: 21,
      z: 12,
      s: 12,
      sc: 9,
    },
    lc: 0,
    rt: 1677488869,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.597,
      io_66: 13597,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488863,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9266266,
      x: 30.3616599,
      c: 2,
      z: 12,
      s: 12,
      sc: 7,
    },
    lc: 0,
    rt: 1677488869,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.49,
      io_66: 13490,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488896,
    f: 0,
    tp: 'ud',
    pos: null,
    lc: 0,
    rt: 1677488898,
    p: {
      prior: 0,
      event_io_id: 253,
      total_io: 7,
      io_1: 1,
      io_179: 0,
      io_253: 3,
      io_254: 30,
      pwr_ext: 13.474,
      io_66: 13474,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488901,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9270416,
      x: 30.35866,
      c: 18,
      z: 11,
      s: 18,
      sc: 17,
    },
    lc: 0,
    rt: 1677488902,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.557,
      io_66: 13557,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488904,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9272483,
      x: 30.3588766,
      c: 14,
      z: 7,
      s: 33,
      sc: 18,
    },
    lc: 0,
    rt: 1677488905,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.595,
      io_66: 13595,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677488908,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9277483,
      x: 30.3592116,
      c: 15,
      z: 2,
      s: 51,
      sc: 18,
    },
    lc: 0,
    rt: 1677488909,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.618,
      io_66: 13618,
      pwr_int: 3.928,
      io_67: 3928,
      io_68: 0,
    },
  },
  {
    t: 1677488921,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.92916,
      x: 30.3601166,
      c: 17,
      z: 2,
      s: 39,
      sc: 19,
    },
    lc: 0,
    rt: 1677488922,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.511,
      io_66: 13511,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488923,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9293883,
      x: 30.36026,
      c: 18,
      z: 2,
      s: 25,
      sc: 19,
    },
    lc: 0,
    rt: 1677488924,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.466,
      io_66: 13466,
      pwr_int: 3.923,
      io_67: 3923,
      io_68: 0,
    },
  },
  {
    t: 1677488925,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9294833,
      x: 30.3603216,
      c: 14,
      z: 1,
      s: 15,
      sc: 19,
    },
    lc: 0,
    rt: 1677488927,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.472,
      io_66: 13472,
      pwr_int: 3.924,
      io_67: 3924,
      io_68: 0,
    },
  },
  {
    t: 1677488929,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9295483,
      x: 30.3603383,
      c: 5,
      z: 0,
      s: 5,
      sc: 19,
    },
    lc: 0,
    rt: 1677488931,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.469,
      io_66: 13469,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677488958,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9295733,
      x: 30.360435,
      c: 24,
      z: 1,
      s: 14,
      sc: 18,
    },
    lc: 0,
    rt: 1677488959,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.62,
      io_66: 13620,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488961,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9297183,
      x: 30.3605383,
      c: 21,
      z: 0,
      s: 26,
      sc: 19,
    },
    lc: 0,
    rt: 1677488962,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.585,
      io_66: 13585,
      pwr_int: 3.925,
      io_67: 3925,
      io_68: 0,
    },
  },
  {
    t: 1677488963,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.929875,
      x: 30.3606283,
      c: 14,
      z: 0,
      s: 36,
      sc: 19,
    },
    lc: 0,
    rt: 1677488964,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.591,
      io_66: 13591,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
  {
    t: 1677488971,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9305166,
      x: 30.36089,
      c: 16,
      z: -2,
      s: 17,
      sc: 19,
    },
    lc: 0,
    rt: 1677488973,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.396,
      io_66: 13396,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677488973,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9305766,
      x: 30.3609183,
      c: 15,
      z: -2,
      s: 7,
      sc: 18,
    },
    lc: 0,
    rt: 1677488974,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.417,
      io_66: 13417,
      pwr_int: 3.926,
      io_67: 3926,
      io_68: 0,
    },
  },
  {
    t: 1677489094,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9306933,
      x: 30.3609833,
      c: 14,
      z: 0,
      s: 18,
      sc: 19,
    },
    lc: 0,
    rt: 1677489094,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.508,
      io_66: 13508,
      pwr_int: 3.929,
      io_67: 3929,
      io_68: 0,
    },
  },
  {
    t: 1677489097,
    f: 1,
    tp: 'ud',
    pos: {
      y: 59.9308683,
      x: 30.36105,
      c: 2,
      z: 0,
      s: 28,
      sc: 19,
    },
    lc: 0,
    rt: 1677489098,
    p: {
      prior: 0,
      event_io_id: 0,
      total_io: 5,
      io_1: 1,
      io_179: 0,
      pwr_ext: 13.488,
      io_66: 13488,
      pwr_int: 3.927,
      io_67: 3927,
      io_68: 0,
    },
  },
];
