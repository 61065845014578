<app-night-theme *ngIf="night | async"></app-night-theme>

<app-push-blocked-account></app-push-blocked-account>

<app-progress-import-popup
  *ngIf="(importObjectsList$ | async)?.length || false"
></app-progress-import-popup>

<tui-loader [overlay]="appLoading" [showLoader]="appLoading">
  <div class="portal">
    <div class="div1">
      <app-header></app-header>
    </div>
    <div
      class="route-component control-block"
      [class.on-show-map-fullscreen]="portalService.isShowMapFullScreen | async"
    >
      <div tuiResizeable class="right-route-resizeable" [style.width]="widthPortal">
        <router-outlet></router-outlet>

        <div
          class="right-route-resizeable-handle"
          [tuiResizer]="[1, 0]"
          (tuiSizeChange)="onChangeSizeContentRoute($event)"
        ></div>
      </div>
    </div>

    <div class="map div3">
      <div class="flexible-window" cdkDragBoundary=".portal" cdkDrag>
        <div class="header" cdkDragHandle></div>
        <app-track-player *ngIf="portalService.isVisibleTrackPlayer | async"></app-track-player>
        <app-compare *ngIf="portalService.isVisibleCompareComponent | async"></app-compare>
      </div>
      <app-room-scheme-view *ngIf="portalService.isVisibleScheme | async"></app-room-scheme-view>
      <div
        *ngIf="portalService.isVisibleMap | async"
        tuiResizeable
        class="bottom-map-resizeable"
        [style.height.px]="heightMessages"
        [class.max-height]="isVisibleReport || isVisibleMessages"
      >
        <app-map></app-map>
        <div
          class="bottom-map-resizeable-handle"
          [class.display-none]="!(isVisibleReport || isVisibleMessages)"
          [tuiResizer]="[0, 1]"
          (tuiSizeChange)="onChangeSizeMap($event)"
        ></div>
      </div>
      <app-view-messages [topValue]="topValue" *ngIf="isVisibleMessages"></app-view-messages>
      <app-report-result [topValue]="topValue" *ngIf="isVisibleReport"></app-report-result>
    </div>
  </div>
</tui-loader>
