import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  updateConnectedStatusForObject,
  updateLastMessageForObject,
} from '@store/actions/objects.actions';
import { environment } from '../environments/environment';
import { MapService } from '../app/portal/components/map/map.service';
import { TuiAlertService } from '@taiga-ui/core';
import { NotificationsHelperService } from '@services/notifications-helper.service';
import { changeListImportObjects } from '@store/actions/import_objects.actions';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private store: Store,
    private noticeHelpS: NotificationsHelperService,
    private mapService: MapService,
    private translate: TranslateService,
  ) {}

  sockets: any = {};

  userSocket: null | WebSocket = null;

  public isConnected: boolean = false;

  connectUserSocket(userId: number) {
    if (!this.isConnected) {
      const token = sessionStorage.getItem('token');
      this.userSocket = new WebSocket(
        `${environment.websocketUrl}live-data/${userId}?token=${token}`,
      );
      this.userSocket.onopen = () => {
        console.log(`connected ${userId}`);
        this.isConnected = true;
      };
      this.userSocket.onmessage = (data: any) => {
        const socketData = JSON.parse(data.data);
        if (socketData.type === 'live_new_data') {
          this.messageHandler(socketData);
        } else if (socketData.type === 'live_import_data') {
          this.importHandler(socketData);
        } else if (socketData.type === 'notification_counter') {
          this.noticeHelpS.countNotifications.next(this.noticeHelpS.countNotifications.value + 1);
        } else if (socketData.type === 'new_notification_push') {
          this.newNotification(socketData);
        } else if (socketData.type === 'command_result') {
          this.commandResult(socketData);
        }
      };
      this.userSocket.onclose = () => {
        this.isConnected = false;
        this.connectUserSocket(userId);
      };
    }
  }

  newNotification(socketData: any) {
    if (socketData.showPush) {
      const data = socketData.data;
      const content = `${data.text}`;
      const label = `${data.notificationName}: ${data.objectName}`;

      this.alerts
        .open(content, {
          label: label,
          autoClose: 6000,
          status: 'info',
        })
        .subscribe();
    }
  }

  messageHandler(data: any) {
    const newMessage = data.data?.type === 'nm';
    const connectedStatus = data.data?.type === 'cs';
    if (newMessage) {
      this.store.dispatch(updateLastMessageForObject(data.data));
      this.mapService.changeObjectMarkerPos.next(data.data);
    }
    if (connectedStatus) {
      this.store.dispatch(updateConnectedStatusForObject(data.data));
    }
  }

  commandResult(data: any) {
    const command = data.data.command;
    const msg =
      this.translate.instant('Command') +
      ' "' +
      command.command.name +
      '" ' +
      this.translate.instant('was sent to object') +
      ' "' +
      command.objectName +
      '"';
    this.alerts.open(msg, { status: 'success' }).subscribe();
  }

  importHandler(data: any) {
    this.store.dispatch(changeListImportObjects({ createdObject: data.data }));
    if (data.data?.status === 'DONE') {
      this.alerts
        .open(this.translate.instant('Import is finished'), { status: 'success' })
        .subscribe();
    }
  }
}
