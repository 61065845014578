import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { PortalService } from '@services/portal.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PaginatorService } from '@common/services/paginator.service';
import { formatDefaultDateFromTui } from '@common/utils/format-date';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  public isLoadingReportList = new BehaviorSubject(false);

  public isLoadingContentOnMap: BehaviorSubject<any> = new BehaviorSubject(false);

  public performanceReportId: BehaviorSubject<any> = new BehaviorSubject(null);

  public selectedReport: BehaviorSubject<any> = new BehaviorSubject(null);

  public selectedTable: BehaviorSubject<any> = new BehaviorSubject(null);

  public selectedChart: BehaviorSubject<any> = new BehaviorSubject(null);

  formGroup: FormGroup = new FormGroup({
    selectedTemplateControl: new FormControl(null, Validators.required),
    selectedObject: new FormControl(null, Validators.required),
    periodDateFrom: new FormControl(null, Validators.required), // only ISO time
    periodDateTo: new FormControl(null, Validators.required), // only ISO time
  });

  constructor(
    private httpClient: HttpClient,
    private portalService: PortalService,
    public paginatorService: PaginatorService,
  ) {}

  clearReportSubjects() {
    this.portalService.isVisibleReportResult.next(false);
    this.performanceReportId.next(null);
    this.selectedReport.next(null);
  }

  get periodString() {
    const controls = this.formGroup.controls;
    return formatDefaultDateFromTui(
      controls['periodDateFrom'].value,
      controls['periodDateTo'].value,
    );
  }

  createReport(form: any) {
    return this.httpClient.post('reports/templates/', form);
  }

  updateReport(form: any, reportId: any) {
    return this.httpClient.put(`reports/templates/${reportId}/`, form);
  }

  listReport(params: any = null) {
    let httpParams = new HttpParams();
    if (params?.accountId) {
      httpParams = httpParams.set('account_id', params.accountId.toString());
    }
    return this.httpClient.get('reports/templates/', { params: httpParams });
  }

  deleteReport(reportId: number) {
    return this.httpClient.delete(`reports/templates/${reportId}`);
  }

  executeReport(body: any) {
    return this.httpClient.post(`reports/`, body);
  }

  reportBuildStatus(id: any) {
    return this.httpClient.get(`reports/status/?reportTaskId=${id}`);
  }

  getReportById(id: any) {
    return this.httpClient.get(`reports/${id}/`);
  }

  getTableById(id: any, page: number, perPage: number) {
    let params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.httpClient.get(`reports/table/${id}/`, { params });
  }

  getChartById(id: any) {
    return this.httpClient.get(`reports/chart/${id}/`);
  }

  exportAsExcel(reportId: any) {
    return this.httpClient.get(`reports/export/xlsx/${reportId}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  exportAsCSV(reportId: any) {
    return this.httpClient.get(`reports/export/csv/${reportId}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
