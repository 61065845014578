import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MessagesService } from '@services/messages.service';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-view-messages',
  templateUrl: './view-messages.component.html',
  styleUrls: ['./view-messages.component.less'],
  providers: [TuiDestroyService],
})
export class ViewMessagesComponent implements OnInit {
  @Input() topValue = 0;

  public selectedView = 'table';
  public selectedType = 'raw';
  public selectedMessageType = 'with_data';

  activeItemIndex = 0;

  filterField = new FormControl('');
  changeFilterEvent = new Subject();
  isFiltered = false;

  isNoWrapTable = true;

  isChartInitialized = false;
  brushEnabled = false;
  clearBrushTrigger = 1;
  isHighlightDigitalSeries = true;
  isDigitalSensors = false;

  constructor(
    public messagesService: MessagesService,
    private destroy$: TuiDestroyService,
  ) {}

  ngOnInit() {
    this.messagesService.currentView.next(this.selectedView);
    this.messagesService.currentType.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.selectedType = value;
    });
    this.messagesService.currentMessageType
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        this.selectedMessageType = value;
      });
    this.filterField.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      if (!value) {
        this.onClearFilter();
      }
    });
  }

  onClickTab(arg0: string) {
    this.selectedView = arg0;
    this.messagesService.currentView.next(arg0);

    if (arg0 === 'table') {
      this.clearBrushTrigger = 1;
      this.brushEnabled = false;
    }
  }

  onClickIsStroke() {
    this.isNoWrapTable = !this.isNoWrapTable;
  }

  onClickFilter() {
    const value = this.filterField.value ? this.filterField.value.replace(/ /g, '') : null;
    this.changeFilterEvent.next(value);
    this.isFiltered = value ? true : false;
  }

  onClearFilter() {
    this.changeFilterEvent.next(null);
    this.isFiltered = false;
  }
}
