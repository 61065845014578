import { getSearchSortValue } from '@common/utils/search-sort';

export const selectBuildings = (state: any) => state.buildings.list;

export const selectBuildingsById = (state: any, buildingsIds: number[]) => {
  return state.buildings.list.filter((f: any) => buildingsIds.includes(f.id));
};

export const selectSearchedSortedBuildings = (
  state: any,
  { searchValue, sortedValue }: any = { searchValue: null, sortedValue: null },
) => {
  const list = [...state.buildings.list];

  return getSearchSortValue(list, searchValue, sortedValue);
};
