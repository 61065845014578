<tui-scrollbar>
  <cdk-virtual-scroll-viewport
    #viewport
    tuiScrollable
    class="tui-zero-scrollbar"
    [itemSize]="45"
    [style.height.px]="windowHeight - topValue - 76"
  >
    <table
      tuiTable
      [columns]="columns"
      [style.white-space]="isNoWrapTable || isVertical ? 'nowrap' : ''"
      [class.vertical]="isVertical"
    >
      <thead>
        <tr tuiThGroup>
          <ng-container *ngFor="let column of columnsObj">
            <th tuiTh [resizable]="false" [sticky]="!isVertical">
              {{ column.title }}
            </th>
          </ng-container>
        </tr>
      </thead>

      <!--БЕЗ ГРУППИРОВКИ-->
      <ng-container *ngIf="!isGrouping">
        <tbody [data]="data" tuiTbody>
          <tr *ngFor="let item of data; let i = index" tuiTr>
            <ng-container *ngFor="let column of columns">
              <td *tuiCell="column" tuiTd>
                {{ item.data[column] | cellTransform: column }}
              </td>
            </ng-container>
          </tr>
        </tbody>
      </ng-container>

      <!--С ГРУППИРОВКОЙ-->
      <ng-container *ngIf="isGrouping">
        <ng-container *ngFor="let group of data">
          <tbody [data]="group" tuiTbody>
            <!--Итоговые значения каждой группы-->
            <tr tuiTr class="group-header">
              <ng-container *ngFor="let column of columns">
                <td class="group-column" *tuiCell="'grouping'" tuiTd>
                  {{ group?.data?.startDate | customDate }} -
                  {{ group?.data?.endDate | customDate }}

                  <tui-svg
                    (click)="openGrouping(group?.uid)"
                    [src]="
                      groupKey[group?.uid]
                        ? 'common/assets/icons/chevronUp.svg'
                        : 'common/assets/icons/chevronDown.svg'
                    "
                  ></tui-svg>
                </td>

                <td *tuiCell="column" tuiTd>
                  {{ group.data?.row[column] | cellTransform: column }}
                </td>
              </ng-container>
            </tr>
            <!--Элементы внутри группы-->
            <ng-container *ngIf="groupKey[group?.uid]">
              <tr *ngFor="let item of group?.data?.children; let i = index" tuiTr>
                <ng-container *ngFor="let column of columns">
                  <td *tuiCell="column" tuiTd>{{ item?.[column] | cellTransform: column }}</td>
                </ng-container>
              </tr>
            </ng-container>
          </tbody>
        </ng-container>
      </ng-container>
      <tfoot *ngIf="summary">
        <tr>
          <td *ngFor="let column of columns" tuiTd>
            <b>{{ summary?.[column] | cellTransform: column }}</b>
          </td>
        </tr>
      </tfoot>
    </table>
  </cdk-virtual-scroll-viewport>
</tui-scrollbar>
