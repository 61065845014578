export const getEnding = (number: number, key: string, verb: boolean = false) => {
  if (!verb) {
    if (number % 10 === 1 && number % 100 !== 11) {
      return `${key}_1`;
    } else if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
      return `${key}_2`;
    } else {
      return `${key}_3`;
    }
  } else {
    if (number % 10 === 1 && number % 100 !== 11) {
      return `${key}_2`;
    } else {
      return `${key}_3`;
    }
  }
};
