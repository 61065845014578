import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  public toggleLegendSubject = new Subject();

  colors = [
    '#3F51B5',
    '#2196F3',
    '#00BCD4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#795548',
    '#607D8B',
    '#673AB7',
    '#9C27B0',
    '#E91E63',
    '#F44336',
    '#303F9F',
    '#1976D2',
    '#0288D1',
    '#0097A7',
    '#00796B',
    '#388E3C',
    '#689F38',
    '#AFB42B',
    '#FBC02D',
    '#FFA000',
    '#F57C00',
    '#5D4037',
    '#616161',
    '#455A64',
    '#512DA8',
    '#7B1FA2',
    '#C2185B',
    '#D32F2F',
    '#1A237E',
    '#0D47A1',
    '#01579B',
    '#006064',
    '#004D40',
    '#1B5E20',
    '#33691E',
    '#827717',
    '#F57F17',
    '#FF6F00',
    '#E65100',
    '#BF360C',
    '#3E2723',
    '#212121',
    '#263238',
    '#311B92',
    '#4A148C',
    '#880E4F',
    '#B71C1C',
  ];

  getRandomColor(index: number) {
    return this.colors[index % this.colors.length];
  }
}
