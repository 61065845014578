import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { PaginatorService } from '@common/services/paginator.service';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  constructor(
    private httpClient: HttpClient,
    public paginatorService: PaginatorService,
  ) {}

  public messagesData = new ReplaySubject(1);

  public addresses: any = {};

  public changeLegends = new Subject();

  public currentView = new BehaviorSubject('table');
  public currentType = new BehaviorSubject<string>('raw');
  public currentMessageType = new BehaviorSubject<string>('with_data');

  public objectForFastMessages: any = null;

  getMessages(
    objectId: number,
    startDate: string,
    endDate: string,
    page = 0,
    pageSize: number | null = 50,
    messageType: string = 'with_data',
    param: string = 'raw',
    sort: string = 'desc',
  ) {
    this.currentType.next(param);
    this.currentMessageType.next(messageType);
    return this.httpClient.post(`messages/get`, {
      objectId: objectId,
      startDate: startDate,
      end_date: endDate,
      messagesType: messageType,
      messagesParam: param,
      page,
      page_size: pageSize,
      sort,
    });
  }

  getMessagesStat(
    objectId: number,
    startDate: string,
    endDate: string,
    messageType: string = 'with_data',
    param: string = 'raw',
  ) {
    return this.httpClient.post(`messages/stat`, {
      objectId: objectId,
      startDatetime: startDate,
      endDatetime: endDate,
      messagesType: messageType,
      // messagesParam: param,
    });
  }

  getSendedCommands(
    objectId: number,
    startDate: string,
    endDate: string,
    page = 0,
    pageSize: number | null = 50,
    messageType: string = 'sended_commands',
    param: string = 'raw',
    sort: string = 'desc',
  ) {
    this.currentMessageType.next(messageType);
    this.currentType.next(param);
    return this.httpClient.post(`messages/get`, {
      objectId: objectId,
      startDate: startDate,
      end_date: endDate,
      messagesType: messageType,
      messagesParam: param,
      page,
      page_size: pageSize,
      sort,
    });
  }
}
